<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Time and Attendance Settings</h3>
      <p class="text-helper mb-md-0">Define your company's overtime and leave year end notification settings here.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]="searchlistdata" [searchplaceholder]='searchString'
            (searchEmitter)="searchresult($event)" (keywordsearchEmitter)="searchconfig($event)" [isLoading]="isLoading">
          </app-searchbar>
        </div>

        <!-- <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
          </div>
        </div> -->
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a"
          routerLink="/time-attendance-settings/time-attendance-settings-setup"><i class="icon-plus"></i>ADD
          CONFIGURATION</button>
      </div>
    </div>
  </div>
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>

  </div>

  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg54.png'"
      [noDataText]="'Start configuring time and attendance settings'"
      [noDataPara]="'Set up overtime and leave year-end notifications for single or multiple companies using the \'Add Configuration\' button to manage time and attendance effectively.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let company of items,let i = index">
        <div class="card card-custom1 card-hover">
          <div class="card-body p-0 d-flex align-items-start">
            <div class="d-flex view-section" (click)="detailedView(company.id)">
              <div *ngIf="company.logo == '' || company.logo == null" class="bg{{(i+1)%5}} sq-48 avatar-box me-16">
                {{this.appService.getFirstChar(company.company_name,2)}}</div>
              <img *ngIf="company.logo != '' && company.logo != null" class="logo-img img-fluid" src="{{company.logo}}">
              <!-- <div  class="logo-img bg{{i%5}}">SW</div> -->
              <div class="overflow-hidden me-auto">
                <h6 class="mb-0 tips">{{company.company_name}}</h6>
                <span class="tips-helper">{{company.company_name}}</span>
                <span
                  class="fs-10 text-uppercase fw-500 {{(company.is_active == true) ? 'text-success' : 'text-danger'}}">{{(company.is_active==
                  true) ? 'Active' : 'Deleted'}}
                </span>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block dropdown-section"
              *ngIf="company.is_active == true && permissions?.e">
              <button class="btn-reset p-0 h-100" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-three-dots-vertical fs-16"></i>
              </button>
              <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="editCompany(company.id)">
                  <i class="icon-pencil-square me-1"></i> Edit
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
  </ng-template>
</div>

<div *ngIf="permissions" class="side-panel" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewConfiguration === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewConfiguration = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Configuration</p>
            <a *ngIf="viewData?.is_active && permissions?.e"
              class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="editCompany(viewData?.id)">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">ATTENDENCE</span></div>

                  <div class=" col-sm-6 col-12">
                    <div class="detail-title">Company name</div>
                    <div class="detail-desc">{{viewData?.company_name}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewData?.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="viewData?.is_active==false">DELETED</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to allow user to edit OT hours?</div>
                    <div class="detail-desc">{{viewData?.edit_ot_hours==true?'Yes':'No'}}</div>
                  </div>

                  <div class="col-12">
                    <div class="detail-title">Define number of hours after shift end time to initiate consolidate check
                      in request
                    </div>
                    <div class="detail-desc">{{viewData?.initiate_consolidate_checkinrequest}} </div>
                  </div>

                  <div class="col-12">
                    <div class="detail-title">Do you want to show pop up notification on the home dashboard when the
                      leave policy year-end arises ?</div>
                    <div class="detail-desc">{{viewData?.showup_notify_home == true?'Yes':'No'}}</div>
                  </div>
                  <ng-container *ngIf="viewData?.showup_notify_home">
                    <div class="col-12">
                      <div class="detail-title">How many days prior to the leave policy year-end the pop up notification
                        should display?
                      </div>
                      <div class="detail-desc">{{viewData?.prior_days}} </div>
                    </div>
                    <div class=" col-sm-6 col-12">
                      <div class="detail-title">Whom do you want to notify?</div>
                      <div class="detail-desc">
                        <div class="d-flex flex-wrap mt-8rem gap-8">
                          <span class="badge2" *ngFor="let roleemp of roleView
                                              , let i = index" [ngbPopover]="popContentSingle"
                            popoverClass="user-list single-user" triggers="hover">
                            <div class="avatar-circle sq-24 fs-10rem {{roleemp?.whom_notify_details?.color_code}}"
                              alt=""
                              *ngIf="roleemp?.whom_notify_details?.role_name !=null || roleemp?.whom_notify_details?.role_name !=''">
                              {{this.appService.getFirstChar(roleemp?.whom_notify_details?.role_name,1)}}</div>


                            {{ ((roleemp?.whom_notify_details?.role_name).length>25)?
                            ((roleemp?.whom_notify_details?.role_name)|
                            slice:0:25)+'..':(roleemp?.whom_notify_details?.role_name) }}
                            <ng-template #popContentSingle>
                              <div class="user-details">
                                <span class="user-label">{{roleemp?.whom_notify_details?.role_name}}</span>

                              </div>
                            </ng-template>
                          </span>

                          <!-- <span class="badge2"> <div class="avatar-circle sq-24 fs-10rem bg1"  alt="">LR</div> Lindsey Donin</span> -->
                        </div>
                      </div>
                    </div>
                  </ng-container>



                </div>
              </div>
            </div>


          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
