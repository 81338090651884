<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Shift </h3>
      <p class="text-helper mb-md-0">Create, add and pre-define a set of shift settings that are applicable to your
        organization.
      </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='shiftsearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchshift($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13rem"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" routerLink="/shift/shift-setups"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add
          Shift
        </button>
      </div>
    </div>
  </div>
  <!-- No data found -->

  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg10.png'" [noDataText]="'Add shifts to get started'"
      [noDataPara]="'It seems that shift settings are not yet configured. Click \'Add Shift\' to set up shifts within the organization.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>

  <ng-template #all_data>
    <div class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="  col-md-3 col-sm-6  " *ngFor="let shift_data of shiftDetails;let i=index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewShift(shift_data.id)">

                <div class="logo-img {{shift_data.color_code}}">{{ this.appService.getFirstChar(shift_data.shift_name,2)
                  }}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-0 fw-500 text-trim tips">{{shift_data.shift_name}} ({{shift_data.shift_code}}) </h6>
                  <p class="mb-0  tips-helper">{{shift_data.shift_name}} ({{shift_data.shift_code}}) </p>
                  <span
                    class="fs-10 text-uppercase fw-500 {{(shift_data.is_active == true) ? 'text-success' : 'text-danger' }} ">
                    {{shift_data.is_active == true ? 'Active':'Deleted'}}
                  </span>
                </div>
              </div>
              <div ngbDropdown class="dropdown-section"
                *ngIf="shift_data.is_active==true && (permissions.e || permissions.d)">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical fs-16"></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngIf="permissions.e" (click)="editalert=true;editid=shift_data.id">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button class="text-danger" ngbDropdownItem (click)="deleteAlert=true;deleteId=shift_data.id"
                    *ngIf="permissions.d">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <div class="card-body p-16" (click)="viewShift(shift_data.id)">
              <div class="row w-100 row-16 fs-12">
                <div class="col-lg-6 ">
                  <div class="text-light-400 fs-10 text-uppercase">shift start time</div>
                  <div class=" ">{{this.appService.timeFormatDisplay(shift_data.shift_start_time)}}</div>
                </div>
                <div class="col-lg-6 overflow-hidden">
                  <div class="text-light-400 fs-10 text-uppercase">SHIFT END TIME</div>
                  <div class=" text-trim ">{{this.appService.timeFormatDisplay(shift_data.shift_end_time)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <app-loader *ngIf="loader"></app-loader>
  <app-infinity-loader *ngIf="infinityloader"></app-infinity-loader>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:64.4375rem;"
  [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <ng-container *ngIf="!loader1">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">SHIFT</p>
            <a (click)="editalert=true;editid=shiftView.id" *ngIf="(permissions.e && shiftView.is_active)"
              class="link-primary fs-14 d-flex align-items-center ms-auto">
              <i class="icon-pencil-square me-2"></i> Edit
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">SHIFT DETAILS</span></div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Shift Code</div>
                    <div class="detail-desc">{{shiftView.shift_code}}</div>
                  </div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Shift Name</div>
                    <div class="detail-desc">{{shiftView.shift_name}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Shift Color</div>
                    <div class="detail-desc">
                      <label for="{{shiftView.color_code}}"
                        class="form-check-label {{shiftView.color_code}} sq-24 rounded-circle">
                      </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">SHIFT TIMING</span></div>

                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Shift Start Time</div>
                    <div class="detail-desc">{{this.appService.timeFormatDisplay(shiftView.shift_start_time)}}</div>

                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Shift End time</div>
                    <div class="detail-desc">{{this.appService.timeFormatDisplay(shiftView.shift_end_time)}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Minimum working hours required for half day</div>
                    <div class="detail-desc">{{shiftView.min_work_hours_for_halfday}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Minimum working hours required for full day</div>
                    <div class="detail-desc">{{shiftView.min_work_hours_for_fullday}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">First Half End Timing</div>
                    <div class="detail-desc">{{this.appService.timeFormatDisplay(shiftView.first_half_end)}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Second Half Start Timing</div>
                    <div class="detail-desc">{{this.appService.timeFormatDisplay(shiftView.second_half_start)}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Do you want to allow check-in before shift starts?</div>
                    <div class="detail-desc" *ngIf="shiftView.before_shift_start_enable">Yes
                      {{shiftView.before_shift_start_time}}</div>
                    <div class="detail-desc" *ngIf="shiftView.before_shift_start_enable==false">No</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Do you want to provide grace time for late check-in?</div>
                    <div class="detail-desc" *ngIf="shiftView.grace_time_late_checkin_enable">Yes
                      {{shiftView.grace_time_late_checkin_time}}</div>
                    <div class="detail-desc" *ngIf="shiftView.grace_time_late_checkin_enable==false">No</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Do you want to provide grace time for early check-out?</div>
                    <div class="detail-desc" *ngIf="shiftView.grace_time_early_checkout_enable">Yes
                      {{shiftView.grace_time_early_checkout_time}}</div>
                    <div class="detail-desc" *ngIf="shiftView.grace_time_early_checkout_enable==false">No</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Do you want to mark Half-day absent if no attendance is recorded for</div>
                    <div class="detail-desc" *ngIf="shiftView.mark_half_day_absent_status
                                    ">Yes {{shiftView.mark_half_day_absent
                      }}</div>
                    <div class="detail-desc" *ngIf="shiftView.mark_half_day_absent_status==false">No</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to mark Full-day absent if no attendance is recorded for</div>
                    <div class="detail-desc" *ngIf="shiftView.mark_full_day_absent_status">Yes
                      {{shiftView.mark_full_day_absent}}</div>
                    <div class="detail-desc" *ngIf="shiftView.mark_full_day_absent_status==false">No</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title"> BREAK SCHEDULE</span></div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to enable break hour deduction?</div>
                    <div class="detail-desc">{{shiftView.break_hour_deduction_enable?"Yes":"No"}}</div>
                  </div>
                  <div class="col-12 fs-14 fw-500" *ngIf="shiftView?.breakschedule?.length>0"> Break Details</div>

                  <ng-container *ngFor="let break_data of shiftView?.breakschedule;let i=index">
                    <div class="col-sm-6 col-12">
                      <div class="detail-title">Break Start Time </div>
                      <div class="detail-desc">{{this.appService.timeFormatDisplay(break_data.break_start_time)}}</div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="detail-title">Break End Time</div>
                      <div class="detail-desc">{{this.appService.timeFormatDisplay(break_data.break_end_time)}}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-24">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">WEEK OFF</span></div>
                  <div class=" col-12">
                    <div class="detail-title">How do you want to define weekoff for this shift?</div>
                    <div class="detail-desc">{{shiftView.week_off_define?"shift based":"As per organization policy"}}
                    </div>
                  </div>
                  <div class="col-12" *ngIf="shiftView.week_off_define">
                    <div class="card details-card shadow-0 px-24 py-16">
                      <div class="card card-c2 p-0">
                        <div class="table-responsive radius-4">
                          <table class="table td-14 sticky-header td-white-space td-fs-14
                                          tr-fs-14  table-striped table-sm form-table">
                            <thead>
                              <tr>
                                <th class="fw-600">WEEK DAYS</th>
                                <th class="fw-600" scope="col">1ST</th>
                                <th class="fw-600" scope="col">2ND</th>
                                <th class="fw-600" scope="col">3RD</th>
                                <th class="fw-600" scope="col">4TH</th>
                                <th class="fw-600" scope="col">5TH</th>
                              </tr>
                            </thead>
                            <tbody class="cell-16 height-full">
                              <tr *ngFor="let week_data of shiftView.weekoffdata_view;let i=index">
                                <td>{{week_data.week_days}}</td>
                                <td>{{week_data['1ST']}}</td>
                                <td>{{week_data['2ND']}}</td>
                                <td>{{week_data['3RD']}}</td>
                                <td>{{week_data['4TH']}}</td>
                                <td>{{week_data['5TH']}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>
<!-- Delete  component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Shift?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Shift')}}

        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteAlert = false;deleteClicked=false;">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="confirmDelete(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
<!-- Edit  component -->
<div class="modal  modal-alert {{editalert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">Edit Shift</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">Changes made to shift will be reflected for shifts assigned next time shift roaster service is
          run. For the changes to be reflected for days for which shift roaster is already created, please re-assign
          shift to all the employees.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="editalert = false">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="shiftedit(editid)">Yes</button>
      </div>
    </div>
  </div>
</div>
