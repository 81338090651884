<div class="container-fluid p-24rem" *ngIf="permissions">
  <div class="row  mb-16rem">
      <div class="col-md-4 col-lg-6">
          <h3>Business Unit</h3>
          <p class="text-helper mb-md-0">Business units are independent divisions. Add all of your company's business units.</p>
      </div>
      <div class="col-md-8 col-lg-6">
          <div class="d-flex justify-content-end  gap-16rem">
              <div class="" style="width:22.6875rem;">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='businesssearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchBU($event)" [isLoading]="isLoading"></app-searchbar>
              </div>
               <div ngbDropdown class="d-inline-block">
                <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                    <i class="icon-filter-left fs-13rem"></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-end" >
                   <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';listFilter()">All</button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';listFilter('true')">Active </button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';listFilter('false')">Deleted</button>
                </div>
             </div>
              <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addBuForm()"><i
                  class="icon-plus"></i>Add BUSINESS UNIT</button>
          </div>
      </div>
  </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16rem" *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card1'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
      <div *ngIf="!bu_list_flag && !loader;else all_data " class="row" style="height: calc(100vh - 15rem);">
        <app-nodata-view class="vstack" [noDataImg]="'bg7.png'" [noDataText]="'Business units are not configured yet.'"
          [noDataPara]="'Click \'Add Business Unit\' to create the master list and manage operations efficiently acrossdifferent functions.'"
          [hasPermission]="false">
        </app-nodata-view>
      </div>
      <ng-template #all_data>
          <div class="row row-16rem" infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [fromRoot]="true"
      (scrolled)="onScrollDown()">
          <ng-container  *ngIf="loader==false">
              <ng-container *ngFor="let bussinessUnit of bussinessUnitList, let i = index" >
                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                      <div class="card card-custom1 card-hover" >
                          <div class="card-body p-0 d-flex align-items-start">
                            <div class="d-flex view-section" (click)="viewBU(bussinessUnit.id)">
                              <div class="logo-img {{bussinessUnit.color_code}}">{{this.appService.getFirstChar(bussinessUnit.name,2)}}</div>
                              <div class="overflow-hidden">
                                  <h6 class="mb-0 tips">{{bussinessUnit.name}}</h6>
                                  <span class="tips-helper">{{bussinessUnit.name}}</span>
                                  <div class="fs-12rem tips fw-500 text-light-400">
                                      {{bussinessUnit.company.company_name}}
                                  </div>
                                  <span class="tips-helper ">{{bussinessUnit.company.company_name}}</span>
                              </div>
                            </div>
                              <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="bussinessUnit.is_active && (permissions.e || permissions.d)">
                                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                    ngbDropdownToggle>
                                    <i class="icon-three-dots-vertical "></i>
                                </button>
                                <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                                    <button *ngIf="permissions.e" ngbDropdownItem (click)="editBuForm(bussinessUnit.id)" >
                                        <i   class="icon-pencil-square me-1"></i> Edit
                                    </button>
                                    <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deletebu(bussinessUnit.id)" >
                                        <i class="icon-trash  me-1"></i> Delete
                                    </button>
                                </div>
                              </div>
                            </div>
                          <div class="card-body pt-8 justify-content-between">
                              <span class="fs-10rem text-uppercase fw-500 {{(bussinessUnit.is_active == true) ? 'text-success' : 'text-danger'}}">{{(bussinessUnit.is_active
                                      == true) ? 'Active' : 'Deleted'}}</span>
                          </div>
                      </div>
                  </div>
              </ng-container>

              <!-- Loader updations -->
              <ng-container *ngIf="infinityloader">
              <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card1'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
            </ng-container>
          </ng-container>
          </div>
      </ng-template>
      <!-- <div class="loader text-center" [style.display]="infinityloader" >
          <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
      </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->


  </div>
  <div *ngIf="permissions" class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg "></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32rem py-24rem">
                <!-- View loader start -->
                <app-view-loader *ngIf="loader1"></app-view-loader>
                <!-- View loader end -->
                <div class="row row-16rem mb-16rem" *ngIf="!loader1">
                  <div class="d-flex col-12">
                    <p class="text-uppercase fw-500 mb-0"> Business Unit</p>
                    <a class="link-primary fs-14rem d-flex align-items-center ms-auto" (click)="editBuForm(buViewid)" *ngIf="buViewis_active==true && (permissions.e)">
                        <i   class="icon-pencil-square me-1"></i> Edit
                    </a>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24rem py-16rem">
                            <div class="row row-16rem" >
                                <div class="col-12 "><span class="title">BUSINESS UNIT DETAILS</span></div>

                                <div class="col-lg-4  col-sm-6 col-12">
                                    <div class="detail-title">Business unit name</div>
                                    <div class="detail-desc">{{buViewname}}</div>
                                </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Status</div>
                                  <div class="detail-desc">
                                    <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="buViewis_active">ACTIVE</span>
                                    <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="buViewis_active==false">DELETED</span>
                                  </div>
                              </div>
                                <div class="col-lg-4  col-sm-6 col-12">
                                    <div class="detail-title">Company</div>
                                    <div class="detail-desc"><span class="d-inline-flex align-items-start">
                                      <img class="img-fluid rounded-circle sq-24 me-1"  src="{{bulogo}}" *ngIf="bulogo !=  '' && bulogo !=  null">
                                      <span class="avatar sq-24 avatar-circle bg1 me-1"  *ngIf="bulogo ==  null || bulogo ==  ''">{{this.appService.getFirstChar(buViewcompany,1)}}</span>
                                        <span> {{buViewcompany}}</span>
                                     </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="permissions" class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
  <form [formGroup]="checkoutForm" (ngSubmit)="BUFormSubmit()" class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
          <h5><span [innerHTML]="header"></span> Business Unit</h5>
          <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg "></i></a>
    </header>

    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid px-32rem py-24rem">
        <!-- Edit loader start -->
        <app-edit-loader *ngIf="loader1" ></app-edit-loader>
        <!-- Edit loader end -->
        <ng-container *ngIf="!loader1" >
          <p class="text-uppercase fw-500 mb-12rem"><span [innerHTML]="header"></span> BUSINESS UNIT DETAILS</p>
          <div class="row row-12rem ">
              <div class="col-12 col-md-6 form-row ">
                  <label for="name" class="form-label required">Business unit name </label>
                  <input type="text" class="form-control" formControlName="name" id="name" placeholder="{{this.messageService.placeholderdisp('business unit name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist}"
                   (keyup)="keyUp()">
                  <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                    <div *ngIf="f.name.errors?.exist">{{this.messageService.validationDisplay(f.name.value)}}</div>
                    <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                    <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                  </div>
              </div>
              <!-- submitted : {{submitted}}
              <br>
              error : {{f.name.errors | json}} -->
              <div class="col-12 col-md-6 form-row ">
                <label for="company" class="form-label required">Company </label>
                <!-- <select class="form-select" formControlName="company" id="company" [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                >
                    <option value="">Select company</option>
                    <option *ngFor="let company of companyDropdown" value="{{ company.id }}">{{ company.company_name }}</option>
                </select> -->
                <ng-select class="form-ngselect"
                          formControlName="company" [items]="companyDropdown"
                          bindLabel="company_name"
                          bindValue="id"
                        [ngClass]="{ 'is-invalid': (submitted || f.company.touched) && f.company.errors }"
                        name=""  id="company"
                        placeholder="{{this.messageService.selectplaceholddisplay('company')}}">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                          <div class="d-inline-flex align-items-center">
                            <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"  *ngIf="item.logo !=  '' && item.logo !=  null" src="{{item.logo}}"  >
                            <span *ngIf="item.logo ==  null || item.logo ==  ''" class="avatar-sm sq-24 rounded-circle bg{{index%5}} me-1"   >
                                <span>
                                {{this.appService.getFirstChar(item.company_name,1)}}</span>
                            </span>
                            <span> {{item.company_name}}</span>
                          </div>
                      </ng-template>
                </ng-select>
                <div *ngIf="(submitted || f.company.touched) && f.company.errors" class="invalid-feedback">
                  <div *ngIf="f.company.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                </div>
              </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer gap-32rem"  *ngIf="!loader1">
      <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="deleteToggle = true" *ngIf="deleteClass==true && permissions.d" (click)="deleteToggle = true" [disabled]='disabled'>Delete</button>
      <button *ngIf="(addEditBtn=='Save' && permissions.a) || (addEditBtn=='Save' && permissions.e)" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]='disabled || !checkoutForm.valid'><span [innerHTML]="addEditBtn"></span></button>
    </footer>
  </form>
</div>

<!-- delete model  -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16rem">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Business Unit?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Business Unit')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked" (click)="confirmDelete()" >Delete</button>
      </div>
    </div>
  </div>
</div>

