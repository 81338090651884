<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Error</h3>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div style="min-width:22.688rem;">
          <div class="form-icon2">
            <i class="icon-search icon-left fs-14"></i>
            <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
              (click)="searchString=''"></div>
            <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  "
              name="searchString" [(ngModel)]="searchString" (keyup)="page=1" />
          </div>
        </div>
        <div ngbDropdown class=" d-inline-block ">
          <button class="btn-square btn btn-secondary" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle
            (click)="filterpanel=true">
            <i class="icon-filter-left fs-13"></i>
          </button>
          <!-- <div ngbDropdownMenu class="bottom-left" aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Corrected' }" (click)="statusBtn='Corrected';filter('status',true)">
                            Corrected
                        </button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Not Corrected' }" (click)="statusBtn='Not Corrected';filter('status',false)">
                            Not Corrected
                        </button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='This week' }" (click)="statusBtn='This week';filter('last_week',false)">
                            This week
                        </button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Last week' }" (click)="statusBtn='Last week';filter('last_week',true)">
                            Last week
                        </button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Last month' }" (click)="statusBtn='Last month';filter('last_month',true)">
                            Last month
                        </button>
                        <button ngbDropdownItem [class]="{'active':statusBtn=='Last three months' }" (click)="statusBtn='Last three months';filter('last_month',false)">
                          Last three months
                      </button>

                    </div> -->
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="(filterIt().length<=0) && !loader;" class="row pt-4" style="height: calc(100vh - 10rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg42.png'" [noDataText]="'Oops, no results found!'"
      [noDataPara]="'It seems no results match your search criteria. Please adjust or apply different filters.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <app-table-loader *ngIf="loader"></app-table-loader>
  <div class="row row-16" *ngIf="!noDataFound && !loader && (filterIt().length>0)">
    <div class="col-12">
      <div class="card card-c2 p-0">
        <div class="table-responsive  scrollbar-10 radius-4 table-minheight" style="max-height:calc(100vh - 13.1rem );">
          <div class="input-group">
            <table class="table sticky-header table-striped vertical-align-top table-sm">
              <thead>
                <tr>
                  <th class="fw-600" scope="col">Module</th>
                  <th class="fw-600" scope="col">Process</th>
                  <th class="fw-600" scope="col">Error Reason</th>
                  <th class="fw-600" scope="col">Occured On</th>
                  <th class="fw-600" scope="col">Employees Affected</th>
                  <th class="fw-600" scope="col">Status</th>
                  <th class="fw-600" scope="col">Corrected On</th>


                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor='let data of $any(errorData|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)'>
                  <td>{{data.module_name}}</td>


                  <td>{{ data.process }}</td>
                  <td>{{data.error_reason}}</td>
                  <td>{{this.appservice.dateFormatDisplay(data.occured_on)}}</td>
                  <td class="pointer text-center" (click)="errorAffectView(data.id,data.employee_details.length)"><span
                      class="text-accent2">{{data.employee_details.length}}</span></td>
                  <td>
                    <span
                      class="badge py-1 {{data.status==1?'badge-success':'badge-danger'}}">{{data.status==1?'Corrected':'Not
                      corrected'}}</span>
                  </td>
                  <td>{{data.corrected_on!=null?this.appservice.dateFormatDisplay(data.corrected_on):''}}</td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="this.appservice.filteredcount(errorData,searchString)>pageSize">
      <ngb-pagination class="d-flex justify-content-end"
        [collectionSize]="this.appservice.filteredcount(errorData,searchString)" [(page)]="page" [maxSize]="3"
        [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
    </div>
  </div>

</div>
<div class="side-panel" style="--sidepanel-width:27rem;z-index:9999;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Employees affected</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24" *ngIf="view_loader">
          <div class="col-12" *ngFor="let dummy of ' '.repeat(2).split(''), let x = index">
            <app-card-view-loader></app-card-view-loader>
          </div>
        </div>
        <ng-container *ngIf="!view_loader">
          <div class="row row-16 ">
            <div class="col-12 form-row" *ngFor="let data of errorEmpData">
              <div class="card card-c2 ">
                <div class="card-body d-flex align-items-start">
                  <div *ngIf="(data.profile_image == null || data.profile_image=='')"
                    class="avatar-circle sq-45 {{data.color_code}} avatar-border">
                    {{this.appservice.getFirstChar(data.first_name+" "+data.last_name,2)}}</div>
                  <img *ngIf="(data.profile_image != null && data.profile_image!='')" src="{{ data.profile_image }}"
                    class="avatar-circle sq-45 avatar-border2" />
                  <div class="overflow-hidden ms-8 d-flex flex-column gap-1">
                    <span class="fs-14 fw-600 ">{{data.first_name +" " +(data.middle_name != null ? data.middle_name :
                      "") + " " +data.last_name +" ("+data.employee_code+")"}}</span>
                    <span class="fs-12 fw-500 text-light-400 ">{{ data.designation!=''?data.designation+' | ':'' }} {{
                      data.company }}</span>
                  </div>
                  <div class="ms-auto">
                    <span class="badge py-1 badge-danger fs-10">{{data.error_count}} Errors</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>

      </div>
    </div>
  </form>
</div>
<!-- filter -->
<div class="side-panel" style="--sidepanel-width:27rem;" [class.side-pane-active]='filterpanel === true'
  [formGroup]="filterForm">
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="filterpanel = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 ">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Module</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Module')}}" formControlName="module"
              [items]="module_list" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true"
              [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"
              class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                  {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.module?.length ">
                  <span class="ng-value-label">{{filterForm.value.module.length }} Module
                    Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.module?.length != 0">
              <li class="ng-value" *ngFor="let item of filterForm.value.module,let ki =index">
                <ng-container *ngFor="let insideitem of module_list, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                    slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('module',ki)"
                  aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Status</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Status')}}" formControlName="status"
              [items]="status_list" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true"
              [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id"
              class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{standalone: true}" /> Select All
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center">
                  <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone : true }" class="me-8" />
                  {{ (item.name?.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.status?.length ">
                  <span class="ng-value-label">{{filterForm.value.status.length }} Status
                    Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.status?.length != 0">
              <li class="ng-value" *ngFor="let item of filterForm.value.status,let ki =index">
                <ng-container *ngFor="let insideitem of status_list, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                    {{ (insideitem['name'].length>15)? (insideitem['name'] |
                    slice:0:15)+'..':(insideitem['name']) }}</span>
                </ng-container>
                <span *ngIf="disabled==false" class="ng-value-icon right" (click)="clearcommonForm('status',ki)"
                  aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Employees affected upto</p>
          </div>
          <div class="col-12 form-row">
            <input placeholder="Enter count" [multiple]="true" class="form-control" formControlName="count"
              [ngClass]="{ 'is-invalid': ( f.count.dirty) &&  f.count.errors}">
            <div *ngIf="( f.count.dirty) && f.count.errors" class="invalid-feedback">
              <div *ngIf="f.count.errors.maxlength">{{this.messageService.fieldlengthvalidation('num','10')}}</div>
              <div *ngIf="f.count.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
            </div>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">OCCURED ON</p>
          </div>
          <div class="form-icon icon-right vertical-datepicker">
            <input class="form-control datepicker-vertical" type="text" onkeydown="return false" opens="right"
              ngxDaterangepickerMd [(ngModel)]="selected" [closeOnAutoApply]="true" [showCustomRangeLabel]="true"
              [alwaysShowCalendars]="false" [ranges]="ranges" [linkedCalendars]="true" [locale]="{applyLabel: 'ok'}"
              [isInvalidDate]="isInvalidDate" [showClearButton]="true"
              placeholder="{{this.messageService.selectplaceholddisplay('Date')}}" formControlName="occured_on">
            <i class="icon-calendar pointer-event"></i>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">corrected ON</p>
          </div>
          <div class="form-icon icon-right vertical-datepicker">
            <input class="form-control datepicker-vertical" type="text" onkeydown="return false" opens="right"
              ngxDaterangepickerMd [(ngModel)]="selected1" [closeOnAutoApply]="true" [showCustomRangeLabel]="true"
              [alwaysShowCalendars]="false" [ranges]="ranges" [linkedCalendars]="true" [locale]="{applyLabel: 'ok'}"
              [isInvalidDate]="isInvalidDate" [showClearButton]="true"
              placeholder="{{this.messageService.selectplaceholddisplay('Date')}}" formControlName="corrected_on">
            <i class="icon-calendar pointer-event"></i>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetfilter()">
        Reset
      </button>
      <button type="submit" (click)="applyfilter()" class="ms-auto btn btn-primary text-uppercase btn-sm">
        Apply
      </button>
    </footer>
  </div>
</div>
