<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Tax Deductor</h3>
      <p class="text-helper mb-md-0">The particulars of the person responsible for the tax deduction are added on this
        page. </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;"><app-searchbar [searchlist]="searchlistdata"
            [searchplaceholder]='searchString' (searchEmitter)="searchresult($event)"
            (keywordsearchEmitter)="searchconfig($event)" [isLoading]="isLoading">
          </app-searchbar></div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';listFilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Pending' }"
              (click)="statusBtn='Pending';listFilter(false)" class="active">Pending </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Completed' }"
              (click)="statusBtn='Completed';listFilter(true)">Completed</button>

          </div>
        </div>
        <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>

      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
<app-nodata-view class="vstack" [noDataImg]="'bg27.png'" [noDataText]="'Ready to add tax deductor details?'"
  [noDataPara]="'It seems the tax deductor information is missing. Choose the financial year and click \'Add Tax Deductor\' to set it up.'"
  [hasPermission]="false">
</app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="row row-16">
          <ng-container>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let item of items,let i = index">
              <div class="card card-custom1 card-hover">
                <div class="card-body p-0 d-flex align-items-start">
                  <div class="d-flex view-section" (click)="viewTaxDeductor = true;this.id=item?.id;viewtaxdata()">
                    <img class="logo-img img-fluid" src="{{item?.company_details?.logo}}">
                    <div class="overflow-hidden me-auto">
                      <h6 class="mb-0 tips">{{item?.company_details?.company_name}}</h6>
                      <span class="tips-helper">{{item?.company_details?.company_name}}</span>

                      <span
                        class="fs-10 fw-500 {{(item?.status == true) ? 'text-success' : 'text-warning'}} text-uppercase">{{(item?.status
                        == true) ? 'COMPLETED' : 'Pending'}}</span>
                    </div>
                  </div>
                  <div *ngIf="permissions.e" ngbDropdown class="pe-16 py-16 dropdown-section d-inline-block ">
                    <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                      <i class="icon-three-dots-vertical fs-16"></i>
                    </button>
                    <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                      <button *ngIf="permissions.e" ngbDropdownItem
                        (click)="showSidePanel = true;this.id=item?.id;editForm()">
                        <i class="icon-pencil-square me-1"></i> Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- Loader updations -->
          <ng-container *ngIf="infinityloader">
            <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
              *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
          </ng-container>


        </div>
      </ng-container>
    </div>
  </ng-template>
  <!-- <pre>Form Values: {{formGp.value | json}}</pre> -->
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>TDS Deductor Information</h5>
      <a class="toggle-panel" (click)="showSidePanel = false;this.showDataItems();"><i
          class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid px-32 py-24" [formGroup]="formGp">

        <ng-container>
          <div class="row row-16 ">
            <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">RETURN DETAILS</p>
            </div>
            <div class="col-12 ">
              <div class="form-check">
                <label class="form-check-label "><input type="checkbox" class="form-check-input"
                    formControlName="include_nil_tds">
                  Include employee having NIL TDS return </label>
              </div>
            </div>
            <div class="col-12 ">
              <div class="form-check">
                <label class="form-check-label "><input type="checkbox" class="form-check-input"
                    formControlName="employee_zero_gross">
                  Exclude employees having zero gross earning </label>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="" class="form-label required">Type of deductor </label>
              <app-master-select
                [ngClass]="{ 'is-invalid': ( f.type_of_deductor.touched || formSubmitted) && f.type_of_deductor.errors }"
                [placeholder]="messageService.selectplaceholddisplay('type')" id="deductor_type"
                [submitted]="formSubmitted" formControlName="type_of_deductor" [selectedItem]="f.type_of_deductor.value"
                [type]="'deductor_type'"></app-master-select>
              <div *ngIf="( f.type_of_deductor.touched || formSubmitted) && f.type_of_deductor.errors"
                class="invalid-feedback">
                <div *ngIf="f.type_of_deductor.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="" class="form-label required">Payment section code </label>
              <app-master-select
                [ngClass]="{ 'is-invalid': ( f.payment_section_code.touched || formSubmitted) && f.payment_section_code.errors }"
                [placeholder]="messageService.selectplaceholddisplay('payment section code')" id="payment_sec_code"
                [submitted]="formSubmitted" [selectedItem]="f.payment_section_code.value"
                formControlName="payment_section_code" [type]="'payment_sec_code'"></app-master-select>
              <div *ngIf="(formSubmitted || f.payment_section_code.touched) && f.payment_section_code.errors"
                class="invalid-feedback">
                <div *ngIf="f.payment_section_code.errors.required">
                  {{this.messageService.validationDisplay('required')}}
                </div>
              </div>
            </div>
            <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">DEDUCTOR DETAILS</p>
            </div>
            <div class="col-12 ">
              <div class="form-check">
                <label class="form-check-label "><input (change)="isChecked(chkEnable.checked)" #chkEnable
                    type="checkbox" class="form-check-input" formControlName="address_deductor_last"> Has registered
                  address of company(deductor) changed since last TDS return</label>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Deductor name </label>
              <input type="text" class="form-control" formControlName="deductor_name"
                [placeholder]="messageService.placeholderdisp('deductor name')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.deductor_name.touched || f.deductor_name.dirty) && f.deductor_name.errors }">

              <div *ngIf="(formSubmitted || f.deductor_name.touched || f.deductor_name.dirty) && f.deductor_name.errors"
                class="invalid-feedback">
                <div *ngIf="f.deductor_name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.deductor_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f.deductor_name.errors.pattern) && f.deductor_name.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">TAN no </label>
              <input type="text" class="form-control" formControlName="tan_number" placeholder="Enter TAN no"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.tan_number.touched || f.tan_number.dirty) && f.tan_number.errors }">
              <div *ngIf="(formSubmitted || f.tan_number.touched || f.tan_number.dirty) && f.tan_number.errors"
                class="invalid-feedback">
                <div *ngIf="f.tan_number.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.tan_number.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">PAN number</label>
              <input type="text" class="form-control" formControlName="deductor_pan_number"
                placeholder="Enter PAN number"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.deductor_pan_number.touched || f. deductor_pan_number.dirty) && f.deductor_pan_number.errors }">
              <div
                *ngIf="(formSubmitted || f.deductor_pan_number.touched || f.deductor_pan_number.dirty) && f. deductor_pan_number.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_pan_number.errors.required">
                  {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.deductor_pan_number.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Branch </label>
              <input type="text" class="form-control" formControlName="branch_name"
                [placeholder]="messageService.placeholderdisp('branch')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. branch_name.touched || f. branch_name.dirty) && f. branch_name.errors }">

              <div *ngIf="(formSubmitted || f. branch_name.touched || f. branch_name.dirty) && f. branch_name.errors"
                class="invalid-feedback">
                <div *ngIf="f. branch_name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f. branch_name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f. branch_name.errors.pattern) && f. branch_name.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Flat no </label>
              <input type="text" class="form-control" formControlName="deductor_flat_number"
                [placeholder]="messageService.placeholderdisp('flat no')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_flat_number.touched || f. deductor_flat_number.dirty) && f. deductor_flat_number.errors }">
              <div
                *ngIf="(formSubmitted || f. deductor_flat_number.touched || f. deductor_flat_number.dirty) && f. deductor_flat_number.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_flat_number.errors.required">
                  {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f. deductor_flat_number.errors.alphaNumericError">
                  {{this.messageService.validationDisplay('pattern')}}
                </div>
                <div
                  *ngIf="!(f. deductor_flat_number.errors.alphaNumericError) && f. deductor_flat_number.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','50')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Name of the building</label>
              <input type="text" class="form-control" formControlName="deductor_name_of_building"
                [placeholder]="messageService.placeholderdisp('name of the building')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_name_of_building.touched || f. deductor_name_of_building.dirty) && f. deductor_name_of_building.errors }">
              <div
                *ngIf="(formSubmitted || f. deductor_name_of_building.touched || f. deductor_name_of_building.dirty) && f. deductor_name_of_building.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_name_of_building.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}
                </div>
                <div
                  *ngIf="!(f. deductor_name_of_building.errors.pattern) && f. deductor_name_of_building.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Street/road name</label>
              <input type="text" class="form-control" formControlName="deductor_street_road"
                [placeholder]="messageService.placeholderdisp('street/road name')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_street_road.touched || f. deductor_street_road.dirty) && f. deductor_street_road.errors }">
              <div
                *ngIf="(formSubmitted || f. deductor_street_road.touched || f. deductor_street_road.dirty) && f. deductor_street_road.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_street_road.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f. deductor_street_road.errors.pattern) && f. deductor_street_road.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Area/location</label>
              <input type="text" class="form-control" formControlName="deductor_area_location"
                [placeholder]="messageService.placeholderdisp('area/location')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_area_location.touched || f. deductor_area_location.dirty) && f. deductor_area_location.errors }">
              <div
                *ngIf="(formSubmitted || f. deductor_area_location.touched || f. deductor_area_location.dirty) && f. deductor_area_location.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_area_location.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f. deductor_area_location.errors.pattern) && f. deductor_area_location.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="" class="form-label required">State </label>
              <ng-select class="form-ngselect" formControlName="deductor_state"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.deductor_state?.touched) && f.deductor_state.errors }"
                name="" id="deductor_state" placeholder="{{this.messageService.selectplaceholddisplay('state')}}"
                (change)="cityList()">
                <ng-option *ngFor="let li of regionList" [value]="li?.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(formSubmitted || f.deductor_state?.touched) && f.deductor_state.errors"
                class="invalid-feedback">
                <div *ngIf="f.deductor_state.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="" class="form-label required">City/district </label>
              <ng-select [readonly]="!f.deductor_state?.value" class="form-ngselect" formControlName="deductor_city"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.deductor_city?.touched) && f.deductor_city.errors }"
                name="" id="deductor_city" placeholder="{{this.messageService.selectplaceholddisplay('city')}}">
                <ng-option *ngFor="let li of citylist1" [value]="li?.id">{{li.name}}</ng-option>
              </ng-select>

              <div *ngIf="(formSubmitted || f.deductor_city?.touched) && f.deductor_city.errors"
                class="invalid-feedback">
                <div *ngIf="f.deductor_city.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">PIN </label>
              <input type="text" class="form-control" formControlName="deductor_pin" placeholder="Enter PIN"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_pin.touched || f. deductor_pin.dirty) && f. deductor_pin.errors }">
              <div *ngIf="(formSubmitted || f. deductor_pin.touched || f. deductor_pin.dirty) && f. deductor_pin.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_pin.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f. deductor_pin.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">STD code</label>
              <input type="text" class="form-control" formControlName="deductor_std_code" placeholder="Enter STD code"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_std_code.touched || f. deductor_std_code.dirty) && f. deductor_std_code.errors }">
              <div
                *ngIf="(formSubmitted || f. deductor_std_code.touched || f. deductor_std_code.dirty) && f. deductor_std_code.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_std_code.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="f. deductor_std_code.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f. deductor_std_code.errors.pattern) && f. deductor_std_code.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('num','5')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Phone number</label>
              <input type="text" class="form-control" formControlName="deductor_phone_number"
                [placeholder]="messageService.placeholderdisp('phone number')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_phone_number.touched || f. deductor_phone_number.dirty) && f. deductor_phone_number.errors }">
              <div
                *ngIf="(formSubmitted || f. deductor_phone_number.touched || f. deductor_phone_number.dirty) && f. deductor_phone_number.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_phone_number.errors.required">
                  {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f. deductor_phone_number.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f. deductor_phone_number.errors.pattern) && f. deductor_phone_number.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Email </label>
              <input type="text" class="form-control" formControlName="deductor_email"
                [placeholder]="messageService.placeholderdisp('email')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. deductor_email.touched || f. deductor_email.dirty) && f. deductor_email.errors }">
              <div
                *ngIf="(formSubmitted || f. deductor_email.touched || f. deductor_email.dirty) && f. deductor_email.errors"
                class="invalid-feedback">
                <div *ngIf="f. deductor_email.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="f. deductor_email.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>
            </div>
            <div class="col-12">
              <p class="text-uppercase fw-500 mb-0">RESPONSIBLE PERSON DETAILS</p>
            </div>
            <div class="col-12 ">
              <div class="form-check">
                <label class="form-check-label "><input type="checkbox" #chkPEnable class="form-check-input"
                    (change)="isPChecked(chkPEnable.checked)" formControlName="address_tds_last">Has address of person
                  responsible for TDS deduction changed since last return</label>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Name </label>
              <input type="text" class="form-control" formControlName="name"
                [placeholder]="messageService.placeholderdisp('name')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.name.touched || f.name.dirty) && f.name.errors }">
              <div *ngIf="(formSubmitted || f.name.touched || f.name.dirty) && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.name.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f.name.errors.pattern) && f.name.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="" class="form-label required">Designation </label>
              <ng-select dropdownPosition="top"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.designation.touched) && f.designation.errors }"
                class="form-ngselect" formControlName="designation"
                placeholder="{{this.messageService.selectplaceholddisplay('designation')}}">
                <ng-option *ngFor="let item of DesignationDD" [value]="item.id">{{ item.name }}</ng-option>
              </ng-select>
              <div *ngIf="(formSubmitted || f.designation.touched) && f.designation.errors" class="invalid-feedback">
                <div *ngIf="f.designation.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">PAN number</label>
              <input type="text" class="form-control" formControlName="person_pan_number" placeholder="Enter PAN number"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_pan_number.touched || f.person_pan_number.dirty) && f.person_pan_number.errors }">
              <div
                *ngIf="(formSubmitted || f.person_pan_number.touched || f.person_pan_number.dirty) && f.person_pan_number.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_pan_number.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="f.person_pan_number.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Flat no </label>
              <input type="text" class="form-control" formControlName="person_flat_number"
                [placeholder]="messageService.placeholderdisp('flat no ')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f. person_flat_number.touched || f. person_flat_number.dirty) && f. person_flat_number.errors }">
              <div
                *ngIf="(formSubmitted || f.person_flat_number.touched || f.person_flat_number.dirty) && f.person_flat_number.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_flat_number.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="f.person_flat_number.errors.alphaNumericError">
                  {{this.messageService.validationDisplay('pattern')}}
                </div>

                <div *ngIf="!(f.person_flat_number.errors.alphaNumericError) && f.person_flat_number.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','50')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Name of the building</label>
              <input type="text" class="form-control" formControlName="person_name_of_building"
                [placeholder]="messageService.placeholderdisp('name of the building')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_name_of_building.touched || f.person_name_of_building.dirty) && f.person_name_of_building.errors }">
              <div
                *ngIf="(formSubmitted || f.person_name_of_building.touched || f.person_name_of_building.dirty) && f.person_name_of_building.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_name_of_building.errors.pattern">
                  {{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f.person_name_of_building.errors.pattern) && f.person_name_of_building.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>

            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Street/road name</label>
              <input type="text" class="form-control" formControlName="person_street_road"
                [placeholder]="messageService.placeholderdisp('street/road name')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_street_road.touched || f.person_street_road.dirty) && f.person_street_road.errors }">
              <div
                *ngIf="(formSubmitted || f.person_street_road.touched || f.person_street_road.dirty) && f.person_street_road.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_street_road.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f.person_street_road.errors.pattern) && f.person_street_road.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>

            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Area/location</label>
              <input type="text" class="form-control" formControlName="person_area_location"
                [placeholder]="messageService.placeholderdisp('area/location')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_area_location.touched || f.person_area_location.dirty) && f.person_area_location.errors }">
              <div
                *ngIf="(formSubmitted || f.person_area_location.touched || f.person_area_location.dirty) && f.person_area_location.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_area_location.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f.person_area_location.errors.pattern) && f.person_area_location.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="" class="form-label required">State </label>
              <ng-select class="form-ngselect" formControlName="person_state"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_state?.touched) && f.person_state.errors }"
                name="" id="person_state" placeholder="{{this.messageService.selectplaceholddisplay('state')}}"
                (change)="cityList2()">
                <ng-option *ngFor="let li of regionList" [value]="li?.id">{{li.name}}</ng-option>
              </ng-select>
              <div *ngIf="(formSubmitted || f.person_state?.touched) && f.person_state.errors" class="invalid-feedback">
                <div *ngIf="f.person_state.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="" class="form-label required">City/district </label>
              <ng-select [readonly]="!f.person_state?.value" class="form-ngselect" formControlName="person_city"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_city?.touched) && f.person_city.errors }" name=""
                id="person_city" placeholder="{{this.messageService.selectplaceholddisplay('city')}}">
                <ng-option *ngFor="let li of citylist2" [value]="li?.id">{{li.name}}</ng-option>
              </ng-select>

              <div *ngIf="(formSubmitted || f.person_city?.touched) && f.person_city.errors" class="invalid-feedback">
                <div *ngIf="f.person_city.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">PIN </label>
              <input type="text" class="form-control" formControlName="person_pin" placeholder="Enter PIN"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_pin.touched || f.person_pin.dirty) && f.person_pin.errors }">
              <div *ngIf="(formSubmitted || f.person_pin.touched || f.person_pin.dirty) && f.person_pin.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_pin.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.person_pin.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">STD code</label>
              <input type="text" class="form-control" formControlName="person_std_code" placeholder="Enter STD code"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_std_code.touched || f.person_std_code.dirty) && f.person_std_code.errors }">
              <div
                *ngIf="(formSubmitted || f.person_std_code.touched || f.person_std_code.dirty) && f.person_std_code.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_std_code.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f.person_std_code.errors.pattern) && f.person_std_code.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('NUM','5')}}</div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label  fs-14">Phone number</label>
              <input type="text" class="form-control" formControlName="person_phone_number"
                [placeholder]="messageService.placeholderdisp('phone number')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_phone_number.touched || f.person_phone_number.dirty) && f.person_phone_number.errors }">
              <div
                *ngIf="(formSubmitted || f.person_phone_number.touched || f.person_phone_number.dirty) && f.person_phone_number.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_phone_number.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>
            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Mobile number</label>
              <input type="text" class="form-control" formControlName="person_mobile_number"
                [placeholder]="messageService.placeholderdisp('mobile number')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_mobile_number.touched || f.person_mobile_number.dirty) && f.person_mobile_number.errors }">
              <div
                *ngIf="(formSubmitted || f.person_mobile_number.touched || f.person_mobile_number.dirty) && f.person_mobile_number.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_mobile_number.errors.required">
                  {{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.person_mobile_number.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>

            </div>
            <div class="col-6 form-row">
              <label for="name" class="form-label required fs-14">Email </label>
              <input type="text" class="form-control" formControlName="person_email"
                [placeholder]="messageService.placeholderdisp('email')"
                [ngClass]="{ 'is-invalid': (formSubmitted || f.person_email.touched || f.person_email.dirty) && f.person_email.errors }">
              <div *ngIf="(formSubmitted || f.person_email.touched || f.person_email.dirty) && f.person_email.errors"
                class="invalid-feedback">
                <div *ngIf="f.person_email.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.person_email.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
              </div>

            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer gap-32">

      <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="!formGp.valid || formSubmitted"
        (click)="saveForm()">Save</button>
    </footer>
  </div>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='viewTaxDeductor === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewTaxDeductor = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">


        <div class="d-flex mb-16">
          <p class="text-uppercase fw-500 mb-0">TDS Deductor Information</p>
          <a class="link-primary fs-14 d-flex align-items-center ms-auto"
            (click)="viewTaxDeductor = false;showSidePanel = true;editForm()">
            <i class="icon-pencil-square me-1"></i> Edit
          </a>
        </div>
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16 mb-24">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">Return Details</span></div>

                <div class=" col-sm-6 col-12">
                  <div class="detail-title">Type of deductor</div>
                  <div class="detail-desc">{{taxviewdata?.type_of_deductor}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Payment section code</div>
                  <div class="detail-desc">{{taxviewdata?.payment_section_code}}
                  </div>
                </div>
              </div>
            </div>
            <div class="card details-card shadow-0 px-24 py-16 mb-24">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">Deductor Details</span></div>

                <div class="col-12 form-row" *ngIf="taxviewdata?.address_deductor_last ==true">
                  <div class="d-flex align-items-center gap-8 fs-14">
                    <i class="icon-check-square-fill text-light-500"></i>Has registered address of company(deductor)
                    changed since last TDS return
                  </div>
                </div>
                <div class=" col-sm-6 col-12">
                  <div class="detail-title">Deductor name</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">TAN no</div>
                  <div class="detail-desc"> {{taxviewdata?.tan_number}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">PAN number</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_pan_number}} </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Branch</div>
                  <div class="detail-desc">{{taxviewdata?.branch_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Flat no</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_flat_number}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Name of the building</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_name_of_building}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Street/road name</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_street_road}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Area/location</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_area_location}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">State</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_state_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">City/district</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_city_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">PIN</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_pin}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">STD code</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_std_code}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Phone number</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_phone_number}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Email</div>
                  <div class="detail-desc">{{taxviewdata?.deductor_email}}</div>
                </div>
              </div>
            </div>
            <div class="card details-card shadow-0 px-24 py-16 ">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">RESPONSIBLE PERSON Details</span></div>

                <div class="col-12 form-row" *ngIf="taxviewdata?.address_tds_last ==true">
                  <div class="d-flex align-items-center gap-8 fs-14">
                    <i class="icon-check-square-fill text-light-500"></i>Has address of person responsible for TDS
                    deduction changed since last return
                  </div>
                </div>
                <div class=" col-sm-6 col-12">
                  <div class="detail-title">Name</div>
                  <div class="detail-desc">{{taxviewdata?.name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Designation</div>
                  <div class="detail-desc">{{taxviewdata?.designation_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">PAN number</div>
                  <div class="detail-desc">{{taxviewdata?.person_pan_number}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Branch</div>
                  <div class="detail-desc">{{taxviewdata?.person_branch_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Flat no</div>
                  <div class="detail-desc">{{taxviewdata?.person_flat_number}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Name of the building</div>
                  <div class="detail-desc">{{taxviewdata?.person_name_of_building}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Street/road name</div>
                  <div class="detail-desc">{{taxviewdata?.person_street_road}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Area/location</div>
                  <div class="detail-desc">{{taxviewdata?.person_area_location}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">State</div>
                  <div class="detail-desc">{{taxviewdata?.person_state_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">City/district</div>
                  <div class="detail-desc">{{taxviewdata?.person_city_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">PIN</div>
                  <div class="detail-desc">{{taxviewdata?.person_pin}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">STD code</div>
                  <div class="detail-desc">{{taxviewdata?.person_std_code}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Phone number</div>
                  <div class="detail-desc">{{taxviewdata?.person_phone_number}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Mobile number</div>
                  <div class="detail-desc">{{taxviewdata?.person_mobile_number}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Email</div>
                  <div class="detail-desc">{{taxviewdata?.person_email}}</div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </form>
</div>
