<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row row-16">
    <div class="col-md-4">
      <h3>Attendance Calendar</h3>
      <p class="text-helper mb-md-0">View the employees attendance status and also add/upload the punch details.</p>
    </div>
    <div class="col-md-8 ">
      <div class="d-flex justify-content-end  gap-16">
        <app-searchbar [searchlist]='searchListData' [searchplaceholder]='searchPlaceholder'  [isLoading]="sLoading" [keyinput]="searchKeyword"
          (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchEmp($event)"></app-searchbar>
        <div class="slider-input-container td-dropdown position-relative" style="width:16.25rem;">
          <input (click)="datePicker1.open()" class="form-control w-100 position-absolute"
            placeholder="{{this.messageService.selectplaceholddisplay('date')}}" matInput [(ngModel)]="pickDate"
            (dateInput)="fromDates($event)" [matDatepicker]="datePicker1" [min]="minDate" [max]="maxDate" style="visibility : hidden;">
          <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
          <button class="slider-btn-left" (click)="changeWeek('left')"><i class="icon-chevron-left-lg"></i></button>
          <div class="slider-input">
            <div class="align-items-center d-flex justify-content-around" (click)="datePicker1.open()">
              <span class="fs-14 fw-500">{{datePickDay}}</span>
              <span class="fs-10 fw-500 text-light-500">{{weekRange}}</span>
            </div>
          </div>
          <button class="slider-btn-right" (click)="changeWeek('right')"><i class="icon-chevron-right-lg"></i></button>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class=" btn-square btn btn-secondary" (click)="filter=true;filterOpened=true;" placement="bottom-left"
            id="dropdownBasic1" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
            <app-common-employee-filters [filterForm]="filterForm" [from]="from" [filter]="filter"
            (filterClose)="closefn($event)" (filterOutput)="resultfilterfn($event)" (dropOutput)="searchDrop($event)" [isMemorized]="isMemorized"></app-common-employee-filters>
        </div>
        <button class="btn flex-shrink-0 radius-2 btn-primary fs-10 fw-700 text-uppercase px-4" *ngIf="permissions?.a"
          (click)="showSidePanel=true;">Bulk Upload</button>

      </div>
    </div>
    <div class="col-12" *ngIf="selectedList?.length!=0 && !loader && alertDiv">
      <div role="alert" class="alert alert-warning show mb-0" id="mapShow">
        <p class="mb-0 fs-14"> The attendance policy has not been mapped for {{selectedList?.length}} employees. <a
            class="link-primary1" (click)="notificationPanel=true">Click here</a> to view the details</p>
        <div class="btn-close pointer" (click)="alertDiv = false"></div>
      </div>
    </div>
    <app-table-loader *ngIf="loader"></app-table-loader>
    <div *ngIf="noData && !loader;else all_data" class="row row-16"  style="height: calc(100vh - 12rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg54.png'" [noDataText]="'Let\'s get those attendance records rolling!'"
        [noDataPara]="'Assign an attendance policy to start tracking punches and managing employee attendance. You can update and override statuses for accurate records once set up.'"
        [hasPermission]="false">
      </app-nodata-view>
      <!-- <div class="card card-c2 overflow-hidden">
        <div class="table-responsive "
          style="max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));">
          <table
            class="table table-sm table-striped vertical-align-top td-text-break th-vertical-middele form-table sticky-header td-width-15ch td-white-space"
            mat-table [dataSource]="landData" matSort cdkDropList cdkDropListOrientation="horizontal">
            <ng-container *ngFor="let prop of displayedColumns">
              <ng-container [matColumnDef]="prop">
                <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header
                  ngbPopover="Click and drag  the header’s  to change the column position"
                  popoverClass="popover-default popover-info" container="body" triggers="hover">
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                  {{prop}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element[prop]}}
                </td>
              </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          </table>
          <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">No data found</div>
        </div>
      </div> -->
    </div>
    <ng-template #all_data>
      <div class="col-12">
        <div class="table-responsive card card-c2 scrollbar-10 table-minheight"
          style="max-height: calc(100vh - {{(selectedList?.length!=0 && !loader && alertDiv) ? '19.2rem':'14.8rem'}})"
          *ngIf="!loader && landData?.length!=0">
          <table class="table sticky-header table-bordered table-sm form-cell-sm td-0 ">
            <thead>
              <tr>
                <th class="fw-600 text-center" scope="col">Employee </th>
                <th class="fw-600 text-center text-uppercase" scope="col" style="min-width: 7.375rem"
                  *ngFor="let header of landData[0].attendance_detail ; let i = index">
                  {{header?.weekday?.slice(0,3)}} - {{appService.getDayMonth(header?.date)}}
                </th>
              </tr>
            </thead>
            <tbody class="">
              <tr *ngFor="let item of landData ; let i = index">
                <td (click)="singleEmployeeview(item?.employee_id)">
                  <div class=" p-12 d-flex align-items-center pointer">
                    <img *ngIf="(item?.profile_image!=null && item?.profile_image!='')" class="avatar-circle sq-32"
                      src="{{item?.profile_image}}" alt="">
                    <div *ngIf="(item?.profile_image ==  null || item?.profile_image == '')"
                      class="avatar-circle fs-12 sq-32 {{item?.color_code}}">
                      {{this.appService.getFirstChar(item?.name,2)}}</div>
                    <div class="overflow-hidden ms-16 d-flex flex-column">
                      <p class="fs-14 fw-600 w-15ch text-trim mb-0" ngbPopover="{{item?.name}}"
                        popoverClass="popover-input popover-info" container="body" triggers="hover">{{item?.name}}</p>
                      <p class="fs-10 mb-0  text-trim lh-base">{{item?.employee_code}}</p>
                    </div>
                  </div>
                </td>
                <td *ngFor="let day of landData[i].attendance_detail" class="cell-status {{day?.attendance_class}}">
                  <ng-container *ngIf="day?.attendance_class!=''|| day?.checkIn!='None' || day?.checkOut!='None' || landData[i].date_of_joined <= day?.date;else nodata">
                    <div class="card-cell-2 p-8 {{isCurrentDate(day?.date) ? 'td-today' : ''}}">
                      <div class="time-event" *ngIf=" landData[i].date_of_joined <= day?.date">
                        <span
                          class="start-time">{{day?.checkIn!='None'?appService.timeFormatDisplay(day?.checkIn):'--:--'}}</span>
                        <span
                          class="end-time">{{day?.checkOut!='None'?appService.timeFormatDisplay(day?.checkOut):'--:--'}}</span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #nodata>
                    <div class="card-cell-2 p-8  {{isCurrentDate(day?.date) ? 'td-today' : ''}}">
                      <ng-container *ngIf="!(isCurrentDate(day?.date));else today">
                        <div class="card-body d-flex p-0 align-items-start">
                          <div class="time-event">
                            <!-- <p class="mb-0 fs-12 fw-600 w-15ch text-trim" ngbPopover="No data" popoverClass="popover-input popover-info" container="body" triggers="hover">No data</p> -->
                            <span class="end-time">--:--</span>
                            <span class="end-time">--:--</span>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #today>
                        <div class="time-event">
                          <span
                            class="start-time">{{day?.checkIn!='None'?appService.timeFormatDisplay(day?.checkIn):'--:--'}}</span>
                          <span class="end-time">{{day?.checkOut!='None'?appService.timeFormatDisplay(day?.checkOut):'--:--'}}</span>
                        </div>
                      </ng-template>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12" *ngIf="!loader && lastPage > landData?.length && lastPage > 20">
        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPage" [maxSize]="3"
          [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
          (pageChange)="getPageFromService($event)"></ngb-pagination>
      </div>
    </ng-template>
  </div>
</div>

<ng-container *ngIf="notificationPanel">
  <app-non-mapped-empp [showSidePanel]="notificationPanel" [selectedList]="selectedList" [selectedName]="selectedName"
    (closePanel)="closePanel($event)" [searchNeeded]="'true'"></app-non-mapped-empp>
</ng-container>
<ng-container *ngIf="showSidePanel">
    <app-import-employee-punch-override [showSidePanel]="showSidePanel" (closePanel1)="closesidePanel($event)" (uploadeddata)="uploadeddata($event)" [from]="'hr'"></app-import-employee-punch-override>
</ng-container>
