<div *ngIf="permissions" class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Asset Category</h3>
      <p class="text-helper mb-md-0">Create asset categories and add corresponding subcategories for effective asset management.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div style="width:22.6875rem;">
          <div class="">
            <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='categorySearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchCategory($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';filterItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';filterItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';filterItemList(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addAssetCategory();"><i
            class="icon-plus"></i>Add
            Asset Category</button>
      </div>
    </div>
  </div>

  <div class="row row-16rem" *ngIf="loader">
    <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata && loader==false;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg26.png'" [noDataText]="'Define asset categories'"
      [noDataPara]="'It seems no asset categories are available. Click the \'Add Asset Category\' button to start organizing your assets.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>

  <ng-template #all_data><div class="row row-16rem " infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    (scrolled)="onScrollDown()">
      <ng-container  *ngIf="loader==false" >
        <!-- <div class="row row-16"> -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let category of assetCategoryList let i = index">
            <div class="card card-custom1 card-hover">
              <div class="card-body p-0 d-flex align-items-start">
                <div class="d-flex view-section" (click)="viewAssetCategory(category?.id);">
                  <div class="logo-img  {{category?.color_code}}">{{this.appService.getFirstChar(category?.name,2)}} </div>
                  <div class="overflow-hidden">
                    <h6 class="mb-1 tips">{{category?.name}} </h6>
                    <span class="tips-helper">{{category?.name}} </span>
                    <div class="fs-10 text-uppercase fw-500  {{(category?.is_active == true) ? 'text-success' : 'text-danger'}} ">{{category?.is_active == true ? 'Active' : 'Deleted'}}</div>
                  </div>
                </div>
                <div *ngIf="category?.is_active && (permissions.e || permissions.d)" ngbDropdown class="d-inline-block dropdown-section">
                  <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                    <i class="icon-three-dots-vertical "></i>
                  </button>
                  <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                    <button *ngIf="permissions.e" ngbDropdownItem ngbDropdownItem (click)="editCategory(category?.id);">
                      <i class="icon-pencil-square me-1"></i> Edit
                    </button>
                    <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deleteAssetCategoryfn(category?.id)">
                      <i class="icon-trash  me-1"></i> Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->
      </ng-container>

      <ng-container *ngIf="infinityloader">
      <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
  </ng-template>
</div>

<!-- Add & Edit Screen -->
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='editDetails === true'>
  <form [formGroup]="assetCategoryForm" class="side-panel-container" autocomplete="off" (ngSubmit)="onSubmit()">
    <header class="side-panel-head">
      <h5>{{header}} Asset category </h5>
      <a class="toggle-panel" (click)="editDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">

         <app-edit-loader *ngIf="loader1" ></app-edit-loader>

        <div *ngIf="!loader1" class="row row-16 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">Asset</p>
          </div>
          <div class="col-12">
            <label for="" class="form-label required">Asset category </label>

            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
              ngbPopover="Enter the asset category name  eg: Electronics, Furniture, Vehicles, etc."
              popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>

            <input type="text" class="form-control" formControlName="name" name="" id="name" value=""
            placeholder="{{this.messageService.placeholderdisp('category name')}}"
            [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched)  &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
            <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer" *ngIf="permissions && !loader1">

      <button *ngIf="header=='Edit' && permissions.d" class="btn btn-outline-danger text-uppercase btn-sm" (click)="deleteToggle = true;" type="button" [disabled]='disable'>DELETE</button>

      <button *ngIf="(header=='Add' && permissions.a) || (header=='Edit' && permissions.e)" type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]='disable || !assetCategoryForm.valid || isApi2'>
        Save
      </button>
    </footer>
  </form>
</div>

 <!-- View page -->
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>View </h5>
      <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">

        <app-view-loader *ngIf="loader1"></app-view-loader>

        <div *ngIf="!loader1" class="row row-16 mb-24">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0">Asset Category</p>
            <a *ngIf="assetCategoryViewData?.is_active && (permissions.e)" class="link-primary fs-14 ms-auto" (click)="editCategory(assetCategoryViewData?.id);"><i class="icon-pencil-square me-1"></i>
              Edit</a>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">Asset</p>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Asset category</div>
                  <div class="detail-desc">{{assetCategoryViewData?.name}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc"> <span class="{{(assetCategoryViewData?.is_active == true) ? 'text-success' : 'text-danger'}} ">{{assetCategoryViewData?.is_active == true ? 'Active' : 'Deleted'}}</span> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- Alert for delete start -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16rem">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Asset Category?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Asset Category')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteAssetCategory()">Delete</button>
      </div>
    </div>
  </div>
</div>
