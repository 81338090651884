<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Shift Rotation</h3>
      <p class="text-helper mb-md-0">
        Configure the shift rotation policy to automatically assign shifts and week-offs to the employees.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.688rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='shiftsearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchshift($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" routerLink="/shift/shift-rotation-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add
          SHIFT ROTATION
        </button>
      </div>
    </div>
  </div>
  <!-- No data found -->
  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg10.png'" [noDataText]="'Shift rotation scheduler is empty!'"
      [noDataPara]="'Automate employee schedules by defining shift rotations. Click \'Add Shift Rotation\' to start.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>

  <ng-template #all_data>
    <div class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="  col-md-3 col-sm-6" *ngFor="let shift_data of shiftrotDetails;let i=index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewShift(shift_data.id)">
                <div class="logo-img {{shift_data.color_code}}">{{
                  this.appService.getFirstChar(shift_data.rotation_name,2) }}</div>
                <div class="overflow-hidden">
                  <h6 class="mb-0 fw-500 text-trim tips">{{shift_data.rotation_name}}</h6>
                  <p class="mb-0  tips-helper">{{shift_data.rotation_name}}</p>
                  <span
                    class="fs-10 text-uppercase fw-500 {{(shift_data.is_active == true) ? 'text-success' : 'text-danger' }} ">
                    {{shift_data.is_active == true ? 'Active':'Deleted'}}
                  </span>
                </div>
              </div>
              <div ngbDropdown class="dropdown-section"
                *ngIf="shift_data.is_active==true && (permissions.e || permissions.d)">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical fs-16"></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngIf="permissions.e" (click)="shiftedit(shift_data.id)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button class="text-danger" ngbDropdownItem (click)="deleteAlert=true;deleteId=shift_data.id"
                    *ngIf="permissions.d">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <div class="card-body p-16 justify-content-between">
              <span class="fs-10 text-light-400">SHIFT SCHEDULE</span>
              <span class="fs-12 ">{{shift_data.shift_schedule}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <app-loader *ngIf="loader"></app-loader>
  <app-infinity-loader *ngIf="infinityloader"></app-infinity-loader>
</div>

<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <ng-container *ngIf="!loader1">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">SHIFT ROTATION</p>
            <a class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="shiftedit(shiftrot_data.id)"
              *ngIf="(permissions.e && shiftrot_data.is_active)">
              <i class="icon-pencil-square me-2"></i> Edit
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 ">
                    <span class="title">SHIFT ROTATION DETAILS</span>
                  </div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Rotation Name</div>
                    <div class="detail-desc">{{shiftrot_data.rotation_name}}</div>
                  </div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Shift scheduled for</div>
                    <div class="detail-desc">{{shiftrot_data.shift_schedule}}</div>
                  </div>
                  <div class="col-sm-3 col-12" *ngIf="shiftrot_data.shift_schedule=='Weekly'">
                    <div class="detail-title">Applicable period</div>
                    <div class="detail-desc">{{shiftrot_data.applicable_period}}</div>
                  </div>
                  <div class="col-sm-3 col-12" *ngIf="shiftrot_data.shift_schedule=='Monthly'">
                    <div class="detail-title">Applicable period</div>
                    <div class="detail-desc">{{this.appService.ordinal_suffix_of(shiftrot_data.applicable_period)}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 ">
                    <span class="title">Configure rotation</span>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Selected Shifts</div>
                    <div class="detail-desc">
                      <div class="d-flex flex-wrap gap-8">
                        <ng-container *ngFor="let shift_list of shiftrot_data.shiftrotationconfigure;let i=index">
                          <div class="  badge badge3 p-8   {{shift_list.shift_code}}" id="shifts">
                            {{shift_list.selected_shift}}</div>
                        </ng-container>

                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Rotation frequency</div>
                    <div class="detail-desc">{{shiftrot_data?.rotation_frequency}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Shift Rotation Pattern</div>
                    <div class="detail-desc">
                      <div class="d-flex flex-wrap gap-8 mt-20">
                        <ng-container *ngFor="let shift_list of shiftrot_data.shiftrotationconfigure;let i=index">
                          <ng-container
                            *ngFor="let freq of [].constructor(shiftrot_data?.rotation_frequency),let x = index">
                            <div class="btn-c1 {{shift_list.shift_code}}" *ngIf="shiftrot_data.shift_schedule=='Weekly'">
                              W{{(i*shiftrot_data?.rotation_frequency)+(1+x)}}</div>
                            <div class="btn-c1 {{shift_list.shift_code}}" *ngIf="shiftrot_data.shift_schedule=='Monthly'">
                              M{{(i*shiftrot_data?.rotation_frequency)+(1+x)}}</div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Applicability</span></div>
                  <div class="col-12">
                    <div class="detail-title">Applicable to</div>
                    <div class="detail-desc">{{shiftapplicable?.applicable}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">{{shiftapplicable?.applicable}}(s) </div>
                    <div class="detail-desc">
                      <div class="selected-values mt-1">
                        <ng-container *ngIf="shiftapplicable?.applicable == 'Employee'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,2)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="shiftapplicable?.applicable == 'Company'">
                          <li class="ng-value" *ngFor="let rights of assignees, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngIf="(shiftapplicable?.applicable != 'Employee') && (shiftapplicable?.applicable != 'Company')">
                          <div class="d-flex flex-wrap mt-8 gap-8">
                            <ng-container *ngFor="let rights of assignees, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>

                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Delete  component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Shift Rotation?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Shift Rotation')}}

        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="confirmDelete(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
