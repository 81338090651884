<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
      <div class="col-md-4 col-lg-6">
          <h3>Documents</h3>
          <p class="text-helper mb-md-0">Configure your employee's document settings.</p>
      </div>
      <div class="col-md-8 col-lg-6">
          <div class="d-flex justify-content-end gap-16">
              <div class="" style="width:22.6875rem;">
                  <div class="">
                      <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='documentsPlaceholder' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchdoc($event)" [isLoading]="isLoading"></app-searchbar>
                  </div>
                  <ul class="drop-down-menu search-result {{(inputFocusClass == true) ? 'active' : ''}}">
                      <p class="note"> No Result msg </p>
                      <p class="text-center text-helper mt-1">No Company Found...</p>
                      <p class="note"> Loader </p>
                      <p class="loader text-center">
                          <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
                      </p>
                      <p class="note"> Search Results</p>
                  </ul>
              </div>
              <div ngbDropdown class="d-inline-block">
                <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                    <i class="icon-filter-left fs-13"></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-end" >
                   <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';statusfilter('')">All</button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';statusfilter(true)">Active </button>
                   <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
                </div>
             </div>

              <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="adddocuments($event)"><i class="icon-plus"></i>Add Document</button>
          </div>
      </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16rem" *ngIf="loader">
      <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <!--No data found-->
  <div *ngIf="nodatafound && !loader;else all_data" class="row " style="height: calc(100vh - 11rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg9.png'" [noDataText]="'Set up company documents'"
      [noDataPara]="'It seems there are no documents set up. Click \'Add Document\' to create and share essential policy and official documents with employees.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <!--End-->
  <!-- <div class="row row-16 "> -->
    <ng-template #all_data>
      <div class="row row-16 "  infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [fromRoot]="true"
      (scrolled)="onScrollDown()">
        <ng-container  *ngIf="loader==false">

              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 "  *ngFor="let documents of documentsDetailsList let i = index">
                    <div class="card card-custom1 card-hover">
                      <div class="card-body d-flex p-0 align-items-start">
                        <div class="d-flex view-section" (click)="viewdoc(documents.id)">
                          <div class="logo-img {{documents.color_code}}">{{this.appService.getFirstChar(documents.name,1)}}</div>
                          <div class="overflow-hidden">
                              <h6 class="tips text-trim mb-0">{{documents.name}}</h6>
                              <span class="tips-helper">{{documents.name}}</span>
                              <span
                                    class="fs-10 text-uppercase fw-500 {{(documents.is_active == true) ? 'text-success' : 'text-danger'}}">{{(documents.is_active
                                    == true) ? 'Active' : 'Deleted'}}</span>
                          </div>
                        </div>


                          <div ngbDropdown class="d-inline-block dropdown-section" *ngIf="documents.is_active && (permissions.e || permissions.d)">
                            <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                                ngbDropdownToggle>
                                <i class="icon-three-dots-vertical fs-16"></i>
                            </button>
                            <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                                <button *ngIf="permissions.e" ngbDropdownItem   (click)="editDocuments(documents.id)">
                                    <i   class="icon-pencil-square me-1"></i> Edit
                                </button>
                                <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deleteDoc(documents.id)">
                                    <i class="icon-trash  me-1"></i> Delete
                                </button>
                            </div>
                        </div>

                      </div>

                    </div>
              </div>
        </ng-container>

        <!-- Loader updations -->
        <ng-container *ngIf="infinityloader">
          <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>

      </div>
    </ng-template>
    <!-- <div class="loader text-center" [style.display]="infinityloader" >
      <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
    <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->

  </div>
<!-- </div> -->

<!-- view -->
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel1 === true'>
  <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Details</h5>
          <a class="toggle-panel" (click)="showSidePanel1 = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid px-32 py-24">
            <!-- View loader start -->
            <app-view-loader *ngIf="loader1"></app-view-loader>
            <!-- View loader end -->
            <ng-container *ngIf="!loader1">
              <div class="d-flex mb-16">
                  <p class="text-uppercase fw-500 mb-0"> Documents </p>
                  <a  class="link-primary fs-14 d-flex align-items-center ms-auto"  (click)="editDocuments(docid)" *ngIf="docstatus && permissions.e">
                      <i   class="icon-pencil-square me-1"></i> Edit
                  </a>
              </div>
              <div class="row row-16 mb-16">
                  <div class="col-12">
                      <div class="card details-card shadow-0 px-24 py-16">
                          <div class="row row-24 " >
                              <div class="col-12 "><span class="title">DOCUMENTS DETAILS</span></div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                  <div class="detail-title">Documents name</div>
                                  <div class="detail-desc">{{doc_name}}
                                  </div>
                              </div>
                              <div class="col-lg-4  col-sm-6 col-12">
                                <div class="detail-title">Documents type</div>
                                <div class="detail-desc">{{doctype}}
                                </div>
                            </div>
                            <div class="col-lg-4  col-sm-6 col-12">
                              <div class="detail-title">Status</div>
                              <div class="detail-desc">
                                <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="docstatus">ACTIVE</span>
                                <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="docstatus==false">DELETED</span>
                              </div>
                          </div>
                            <div class="col-12 " *ngIf="state_view">
                              <div class="form-check">
                                <input type="checkbox" name="" id="emp-view" class="form-check-input opacity-75" checked disabled="">
                                <label for="emp-view" class="form-check-label detail-desc opacity-75">Employee can view</label>
                              </div>
                            </div>
                            <div class="col-12" *ngIf="state_edit">
                              <div class="form-check">
                                <input type="checkbox" class="form-check-input opacity-75" name="" id="emp-edit" checked disabled="">
                                <label for="emp-edit" class="form-check-label detail-desc opacity-75">Employee can edit</label>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>

              </div>
            </ng-container>
          </div>
      </div>
  </form>
</div>

<!-- Slider add start-->

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" [formGroup]="documentsForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <header class="side-panel-head">
          <h5>{{panelheader}} Document</h5>
          <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid px-32 py-24">
            <!-- Edit loader start -->
            <app-edit-loader *ngIf="loader1" ></app-edit-loader>
            <!-- Edit loader end -->
            <ng-container *ngIf="!loader1" >
             <!--Alert msg section-->
           <div class="alert alert-success show" role="alert" *ngIf="isAlert==true">
              Document {{msgText}} successfully.
             <button class="btn-close" ></button>
           </div>
           <div class="alert alert-warning show" role="alert" *ngIf="isAlert==true">
             Something went to wrong.
             <button class="btn-close" ></button>
           </div>
           <div class="alert alert-danger show" role="alert" *ngIf="isAlert==true">
              You should check in on some of those fields below.
             <button class="btn-close" ></button>
           </div>
          <!--End-->
              <p class="text-uppercase fw-500 mb-12">{{panelheader | uppercase}}  DOCUMENT DETAILS </p>
              <input type="text" value="{{dataId}}" formControlName="id" id="id" hidden>
              <div class="row row-16 ">
                  <div class="col-6 form-row">
                      <label for="name" class="form-label required fs-14">Document name </label>
                      <input type="text" class="form-control" formControlName="name" name="name" id="name" value="" placeholder="{{this.messageService.placeholderdisp('document name')}}" [ngClass]="{ 'is-invalid': (submitted || f.name.dirty || f.name.touched) &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp()">
                      <div *ngIf="(submitted || f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                        <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                        <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                        <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                       <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
                      </div>
                  </div>
                  <div class="col-6 form-row">
                    <label for="" class="form-label required">Document type</label>
                    <ng-select class="form-ngselect"
                         [ngClass]="{ 'is-invalid': (submitted || f.type.touched) && f.type.errors }"
                             formControlName="type"
                            name=""  id="type"
                            placeholder="{{this.messageService.selectplaceholddisplay('document type')}}">
                    <ng-option *ngFor="let type of ['Identification','Education','Employee','Others']" [value]="type">{{type}}</ng-option>
                    </ng-select>
                    <div *ngIf="(submitted || f.type.touched) && f.type.errors" class="invalid-feedback">
                      <div *ngIf="f.type.errors.required">{{this.messageService.validationDisplay('required')}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 ">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" formControlName="view" name="" id="emp-view" [checked]="isChecked">
                      <label for="emp-view" class="form-check-label ">Employee can view</label>

                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" formControlName="edit" name="" id="emp-edit" (change)="editCheckFun($event)">
                      <label for="emp-edit" class="form-check-label ">Employee can edit</label>

                    </div>

                  </div>
              </div>
            </ng-container>
          </div>
      </div>
      <footer class="side-panel-footer gap-32" *ngIf="!loader1">
        <button *ngIf="isdelete==true" class="btn btn-outline-danger text-uppercase btn-sm "
        (click)="deleteToggle = true" type="button" [disabled]='!status'>DELETE</button>
         <button class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]='!status || !documentsForm.valid' >{{buttonTitle}}</button>
      </footer>
  </form>
</div>

<!--Delete confirmation box-->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Delete Document?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{this.messageService.Deletemsgdisplay('Document')}}
              </p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm"
                  (click)="deleteToggle = false">Cancel</button>
              <button type="button" class="btn btn-danger btn-sm" [disabled]="deleteClicked" (click)="deleteDocuments()">Delete</button>
          </div>
      </div>
  </div>
</div>
