<div class="sidebar-wrap">
    <div class="container-fluid p-24">
        <div class="row row-16">
            <div class="col-md-4 col-lg-6">
                <h3>Payroll Dashboard</h3>
            </div>
            <div class="col-md-8 col-lg-6 d-flex align-items-center gap-16">
                <div class="ms-auto" style="width:18.75rem;">
                    <ng-select #ngSelecter class="form-ngselect fs-14" placeholder="Select Company"
                        (change)="companySelected()" [(ngModel)]="company" [items]="companyList"
                        bindLabel="company_name" bindValue="id" [ngClass]="{ 'is-invalid':(company==null || company=='') && !loader }" [clearOnBackspace]="false">
                        <ng-template ng-label-tmp let-item="item" let-index="index">
                            <div class="d-inline-flex  align-items-center">
                                <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                                    *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                                <span
                                    *ngIf="(item.logo ==  ''|| item.logo == null) && item.company_name != '' && item.company_name != null"
                                    class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                                    <span>{{this.appService.getFirstChar(item.company_name,1)}}</span>
                                </span>
                                {{item.company_name}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="d-inline-flex  align-items-center">
                                <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                                    *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                                <span *ngIf="item.logo ==  ''|| item.logo == null"
                                    class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                                    <span>
                                        {{this.appService.getFirstChar(item.company_name,1)}}</span>
                                </span>
                                {{item.company_name}}
                            </div>
                        </ng-template>
                    </ng-select>
                    <div *ngIf="(company==null || company=='') && !loader" class="invalid-feedback">
                      <div *ngIf="(company==null || company=='') && !loader">{{this.messageService.validationDisplay('required')}}</div>
                  </div>
                </div>
                <button *ngIf="config.prior_payroll_status==true && config.is_anypriorpayroll_uploaded==true"
                    class="btn flex-shrink-0 btn-outline-primary  fs-10 fw-700 text-uppercase"
                    routerLink="/prior-payroll/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">Prior
                    payroll</button>
                <button *ngIf="activeItem.payroll_year"
                    class="btn flex-shrink-0 btn-primary fs-10 fw-700 text-uppercase"
                    routerLink="/run-payroll/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">Run
                    payroll</button>
                <button *ngIf="!activeItem.payroll_year && year != '' && month != ''"
                    class="btn flex-shrink-0 btn-primary fs-10 fw-700 text-uppercase"
                    routerLink="/run-payroll/{{year}}/{{month}}/{{company}}">Run payroll</button>
            </div>
            <div *ngIf="config.length == 0 && nodataFound && !loader" class="col-12 ">
              <div class="row" style="height: calc(100vh - 13rem);">
                <app-nodata-view class="vstack" [noDataImg]="'bg13.png'" [noDataText]="'Hooray! payroll is all set'"
                  [noDataPara]="'Manage your payroll effortlessly. Process new payroll runs or review past payments with just a click!.'"
                  [hasPermission]="false">
                </app-nodata-view>
              </div>
            </div>
            <div class="col-12" *ngIf="(activeItem.payroll_month=='September' || activeItem.payroll_month=='October') && banneropen && activeItem.payroll_year == '2024'">
                <div class="bg-grad1 radius-16 overflow-hidden" (click)="bannerSidebar = true">
                  <div class="card card-c2 card-hover text-white bg-pattern1 px-24 justify-content-center flex-row py-32 radius-0 border-0">
                    <p class="mb-0 d-flex align-items-center">
                      <span class="fs-20 fw-600">Union budget 2024-2025 changes are effective from the September pay run.</span>
                      <i class="icon-arrow-right-circle ms-16 fs-18"></i>
                      <!-- <span class="fs-14 ms-16 fw-700 gap-16 align-items-center d-inline-flex" >View details </span> -->
                    </p>
                    <i class="icon-close-lg ms-auto lh-base" (click)="bannerCloseBtn($event)"></i>
                  </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card  px-24 card-c2">
                    <div class="card-body p-0"
                        *ngIf="access">
                        <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselTileItems"
                            (carouselLoad)="myCarousel?.moveTo(movetoPoint,false);">
                            <ngu-tile *nguCarouselDef="let item; let i = index" (click)="clickCarousel(item)">
                                <div
                                    class="card card-c3 {{ item.status }} {{(item.state == 'completed') ? 'success' : ''}}">
                                    <div class="card-body py-8 date">
                                        <div class="fs-14 fw-500">{{ item.month }}</div>
                                        <!-- <div class="fs-10 fw-400 text-uppercase text-light-400">
                                            {{item.range}}
                                        </div> -->
                                    </div>
                                    <div class="card-body status current py-0">
                                        <div class="fs-10 fw-500 text-uppercase ">
                                            {{ item.state }}
                                        </div>
                                    </div>
                                </div>
                            </ngu-tile>
                            <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0.5 : 1">
                                <i class="icon-chevron-left-lg fs-11"></i>
                            </button>
                            <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0.5 : 1">
                                <i class="icon-chevron-right-lg fs-11"></i>
                            </button>
                        </ngu-carousel>
                    </div>
                    <div class="card-body px-0 py-16"
                        *ngIf="config.is_payroll_configured == false && config.is_statutory_configured == false && (config?.prior_payroll_status==false || (config?.prior_payroll_status==true && config.is_anypriorpayroll_uploaded==true))  && access">
                        <div class="row row-16">
                            <div *ngIf="nodataFound && !loader" class="col-12">
                              <div class="row" style="height: calc(100vh - 13rem);">
                                <app-nodata-view class="vstack" [noDataImg]="'bg13.png'" [noDataText]="'Hooray! payroll is all set'"
                                  [noDataPara]="'Manage your payroll effortlessly. Process new payroll runs or review past payments with just a click!.'"
                                  [hasPermission]="false">
                                </app-nodata-view>
                              </div>
                            </div>
                            <div class="col-12" *ngIf="!loader  && !nodataFound">
                                <div class="fw-500 mb-8">Financials</div>
                                <div class="card card-c2 card-highlight highlight-primary">
                                    <div class="card-body px-24 py-12 ">
                                        <div class="d-flex gap-64 flex-wrap">
                                            <div class="">
                                                <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                                                    total payroll cost</div>
                                                <div class="fs-24 mb-6 lh-1">{{payrollMonthlyCost?.month_pay_salary|
                                                    currency:currency}}</div>
                                                <div class="fs-10 fw-500 text-uppercase ">
                                                    <span
                                                        class="text-light-400 ">{{getPreMonth(activeItem.payroll_month,activeItem.payroll_year)}}
                                                    </span>
                                                    <span
                                                        class=" ms-8 {{getClass(payrollMonthlyCost.compare_pay_salary)}}">{{getAbs(payrollMonthlyCost.compare_pay_salary)}}</span>
                                                </div>
                                            </div>
                                            <div class="my-auto">=</div>
                                            <div class="">
                                                <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6">
                                                    Employee Net Pay</div>
                                                <div class="fs-24 mb-6 lh-1">{{payrollMonthlyCost.month_net_pay|
                                                    currency:currency }}</div>
                                                <div class="fs-10 fw-500 text-uppercase "><span
                                                        class="text-light-400 ">{{getPreMonth(activeItem.payroll_month,activeItem.payroll_year)}}
                                                    </span> <span
                                                        class=" ms-8 {{getClass(payrollMonthlyCost.compare_net_pay)}}">{{getAbs(payrollMonthlyCost.compare_net_pay)}}</span>
                                                </div>
                                            </div>
                                            <div class="my-auto">+</div>
                                            <div class="">
                                                <div class="fs-10 fw-500 text-uppercase text-light-400 mb-6"> Total
                                                    deductions</div>
                                                <div class="fs-24 mb-6 lh-1">
                                                    {{payrollMonthlyCost.month_total_deductions|
                                                    currency:currency }}</div>
                                                <div class="fs-10 fw-500 text-uppercase "><span
                                                        class="text-light-400 ">{{getPreMonth(activeItem.payroll_month,activeItem.payroll_year)}}
                                                    </span> <span
                                                        class=" ms-8 {{getClass(payrollMonthlyCost.compare_total_deductions)}}">{{getAbs(payrollMonthlyCost.compare_total_deductions)}}</span>
                                                </div>
                                            </div>
                                            <div class="my-auto">+</div>
                                            <div class="">
                                                <div class="fs-10 fw-500 text-uppercase text-light-400 mb-1">
                                                    Total contributions</div>
                                                <div class="fs-24 mb-1 lh-1">
                                                    {{payrollMonthlyCost.month_employer_contributions|
                                                    currency:currency}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="card card-c2 p-24" *ngIf="!loader  && !nodataFound">
                                    <div class="fw-500 mb-24 lh-1">{{activeItem.payroll_month}}
                                        {{activeItem.payroll_year}}
                                        Payroll
                                        <!-- <span class="fw-400 fs-12 text-light-500 mb-8"
                                            *ngIf="payrollCount?.calender_days != undefined">
                                            ({{payrollCount?.calender_days?.prev_month}} -
                                            {{payrollCount?.calender_days.selected_month}})</span> -->
                                    </div>
                                    <div class="row row-16  " *ngIf="payrollCount?.calender_days != undefined">
                                        <div class="col-12">
                                            <div class="card-c2 card bg14 px-16 py-12 h-100">
                                                <div class="d-flex align-items-center">
                                                    <i class="icon-calendar-fill me-8  fs-16 text-accent1"></i>
                                                    <div class="fs-12 fw-500 text-uppercase  ">calendar
                                                        days
                                                    </div>
                                                </div>
                                                <div class="mt-8 lh-sm fs-32 fw-500 "
                                                    [countUp]="payrollCount?.calender_days.day_count">0</div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="card-c2 card bg7 px-16 py-12 h-100">
                                                <div class="d-flex align-items-center">
                                                    <i class="icon-people-fill me-8  fs-16 text-warning"></i>
                                                    <div class="fs-12  fw-500 text-uppercase  ">
                                                        Employees</div>
                                                </div>
                                                <div [countUp]="payrollCount.active_employees_count"
                                                    class="lh-sm mt-8 fs-32 fw-500 ">0</div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="card-c2 card bg8 px-16 py-12 h-100">
                                                <div class="d-flex align-items-center">
                                                    <i class="icon-cash-stack-fill me-8  fs-16 text-primary"></i>
                                                    <div class="fs-12 fw-500 text-uppercase  ">Payroll
                                                        processed
                                                    </div>
                                                </div>
                                                <div class="lh-sm mt-8 fs-32 fw-500 "> <span
                                                        [countUp]="payrollCount.pay_employee_count">0</span>/{{payrollCount.active_employees_count}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="card card-c2 p-24 h-100" *ngIf="!loader  && !nodataFound &&graphloader">
                                    <app-graph-loader></app-graph-loader>

                                </div>
                                <div class="card card-c2 p-24 h-100" *ngIf="!loader  && !nodataFound &&!graphloader">
                                    <div class="row h-100">
                                        <div class="col-12 d-flex">
                                            <div class="fw-500 ">Payroll summary</div>
                                            <ng-select class="form-ng-select ms-auto fs-14 h-36" placeholder="Year"
                                                [clearable]="false" (change)="changeGraph($event)"
                                                [(ngModel)]="graphyear">
                                                <ng-option class="fs-12" *ngFor="let year of range"
                                                    [value]="year.k">{{year.v}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-12" *ngIf="!nographdata ">
                                            <app-bar-chart style="max-height: 19rem;" [legendPosition]="'end'"
                                                [data]="multi" [labels]="xAxisLabel"
                                                [currency]="yAxisLabel"></app-bar-chart>
                                        </div>
                                        <div class="col-12 " *ngIf="nographdata ">
                                            <div class="flex-center h-100 d-flex flex-column gap-20">
                                                <img src="assets\images\bg\line-art1.svg" class="img-fluid">
                                                <p class="fs-14 fw-500">No Payroll summary found</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="card-body d-flex flex-column p-24">
                                        <div class="d-flex ">
                                            <div class="fw-500 ">Payroll summary</div>
                                            <ng-select class="form-ng-select ms-auto fs-14 h-36" placeholder="Year"
                                                [clearable]="false" (change)="changeGraph($event)"
                                                [(ngModel)]="graphyear">
                                                <ng-option class="fs-12" *ngFor="let year of range"
                                                    [value]="year.k">{{year.v}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <ng-container *ngIf="!nographdata ">
                                            <app-bar-chart style="max-height: 17.4375rem;" [data]="multi"
                                                [labels]="xAxisLabel" [yText]="yAxisLabel"></app-bar-chart>
                                        </ng-container>
                                        <div class="col-12 " *ngIf="nographdata ">
                                            <div class="flex-center d-flex flex-column gap-20" style="height: 15rem;">
                                                <img src="assets\images\bg\line-art1.svg" class="img-fluid">
                                                <p class="fs-14 fw-500">No Payroll summary found</p>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                            <div class="col-12" *ngIf="!loader  && !nodataFound">
                                <div class="card card-c2">
                                    <div class="card-body py-0 px-24 d-flex align-items-end">
                                        <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab1"
                                            class="nav radius-4 nav-custom shadow-0 p-0" style="min-height: unset;" (navChange)="onNavChange($event)">
                                            <li [ngbNavItem]="1">
                                                <a ngbNavLink class="nav-link h-100 py-16">Salary summary</a>
                                                <ng-template ngbNavContent>
                                                    <app-reconcilation-view [fromDashboard]="true" [issalsumm]="true"
                                                        [salarysummaryList]="salarysummaryList"
                                                        [month]="activeItem.payroll_month"
                                                        [year]="activeItem.payroll_year" [searchString]="searchString"
                                                        [page]="page" [pageSize]="pageSize"></app-reconcilation-view>
                                                </ng-template>
                                            </li>
                                            <li [ngbNavItem]="2">
                                                <a ngbNavLink class="nav-link h-100 py-16">Employee salary summary</a>
                                                <ng-template ngbNavContent>
                                                    <app-reconcilation-view  [fromDashboard]="true" [isempsalsumm]="true"
                                                        [empsalarysummaryList]="empsalarysummaryList"
                                                        [month]="activeItem.payroll_month"
                                                        [year]="activeItem.payroll_year" [searchString]="searchString"
                                                        [page]="page" [pageSize]="pageSize"></app-reconcilation-view>
                                                </ng-template>
                                            </li>
                                        </nav>
                                        <div class="d-flex align-items-center gap-16 ms-auto py-12">
                                            <div class="form-icon icon-left">
                                                <input type="text" class="form-control fs-14"
                                                    placeholder="{{this.messageService.searchdisplay('novalue')}}   " autocomplete="off"
                                                    name="searchString" [(ngModel)]="searchString" (keyup)="page=1">
                                                <i class="icon-search"></i>
                                            </div>
                                            <button class="btn-square btn btn-secondary" (click)="filter  = true">
                                                <i class="icon-filter-left fs-13"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-24">
                                        <div [ngbNavOutlet]="nav"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-c1-loader *ngIf="loader"></app-c1-loader>
                </div>
            </div>
            <div *ngIf="(config.is_payroll_configured || config.is_statutory_configured || (config.prior_payroll_status && config.is_anypriorpayroll_uploaded==false)) && access && !loader"
                class="col-12 flex-center" style="min-height:calc(100vh - (3rem + 2.5rem + 8.438rem + 5rem ))">
                <!-- <div class="card card-c2 p-24 mx-auto" style="max-width: 26.938rem;">
                    <div class="fs-24">Payroll checklist</div>
                    <div class="fs-12 mb-16 fw-500 text-light-400">The Payroll Checklist enables you to verify that the
                        steps listed below are completed before processing the month's payroll.</div>
                    <div class="d-flex flex-column bg29 p-12 radius-4 mb-16">
                        <div class="d-flex w-100 fs-12 mb-8">
                            <span>Checklist progress</span>
                            <span *ngIf="config.prior_payroll_status" class="ms-auto fw-500">{{progress/33}}/3</span>
                            <span *ngIf="config.prior_payroll_status==false" class="ms-auto fw-500">{{progress/50}}/2</span>
                        </div>
                        <div class="mb-0">
                            <ngb-progressbar class="progress-type3" type="success" [value]="progress"></ngb-progressbar>
                        </div>
                    </div>
                    <ul class="custom-checklist">
                        <li [class]="{'success':config.is_payroll_configured==false }" routerLink="/configuration">
                            Update Payroll configuration</li>
                        <li [class]="{'success':config.is_statutory_configured==false }" routerLink="/statutory">
                            Configure statutory for company</li>
                        <li *ngIf="config.prior_payroll_status" [class]="{'success':config.complete_priorpayroll==true }" (click)="movePrior()" >Add prior payroll</li>
                    </ul>
                </div> -->
                <app-payroll-checklist class="w-100" [config]="config" [progress]="progress"
                    [payroll_month]="activeItem?.payroll_month" [company]="company">
                </app-payroll-checklist>
            </div>
            <!-- <div *ngIf="config.is_payroll_configured == false && config.is_statutory_configured == false  && access"
                class="col-12">
                <div class="card card-c2">
                    <div class="card-body p-24">
                        <div class="row row-16">
                            <app-view-loader *ngIf="loader"></app-view-loader>
                            <ng-container *ngIf="nodataFound">
                                <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                </div>
                            </ng-container>
                            <div class="col-12" *ngIf="!loader  && !nodataFound">
                            </div>
                            <div class="col-4" *ngIf="!loader  && !nodataFound">
                                <div class="card card-c2">
                                </div>
                            </div>
                            <div class="col-8" *ngIf="!loader  && !nodataFound">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div *ngIf="!access  && !loader" class="col-6 flex-center  flex-column mx-auto gap-24"
                style="min-height:calc(100vh - (3rem + 2.5rem + 8.438rem + 5rem ))">
                <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/5 Signup.png"
                    style="width: 13.881rem;">
                <!-- <p class="text-uppercase text-light-400 fw-700 fs-14 mt-3">There are no employees mapped to the current organization. Kindly select a differant company to proceed</p> -->
                <div role="alert" class="alert alert-warning mb-0 show mb-12">
                    <p class="mb-0 fs-14">There are no employees mapped to the current company. Kindly select a
                        different company to proceed</p>
                    <div class="btn-close pointer"></div>
                </div>
            </div>
        </div>
    </div>
    <app-salary-dashboard-filter [filter]="filter" [company]="company" (applymethod)="submitForm($event)"
        (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
    <!-- <div class="draggable-popup popup-primary px-16 py-20" id="draggableElement">>
        <div  class="d-flex">
            <i class="icon-arrow-repeat fs-14 me-8"></i>
            <div class="d-flex flex-column w-100 ">
                <div class="d-flex mb-8">
                    <p class="mb-0 fs-14 lh-1 me-16">Salary Processing</p>
                    <button class="btn-reset ms-auto bg24 sq-30 radius-2 text-white"><i
                            class="icon-chevron-right-lg fs-12"></i></button>
                </div>
                <div class="d-flex">
                    <span class="fs-12">30% completed</span>
                    <span class="fs-10 ms-auto opacity-75">1 min 58 sec remaining</span>
                </div>
                <div class="mb-0">
                    <ngb-progressbar class="progress-type4" [value]="50"></ngb-progressbar>
                </div>
            </div>
        </div> -->
        <app-tax-changes-banner *ngIf="bannerSidebar" [bannerSidebar]="bannerSidebar" (panelclose)="closebanner($event)"></app-tax-changes-banner>
