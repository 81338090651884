<div class="container-fluid p-24">
    <div class="row row-24">
        <div class="col-12 d-flex flex-wrap align-items-center gap-16">
            <h3>Run Payroll</h3>
            <div class="ms-auto d-flex flex-wrap  gap-16">
        <div
          *ngIf="config.is_payroll_configured == false  && (config.prior_payroll_status==false||config.is_anypriorpayroll_uploaded)   && access && !loader && !nodataFound"
                    class="slider-input-container td-dropdown" style="width:16.25rem;">
          <button [disabled]="activeItem?.key==0" (click)="changeCarousel(activeItem?.key-1)" class="slider-btn-left"><i
              class="icon-chevron-left-lg "></i></button>
                    <div *ngIf="activeItem" class="slider-input " ngbDropdown container="body" placement="bottom">
                        <div ngbDropdownToggle class="slider-input-value">
                            <span class="fs-14 fw-500">{{activeItem.month}}</span>
                            <!-- <span class="fs-10 fw-500 text-light-500"
                                *ngIf="activeItem.range">({{activeItem.range}})</span> -->
                        </div>
                        <div ngbDropdownMenu class="card-c2 card p-16" style="width:16.25rem;">
                            <div class="row row-12">
                                <div class="col-5">
                                    <label for="" class="form-label required">Year </label>
                                    <ng-select class="form-ngselect fs-14" [(ngModel)]="selYear"  (change)="changeYear($event)"
                                        [placeholder]="messageService.selectplaceholddisplay('Year')">
                    <ng-option *ngFor="let yData of sliderDropdownYear" [value]="yData">{{yData}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-7">
                                    <label for="" class="form-label required">Month </label>
                                    <ng-select class="form-ngselect fs-14"  [(ngModel)]="selMonth"
                    [placeholder]="messageService.selectplaceholddisplay('Month')" (change)="changeCarousel($event)">
                                        <ng-option *ngFor="let yData of sliderDropdownMonth[selYear]"
                                            [value]="yData?.key">{{yData.month}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                <div>
                  <!-- <ng-select style="width:16.25rem;" #ngSelecter class="form-ngselect fs-14" placeholder="Select Company"
                      (change)="companySelected()" [(ngModel)]="company" [items]="companyList" bindLabel="company_name"
                      bindValue="id"  [ngClass]="{ 'is-invalid':(company==null || company=='') && !loader }" [clearOnBackspace]="false">
                      <ng-template ng-label-tmp let-item="item" let-index="index">
                          <div class="d-inline-flex  align-items-center">
                              <img class="avatar-sm me-8 sq-28 img-fluid rounded-circle"
                                  *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                              <span
                                  *ngIf="(item.logo ==  ''|| item.logo == null) && item.company_name != '' && item.company_name != null"
                                  class="avatar-sm sq-28 me-8 rounded-circle bg{{index%5}}">
                                  <span>{{this.appservice.getFirstChar(item.company_name,1)}}</span>
                              </span>
                              {{item.company_name}}
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                          <div class="d-inline-flex  align-items-center">
                              <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                                  *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                              <span *ngIf="item.logo ==  ''|| item.logo == null"
                                  class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                                  <span>
                                      {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                              </span>
                              {{item.company_name}}
                    </div>
                      </ng-template>
                  </ng-select>
                  <div *ngIf="(company==null || company=='') && !loader" class="invalid-feedback">
                    <div *ngIf="(company==null || company=='') && !loader">{{this.messageService.validationDisplay('required')}}</div>
                  </div> -->
                </div>
                <!-- <button
                    *ngIf="config.is_payroll_configured == false && config.is_statutory_configured == false && config.prior_payroll_status==true && config.is_anypriorpayroll_uploaded==true && access && !loader && !nodataFound"
                    class="btn flex-shrink-0 btn-primary fs-10 fw-700 text-uppercase px-4"
                    routerLink="/prior-payroll/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">Prior
                    payroll 1</button> -->
            </div>
          </div>
                    <button [disabled]="activeItem?.key==this.carouselTileItems.length-1"
                        (click)="changeCarousel(activeItem?.key+1)" class="slider-btn-right"><i
                            class="icon-chevron-right-lg"></i></button>
                </div>
                  <ng-select style="width:16.25rem;" #ngSelecter class="form-ngselect fs-14" placeholder="Select Company"
                      (change)="companySelected()" [(ngModel)]="company" [items]="companyList" bindLabel="company_name"
                      bindValue="id"  [ngClass]="{ 'is-invalid':(company==null || company=='') && !loader }" [clearOnBackspace]="false">
                      <ng-template ng-label-tmp let-item="item" let-index="index">
                          <div class="d-inline-flex  align-items-center">
              <img class="avatar-sm me-8 sq-28 img-fluid rounded-circle" *ngIf="item.logo !=  '' && item.logo != null"
                src="{{item.logo}}">
                              <span
                                  *ngIf="(item.logo ==  ''|| item.logo == null) && item.company_name != '' && item.company_name != null"
                                  class="avatar-sm sq-28 me-8 rounded-circle bg{{index%5}}">
                                  <span>{{this.appservice.getFirstChar(item.company_name,1)}}</span>
                              </span>
                              {{item.company_name}}
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                          <div class="d-inline-flex  align-items-center">
              <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle" *ngIf="item.logo !=  '' && item.logo != null"
                src="{{item.logo}}">
                              <span *ngIf="item.logo ==  ''|| item.logo == null"
                                  class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                                  <span>
                                      {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                              </span>
                              {{item.company_name}}
                          </div>
                      </ng-template>
                  </ng-select>
                  <div *ngIf="(company==null || company=='') && !loader" class="invalid-feedback">
                    <div *ngIf="(company==null || company=='') && !loader">{{this.messageService.validationDisplay('required')}}</div>
                  </div>
                <button
                    *ngIf="config.is_payroll_configured == false && config.is_statutory_configured == false && config.prior_payroll_status==true && config.is_anypriorpayroll_uploaded==true && access && !loader && !nodataFound"
                    class="btn flex-shrink-0 btn-primary fs-10 fw-700 text-uppercase px-4"
                    routerLink="/prior-payroll/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">Prior
                    payroll</button>
            </div>
        </div>
        <div class="col-12" *ngIf="yearEndReminder && isOpen && !loader">
            <div role="alert" class="alert alert-warning show mb-0">
                <div>
                    <p class="fw-500 mb-1">Pending Requests</p>
                    <p class="fs-12 mb-0">Salary cannot be processed for few employees since they have some pending
                        requests that might effect the Income tax calculation.</p>
                </div>
        <button routerLink="/pending-request/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}"
          class="btn btn-warning fw-500 fs-12 text-white text-nowrap px-4 py-6 ms-auto" style="min-height:unset;">
                    View details
                </button>
                <div class="btn-close" (click)="isOpen=false;"></div>
            </div>
        </div>
        <div class="col-12" *ngIf="(activeItem.payroll_month=='September' || activeItem.payroll_month=='October') && banneropen && activeItem.payroll_year == '2024'">
          <div class="bg-grad1 radius-16 overflow-hidden" (click)="bannerSidebar = true">
            <div class="card card-c2 card-hover text-white bg-pattern1 px-24 justify-content-center flex-row py-32 radius-0 border-0">
                <p class="mb-0 d-flex align-items-center">
                    <span class="fs-20 fw-600">Union budget 2024-2025 changes are effective from the September pay run.</span>
                    <i class="icon-arrow-right-circle ms-16 fs-18"></i>
                    <!-- <span class="fs-14 ms-16 fw-700 gap-16 align-items-center d-inline-flex" >View details </span> -->
              </p>
              <i class="icon-close-lg ms-auto lh-base" (click)="bannerCloseBtn($event)"></i>
            </div>
          </div>
        </div>
        <app-view-loader *ngIf="loader"></app-view-loader>
        <div class="row " style="height: calc(100vh - 10rem);"
          *ngIf="((config.is_payroll_configured == false && config.is_statutory_configured == false && (config.prior_payroll_status==false||config.is_anypriorpayroll_uploaded) ) || config.length==0)  && nodataFound && access">
          <app-nodata-view class="vstack" [noDataImg]="'bg11.png'" [noDataText]="'It\'s payday!'"
            [noDataPara]="'Run payroll quickly and accurately. Ensure everyone gets paid on time with ease.'"
            [hasPermission]="false">
          </app-nodata-view>
        </div>
        <div class="col-md-7"
            *ngIf="config.is_payroll_configured == false && config.is_statutory_configured == false && (config.prior_payroll_status==false||config.is_anypriorpayroll_uploaded)  && access  && !loader && !nodataFound">
            <div class="row row-4">
                <div class="col-12">
                    <p class="mb-0 fw-500">Payroll for {{activeItem.payroll_month}} {{activeItem.payroll_year}} <span
                            [ngClass]="{'badge-primary' : activeItem.state == 'upcoming', 'badge-success' :  activeItem.state == 'completed', 'badge-warning' : activeItem.state == 'pending'}"
                            class=" text-uppercase ms-8 fs-10 fw-500 badge-custom">{{activeItem.state}}</span></p>
                </div>
                <div class="col-12">
                    <div [class.success]="payrollRunData?.attendance_lop == 'completed'"
                        [class]="{'pointer-event':disableclick}" [class.opacity-50]="disableclick"
                        class="card card-c2 card-hover flex-row d-flex align-items-center px-24 py-12"
                        routerLink="/run-payroll/attendance-&-lop/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <div class="bg8 sq-42 avatar-circle me-16">
                            <i class="icon-file-earmark-arrow-left fs-18 text-accent2"></i>
                        </div>
                        <div class="d-flex flex-column gap-4">
                            <div class="fs-10 text-light-500 text-uppercase fw-500">Step 1
                                {{payrollRunData?.attendance_lop}}</div>
                            <div class="fw-600 fs-14">{{isAttendanceLop==true?'Attendance & LOP':'Attendance and arrear process'}}<i
                                    class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                                    ngbPopover="User can either upload attendance or add LOP days in an excel format for the current pay period. System will calculate the paid days based on the uploaded data."
                                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div [class.success]="payrollRunData?.salary_revision_arreras == 'completed'"
                        [class]="{'pointer-event':disableclick}" [class.opacity-50]="disableclick"
                        class="card card-c2 card-hover  flex-row d-flex align-items-center px-24 py-12"
                        routerLink="/run-payroll/salary-revision-and-arrears/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <div class="bg24 sq-42 avatar-circle me-16">
                            <i class="icon-briefcase-clock-fill fs-18 text-accent1 "></i>
                        </div>
                        <div class="d-flex flex-column gap-4">
                            <div class="fs-10 text-light-500 text-uppercase fw-500">STEP 2 (Optional)</div>
                            <div class="fw-600 fs-14">Salary revision arrears <i
                                    class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                                    ngbPopover=" If any employee's salary has been revised and is effective from the previous months, their information will be auto-populated here. The user can pay the arrears for the selected months in the current payroll or ignore the arrears if they do not want to pay them."
                                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div [class.success]="payrollRunData?.variable_payments_deductions== 'completed'"
                        [class]="{'pointer-event':disableclick}" [class.opacity-50]="disableclick"
                        class="card card-c2 card-hover  flex-row d-flex align-items-center px-24 py-12"
                        routerLink="/run-payroll/variable-payment-&-deduction/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <div class="bg-warning sq-42 avatar-circle me-16">
                            <i class="icon-clipboard-clock fs-18  text-warning"></i>
                        </div>
                        <div class="d-flex flex-column gap-4">
                            <div class="fs-10 text-light-500 text-uppercase fw-500">STEP 3 (Optional)</div>
                            <div class="fw-600 fs-14">{{isAttendanceLop==true?'Variable payments and deductions':'Overtime and other variable payment'}} <i
                                    class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                                    ngbPopover="This option allows for the payment of variable components and perquisites. If an employee's salary structure does not specify the payment months for variable components, those components can be added to the current payroll using this option. If no changes are needed to the payment amount, the specified components will be automatically added to the current payroll. If changes are required, they can be made, and the default amount will be overridden."
                                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <!-- [class.success]="payrollRunData?.expense_reimbursement == 'completed'" -->
                  <div
                  [class]="{'pointer-event':disableclick}" [class.opacity-50]="disableclick"
                  class="card card-c2 card-hover  flex-row d-flex align-items-center px-24 py-12"
                    routerLink="/run-payroll/expense-reimbursement/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                    <div class="bg48 sq-42 avatar-circle me-16">
                      <i class="icon-cash-stack-fill fs-18 text22"></i>
                    </div>
                    <div class="d-flex flex-column gap-4">
                      <div class="fs-10 text-light-500 text-uppercase fw-500">STEP 4</div>
                      <div class="fw-600 fs-14">Expense reimbursement<i
                          class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8" ngbPopover="If the user opts for reimbursement via payroll, the corresponding expense reports will be displayed in this step, and user can select and pay them along with the payroll."
                          popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="col-12">
                    <div [class.success]="payrollRunData?.process_unprocess == 'completed'"
                        [class]="{'pointer-event':disableclick}" [class.opacity-50]="disableclick"
                        class="card card-c2 card-hover  flex-row d-flex align-items-center px-24 py-12"
                        routerLink="/run-payroll/process-salary/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <div class="bg5 sq-42 avatar-circle me-16">
                            <i class="icon-arrow-repeat fs-18 text15"></i>
                        </div>
                        <div class="d-flex flex-column gap-4">
              <div class="fs-10 text-light-500 text-uppercase fw-500">STEP 5</div>
                            <div class="fw-600 fs-14">Process/Unprocess salary<i
                                    class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                                    ngbPopover=" In this step, employees' actual net pay is calculated based on paid days and deductions. Once processed, the system will not allow changes to attendance or other payroll-related inputs;user needs to Unprocess first inorder to make any changes in payroll inputs."
                                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div [class.success]="payrollRunData?.override == 'completed'"
                        [class]="{'pointer-event':disableclick}" [class.opacity-50]="disableclick"
                        class="card card-c2 card-hover  flex-row d-flex align-items-center px-24 py-12"
                        routerLink="/run-payroll/overrider/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <div class="bg25 sq-42 avatar-circle me-16">
                            <i class="icon-files-arrow-in-right fs-18 text16"></i>
                        </div>
                        <div class="d-flex flex-column gap-4">
              <div class="fs-10 text-light-500 text-uppercase fw-500">STEP 6 (Optional)</div>
                            <div class="fw-600 fs-14">Override PC/PT/ESI/LWF/TDS/PF <i
                                    class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                                    ngbPopover="Override feature in Run payroll allows  the authorized user to modify the values of the Pay components like Basic, HRA etc. and also the statutory components such as PT,LWF,ESI and TDS after the salary processing."
                                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div [class]="{'success':payrollRunData?.freeze_unfreeze == 'completed'}"
                        class="card card-c2 card-hover flex-row d-flex align-items-center px-24 py-12"
                        routerLink="/run-payroll/freez-publish-salary/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <div class="bg26 sq-42 avatar-circle me-16">
                            <i class="icon-file-grid-fill fs-18 text17"></i>
                        </div>
                        <div class="d-flex flex-column gap-4">
              <div class="fs-10 text-light-500 text-uppercase fw-500">STEP 7</div>
                            <div class="fw-600 fs-14">Freeze/Unfreeze salary <i
                                    class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                                    ngbPopover=" Freeze means locking the payroll. System will generate bank transfer statement only for the employees whose payroll is in freezed stage. Once Freezed, the system will not allow to Un-process salary or make any changes in payroll inputs, user needs to Unfreeze first in-order to Un-process or make any changes to payroll inputs."
                                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class=" radius-4 px-24 py-2 d-flex align-items-center bg28"
                        routerLink="/run-payroll/salary-reconciliation/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <span class="fw-500 fs-12 me-1">Verify the salary under </span>
                        <span class="fw-600 fs-14 link-primary1"> Salary reconciliation</span>
                    </div>
                </div>
                <div class="col-12">
                    <div [class]="{'success':payrollRunData?.publish == 'completed'}"
                        class="card card-c2 card-hover flex-row d-flex align-items-center px-24 py-12"
                        routerLink="/run-payroll/publish-salary/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                        <div class="bg6 sq-42 avatar-circle me-16">
                            <i class="icon-window-arrow-in-up fs-18 text18"></i>
                        </div>
                        <div class="d-flex flex-column gap-4">
              <div class="fs-10 text-light-500 text-uppercase fw-500">STEP 8</div>
              <div class="fw-600 fs-14">Publish/Unpublish salary <i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon ms-8"
                                    ngbPopover="In this step, the system will send payslips to employees' self-service portals as well as their official email addresses. After a successful salary transfer to an employee's bank account, the user can publish the salary. You can also withdraw or unpublish the salary once it has been published."
                                    popoverClass="popover-default popover-info" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5"
            *ngIf="config.is_payroll_configured == false && config.is_statutory_configured == false && (config.prior_payroll_status==false||config.is_anypriorpayroll_uploaded)  && access  && !loader && !nodataFound">
            <div class="card card-c2 p-24">
                <div class="row row-12">
                    <div class="col-12 d-flex">
                        <div class="fw-500">Payroll summary </div>
                        <a class="ms-auto link-primary1 fs-12"
                            routerLink="/salary-dashboard/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">View
                            details</a>
                    </div>
                    <div class="col-12">
                        <div class="card card-c2 card-highlight highlight-primary horizontal-body flex-wrap py-14">
                            <div class="card-body px-24 py-0">
                                <div class="fs-10 fw-500 text-uppercase text-light-400 mb-1"> total payroll cost
                                </div>
                                <div class="fs-24 ">{{countData?.total_payroll_cost| currency:currency }}</div>
                            </div>
                            <div class="card-body px-24 py-0">
                                <div class="fs-10 fw-500 text-uppercase text-light-400 mb-1">EMPLOYEES NET PAY</div>
                                <div class="fs-24 ">{{countData?.employee_net_pay| currency:currency }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12  pointer">
            <div
              routerLink="/run-payroll/held-salary/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}"
                            class="fw-500">Salaries on hold</div>
                    </div>
                    <div class="col-12" *ngIf="holdData.length==0;else holdlist">
                        <div class="card card-c2 px-18  py-14">
                            <p class="text-light-400 fs-14 fw-500 mb-0"><i class="bi bi-file-text me-10"></i>There are
                                no salaries on hold currently</p>
                        </div>
                    </div>
                    <ng-template #holdlist>
                        <div class="col-12">
                            <div class="card card-c2 px-18  py-14">
                                <div class="row row-8">
                                    <div class="col-12 lh-1">
                                        <span class="fs-10 fw-500 text-uppercase text-light-400">{{holdData.length}}
                                            salaries on hold</span>
                                    </div>
                                    <div class="col-12 d-flex  align-items-center">
                    <div class="d-flex align-items-center overlap-avatar overlap-left" container="main-content">
                                            <ng-container *ngFor="let hold of holdData,let i = index">
                                                <img class="avatar-sm sq-32 avatar-circle"
                                                    *ngIf="i < 3 && hold.profile_image !=  '' && hold.profile_image !=  null"
                                                    src="{{hold.profile_image}}" [ngbPopover]="popContentSingle"
                          popoverClass="user-list single-user" triggers="hover" container="body">
                                                <div class="avatar-sm sq-32 avatar-circle {{hold.color_code}}"
                                                    *ngIf="i < 3 && (hold.profile_image ==  ''|| hold.profile_image ==  null)"
                          [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                          container="body">
                                                    <span>{{this.appservice.getFirstChar(hold.employee_name,2)}}</span>
                                                </div>
                                                <ng-template #popContentSingle>
                                                    <div class="user-details">
                                                        <span class="user-label">{{hold?.employee_name}}</span>
                                                        <span class="user-code">EMP CODE: {{hold?.employee_code}}
                                                        </span>
                                                    </div>
                                                </ng-template>
                        <div *ngIf="i==3" class="avatar-sm sq-32 avatar-circle" [ngbPopover]="popContentMultilpe"
                          popoverClass="user-list multi-user" triggers="click" container="body">
                                                    <span>+{{this.appservice.subEmp(holdData.length)}}</span>
                                                </div>
                                            </ng-container>
                                            <ng-template #popContentMultilpe>
                                                <ul>
                                                    <ng-container *ngFor="let re of holdData,let i = index">
                                                        <ng-container *ngIf="i >= 3">
                              <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                                                                <img class="user-avatar" src="{{re.profile_image}}">
                                                                <div class="user-details">
                                                                    <span class="user-label">{{re.employee_name}}</span>
                                                                    <span class="user-code">EMP CODE :
                                                                        {{re.employee_code}}</span>
                                                                </div>
                                                            </li>
                              <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                                                                <span
                                                                    class="user-avatar {{re.color_code}}">{{this.appservice.getFirstChar(re.employee_name,2)}}</span>
                                                                <div class="user-details">
                                                                    <span class="user-label">{{re.employee_name}}</span>
                                                                    <span class="user-code">EMP CODE :
                                                                        {{re.employee_code}}</span>
                                                                </div>
                                                            </li>
                                                        </ng-container>
                                                    </ng-container>
                                                </ul>
                                            </ng-template>
                                        </div>
                                        <div class="ms-auto">
                                            <a routerLink="/run-payroll/held-salary/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}"
                                                class="link-primary1 px-12 py-8 bg21 text-nowrap fs-12 fw-500 radius-4 pointer">Process
                                                salary</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="col-6">
                        <div class="card-c2 card bg5 px-16 py-8  pointer"
                            routerLink="/run-payroll/alerts/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                            <div class="d-flex align-items-center">
                                <i class="bi bi-exclamation-triangle-fill me-8  fs-20 text-danger"></i>
                                <div class="fs-12 fw-500 text-uppercase  ">ALERTS</div>
                            </div>
                            <div class="lh-sm fs-32 fw-500 " [countUp]="countData?.alert_count"> 0</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card-c2 card bg7 px-16 py-8 pointer"
                            routerLink="/run-payroll/reminders/{{activeItem.payroll_year}}/{{activeItem.payroll_month}}/{{company}}">
                            <div class="d-flex align-items-center">
                                <i class="bi bi-bell-fill me-8  fs-20 text-warning"></i>
                                <div class="fs-12  fw-500 text-uppercase  ">REMINDERS</div>
                            </div>
                            <div [countUp]="countData?.reminder_count" class="lh-sm fs-32 fw-500 ">0 </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div
      *ngIf="(config.is_payroll_configured || config.is_statutory_configured || (config.prior_payroll_status && config.is_anypriorpayroll_uploaded==false)) && access  && !loader"
            class="col-12 flex-center" style="min-height:calc(100vh - (3rem + 2.5rem + 8.438rem + 5rem ))">
            <!-- <div class="card card-c2 p-24 mx-auto" style="max-width: 26.938rem;">
                <div class="fs-24">Payroll checklist</div>
                <div class="fs-12 mb-16 fw-500 text-light-400">The Payroll Checklist enables you to verify that the
                    steps
                    listed below are completed before processing the month's payroll.</div>
                <div class="d-flex flex-column bg29 p-12 radius-4 mb-16">
                    <div class="d-flex w-100 fs-12 mb-8">
                        <span>Checklist progress</span>
                        <span *ngIf="config.prior_payroll_status" class="ms-auto fw-500">{{progress/33}}/3</span>
                        <span *ngIf="config.prior_payroll_status==false" class="ms-auto fw-500">{{progress/50}}/2</span>
                    </div>
                    <div class="mb-0">
                        <ngb-progressbar class="progress-type3" type="success" [value]="progress"></ngb-progressbar>
                    </div>
                </div>
                <ul class="custom-checklist">
                    <li [class]="{'success':config.is_payroll_configured==false }" routerLink="/configuration">Update
                        Payroll configuration</li>
                    <li [class]="{'success':config.is_statutory_configured==false }" routerLink="/statutory">Configure
                        statutory for company</li>
                    <li *ngIf="config.prior_payroll_status" [class]="{'success':config.complete_priorpayroll==true }"
                        (click)="movePrior()">Add prior payroll</li>
                </ul>
            </div> -->
            <app-payroll-checklist class="w-100" [config]="config" [progress]="progress"
                [payroll_month]="activeItem?.payroll_month" [company]="company">
            </app-payroll-checklist>
        </div>
        <div *ngIf="(!access || !hasEmployee) && !isClosed  && !loader"
            class="col-6 flex-center  flex-column mx-auto gap-24"
            style="min-height:calc(100vh - (3rem + 2.5rem + 8.438rem + 5rem ))">
      <img class="img-fluid mx-auto " src="../../../../../assets/images/bg/5 Signup.png" style="width: 13.881rem;">
            <div role="alert" class="alert alert-warning mb-0 show mb-12">
                <p class="mb-0 fs-14" *ngIf="!access && hasEmployee">
                    You do not have data access rights for the selected company, Please select another company to
                    proceed.</p>
                <p class="mb-0 fs-14" *ngIf="!hasEmployee">
                    There are no employees mapped to the current company. Kindly select a
                    different company to proceed.</p>
                <div class="btn-close pointer" (click)="isClosed=true;"></div>
            </div>
        </div>
    </div>
</div>
<app-tax-changes-banner *ngIf="bannerSidebar" [bannerSidebar]="bannerSidebar" (panelclose)="closebanner($event)"></app-tax-changes-banner>
