<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Form 16</h3>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <!-- <div class=" " style="width:22.6875rem;">
                  <div class="no-item-image">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey" (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchform16($event)"></app-searchbar>
                </div>
                </div> -->
        <div class=" d-inline-flex">
          <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
        </div>
        <!-- <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                      <i class="icon-filter-left fs-13"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                      <button ngbDropdownItem [class]="{'active':currentFilterYear=='all' }" (click)="yearFilter('all')" >All</button>
                      <button ngbDropdownItem [class]="{'active':currentFilterYear=='previous year' }" (click)="yearFilter('previous year')">Previous Year</button>
                      <button ngbDropdownItem [class]="{'active':currentFilterYear=='current year' }" (click)="yearFilter('current year')">Current Year</button>

                    </div>
                </div> -->

      </div>
    </div>
  </div>
  <!-- <app-view-loader *ngIf="loader"></app-view-loader> -->

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-md-6" [cardType]="'card10'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="(nodatafound && !loader);else all_data" class="row " style="height: calc(100vh - 10rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg34.png'" [noDataText]="'It seems no form 16 has been generated'"
      [noDataPara]="'Generate and distribute form 16 effortlessly to simplify tax filing for your employees.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16 " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class=" col-md-6" *ngFor="let form16_data of form16_data">
          <div class="card card-c2 px-24 py-8">
            <div class="card-body px-0 py-16">
              <div class="d-flex">
                <p class=" mb-24 fw-500">Form 16 generation</p>
                <p class=" fs-12 ms-auto text-accent1">Financial Year
                  {{this.appService.getFinancialYearRange(form16_data.year)}}</p>

              </div>

              <div class="row  row-16 fs-12">
                <div class="col-lg-6 ">
                  <div class="text-light-400">Part A</div>
                  <div class="fw-500 ">{{form16_data?.partA?.pending}} pending | {{form16_data?.partA?.uploaded}}
                    uploaded</div>
                </div>
                <div class="col-lg-6">
                  <div class="text-light-400">Part B</div>
                  <div class="fw-500 ">{{form16_data?.partB?.pending}} pending | {{form16_data?.partB?.uploaded}}
                    uploaded</div>
                </div>
              </div>
            </div>
            <div class="card-body px-0 py-16">
              <div class="row row-16  ">
                <div class="col-lg-6 fs-12">
                  <div class="text-light-400">Form 16</div>
                  <div class="fw-500 ">{{form16_data?.form16?.pending}} pending | {{form16_data?.form16?.uploaded}}
                    generated</div>
                </div>
                <div class="col-lg-6" *ngIf="permissions.a">
                  <a routerLink="/form-16/generate-form-16/{{currentFilterYear}}" class="btn btn-primary fs-14 text-uppercase w-100">Generate
                    form 16</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-md-6" [cardType]="'card10'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>
</div>
