<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row  mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Data Access</h3>
            <p class="text-helper mb-md-0">Data access rights are configured at the module level for each group to specify which employee data it has access to.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                  <div class="" style="width:22.6875rem;">

                      <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='dataaccesssearch' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchdataaccess($event)" [isLoading]="isLoading"></app-searchbar>

                  </div>
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                       <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';selectItemList('')">All</button>
                       <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';selectItemList(true)">Active </button>
                       <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
                    </div>
                 </div>
                <button  *ngIf="permissions.a" routerLink="/data-access/setup-data-access" class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add Data Access</button>
            </div>
        </div>
    </div>

    <!-- Loader updations -->
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="row row-16rem" *ngIf="loader">
        <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card3'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>

    <div *ngIf="nodata == false" class="row row-16 " infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    (scrolled)="onScrollDown()">
      <ng-container  *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 "  *ngFor="let item of dataaccessList,let i = index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body p-0 d-flex align-items-start">
                      <div class="d-flex p-16 {{(item.is_active == true) ? 'view-section' : 'w-100' }} " (click)="viewdataaccess(item.id)">
                        <div class="logo-img {{item.color_code}}"> {{ this.appService.getFirstChar(item['name'],2) }}</div>
                        <div class="overflow-hidden">
                            <h6 class="mb-0 tips">{{item['name']}}</h6>
                            <span class="tips-helper">{{item['name']}}</span>
                            <span class="fs-10 text-uppercase fw-500 {{(item['is_active'] == true) ? 'text-success' : 'text-danger'}}">{{(item['is_active']
                              == true) ? 'Active' : 'Deleted'}}</span>
                        </div>
                      </div>
                      <ng-container  *ngIf="permissions">
                      <div  *ngIf="item.is_active==true && (permissions.e || permissions.d)" ngbDropdown class="pe-16 py-16 dropdown-section d-inline-block " >
                        <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1"
                            ngbDropdownToggle>
                            <i class="icon-three-dots-vertical fs-16"></i>
                        </button>
                        <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                            <button *ngIf="permissions.e" ngbDropdownItem (click)="editHeaderContent(item['id'])">
                                <i   class="icon-pencil-square me-1"></i> Edit
                            </button>
                            <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem (click)="deleteDataAccess(item['id'])">
                                <i class="icon-trash  me-1"></i> Delete
                            </button>
                        </div>
                       </div>
                      </ng-container>

                        <!-- <div class="ms-auto d-flex gap-4 fs-14">
                            <button class="btn-reset link-primary" (click)="editHeaderContent(item['id'])">
                                <i class="icon-pencil-square"></i>
                            </button>
                            <button class="btn-reset " (click)="deleteDataAccess(item['id'])">
                                <i class="icon-trash"></i>
                            </button>
                        </div> -->

                    </div>
                    <div class="card-body align-items-center px-16 pt-8 pb-16 justify-content-between">
                        <div class="d-flex align-items-center overlap-avatar overlap-left">
                            <ng-container *ngFor="let rights of item['data_access_employee'],let j = index">
                                <img  class="avatar-sm sq-32 img-fluid rounded-circle"  *ngIf="j < 3 && (rights.employee.profile_image !=  '' && rights.employee.profile_image !=  null)"  src="{{rights.employee.profile_image}}" [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body">
                                <div class="avatar-sm sq-32 rounded-circle {{rights.employee.color_code}}" *ngIf="j < 3 && (rights.employee.profile_image ==  '' || rights.employee.profile_image ==  null)" [ngbPopover]="popContentSingle"  popoverClass="user-list single-user" triggers="hover" container="body"><span>{{this.appService.getFirstChar(rights.employee.first_name+' '+rights.employee.last_name,2)}}</span></div>
                                <ng-template #popContentSingle>
                                  <div class="user-details">
                                      <span class="user-label">{{rights.employee.first_name+' '+(rights.employee.middle_name!=null?rights.employee.middle_name:'')+' '+rights.employee.last_name}}</span>
                                      <span class="user-code">EMP CODE: {{rights.employee.employee_code}} </span>
                                  </div>
                               </ng-template>
                                <div *ngIf="j==3" class="avatar-sm sq-32 rounded-circle" [ngbPopover]="popContentMultilpe"  popoverClass="user-list multi-user" triggers="click" container="body"><span>+{{item['data_access_employee'].length - 3}}</span></div>
                            </ng-container>
                        </div>
                        <ng-template #popContentMultilpe>
                          <ul>
                            <ng-container *ngFor="let re of item['data_access_employee'], let i = index">
                              <ng-container *ngIf="i >= 3">
                                <li *ngIf="re.employee?.profile_image !='' && re.employee?.profile_image !=null">
                                  <img  class="user-avatar" src="{{re.employee?.profile_image}}">
                                  <div class="user-details">
                                      <span class="user-label">{{re.employee?.first_name+' '+(re.employee?.middle_name!=null?re.employee?.middle_name:'')+' '+re.employee?.last_name}}</span>
                                      <span class="user-code">EMP CODE : {{re.employee?.employee_code}}</span>
                                  </div>
                              </li>
                              <li *ngIf="re.employee?.profile_image =='' || re.employee?.profile_image ==null">
                                  <span class="user-avatar {{re.employee?.color_code}}">{{this.appService.getFirstChar(re.employee?.first_name+' '+re.employee?.last_name,2)}}</span>
                                  <div class="user-details">
                                      <span class="user-label">{{re.employee?.first_name+' '+(re.employee?.middle_name!=null?re.employee?.middle_name:'')+' '+re.employee?.last_name}}</span>
                                      <span class="user-code">EMP CODE : {{re.employee?.employee_code}}</span>
                                  </div>
                              </li>
                              </ng-container>
                            </ng-container>
                          </ul>
                        </ng-template>
                        <div class="fs-12 fw-500 text-light-400">
                            <span *ngIf="item['data_access_employee'].length == 1" class="text-nowrap">{{item['data_access_employee'].length}} Employee</span>
                            <span *ngIf="item['data_access_employee'].length > 1" class="text-nowrap">{{item['data_access_employee'].length}} Employees</span>

                        </div>
                    </div>
                </div>
            </div>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card3'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
    <div *ngIf="nodata == true  && !loader" class="row" style="height: calc(100vh - 12rem);">
        <app-nodata-view class="vstack" [noDataImg]="'bg35.png'" [noDataText]="'Data at your fingertips!'"
          [noDataPara]="'It seems there are no data access permissions configured yet. Click \'Add Data Access\' to define who can access and interact with the data.'"
          [hasPermission]="false">
        </app-nodata-view>
    </div>
  </div>
  <div class="side-panel" style="--sidepanel-width:44.6875rem;" [class.side-pane-active]='showSidePanel === true' *ngIf="permissions">
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid px-32 py-24">
              <!-- View loader start -->
              <app-view-loader *ngIf="loader1"></app-view-loader>
              <!-- View loader end -->
              <ng-container *ngIf="!loader1">
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0">DATA ACCESS</p>
                    <a *ngIf="(permissions.e && viewaccesssData.is_active)" class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="editHeaderContent(viewaccesssData.id)">
                        <i class="icon-pencil-square me-1"></i> Edit
                    </a>
                </div>
                <div class="row row-16 ">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 py-16">
                            <div class="row row-24 ">
                                <div class="col-12 "><span class="title">Data Access details</span></div>
                                <div class="col-6">
                                    <div class="detail-title">Data acces name</div>
                                    <div class="detail-desc">{{viewaccesssData.name}}</div>
                                </div>
                                <div class="col-6">
                                  <div class="detail-title">Status</div>
                                      <div class="detail-desc">
                                        <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewaccesssData.is_active">ACTIVE</span>
                                        <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="viewaccesssData.is_active==false">DELETED</span>
                                      </div>
                              </div>
                                <div class="col-12">
                                  <div class="detail-title">Module</div>
                                  <div class="detail-desc">
                                      <div class="d-flex flex-wrap align-items-start mt-8 gap-8" >
                                          <span class="badge3" *ngFor="let DATAmodule of viewaccesssData.module, let i = index">
                                            <div class="avatar-circle sq-24 fs-10 bg1"  alt="" >
                                              {{this.appService.getFirstChar(DATAmodule.module_name,2)}}
                                            </div>
                                             {{DATAmodule.module_name}}
                                          </span>

                                          <!-- <span class="badge2"> <div class="avatar-circle sq-24 fs-10 bg1"  alt="">LR</div> Lindsey Donin</span> -->
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12">
                                <div class="detail-title">Employees</div>
                                <div class="detail-desc">
                                    <div class="d-flex flex-wrap align-items-start mt-8 gap-8" >
                                        <span class="badge3" *ngFor="let DATAemp of viewaccesssData.employee, let i = index">
                                          <div class="avatar-circle sq-24 fs-10 {{DATAemp.color_code}}" alt="" *ngIf="DATAemp.profile_image ==null || DATAemp.profile_image ==''">{{this.appService.getFirstChar(DATAemp.first_name+" "+DATAemp.last_name,1)}}</div>
                                          <img class="avatar-circle sq-24 {{DATAemp.color_code}}"  src="{{DATAemp.profile_image}}" alt="" *ngIf="DATAemp.profile_image !=null && DATAemp.profile_image !=''">{{DATAemp.first_name}} {{DATAemp.middle_name}} {{DATAemp.last_name}} {{" ("+DATAemp.employee_code+")"}}
                                        </span>

                                        <!-- <span class="badge2"> <div class="avatar-circle sq-24 fs-10 bg1" alt="">LR</div> Lindsey Donin</span> -->
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                            <div class="row row-24 ">
                                <div class="col-12 "><span class="title">rights</span></div>
                                <div class="col-12" *ngFor="let emprights of viewaccesssData.employee_rights, let i = index">
                                    <div class="card card-c2 px-16">
                                        <div class="card-body px-0 py-16" >
                                            <div class="d-flex align-items-center">
                                                <img  class="avatar-circle sq-40 {{emprights.color_code}}" src="{{emprights.profile_image}}"   alt="" *ngIf="emprights.profile_image !=null && emprights.profile_image !=''">

                                                <div class="avatar-circle sq-40 {{emprights.color_code}} fs-14 fw-500"  alt="" *ngIf="emprights.profile_image ==  null || emprights.profile_image ==''">{{this.appService.getFirstChar(emprights.first_name+" "+emprights.last_name,1)}}</div>

                                                <p class="mb-0 fw-600 fs-14 ms-8 text-break">{{emprights.first_name}} {{emprights.middle_name}} {{emprights.last_name}} {{" ("+emprights.employee_code+")"}}</p>
                                            </div>
                                        </div>
                                        <div class="card-body px-0 py-16">
                                            <div class="row row-24">
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Company</div>
                                                    <div class="detail-desc">
                                                        <!-- {{emprights.company == '' ? '-': emprights.company}}  -->
                                                        <div *ngIf="emprights.company!=''" class="selected-values mt-1">
                                                          <li class="ng-value" *ngFor="let rights of emprights.company, let i=index;">
                                                            <div class="ng-value-label" title="{{rights.company_name}}">
                                                              <span class="d-inline-flex align-items-center">
                                                                <img class="img-fluid rounded-circle sq-24"  src="{{rights.company_logo}}" *ngIf="rights.company_logo !=  '' && rights.company_logo !=  null">
                                                                <span class="avatar sq-24 avatar-circle bg1"  *ngIf="rights.company_logo ==  null || rights.company_logo ==  ''">{{this.appService.getFirstChar(rights.company_name,1)}}</span>
                                                                  <span> {{rights.company_name}}</span>
                                                               </span>
                                                            </div>
                                                          </li>

                                                      </div>
                                                    </div>

                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="detail-title">Business unit</div>
                                                    <div class="detail-desc">
                                                        <!-- {{emprights.businessunit == '' ? '-': emprights.businessunit}} -->
                                                        <div class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.businessunit" class="badge3">{{item.businessunit_name}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Department</div>
                                                    <div class="detail-desc">
                                                        <!-- {{emprights.department == '' ? '-': emprights.department}} -->
                                                        <div class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.department" class="badge3">{{item.department_name}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Designation</div>
                                                    <div class="detail-desc">
                                                        <!-- {{emprights.designation == '' ? '-': emprights.designation}} -->
                                                        <div class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.designation" class="badge3">{{item.designationt_name}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Branch</div>
                                                    <div class="detail-desc">
                                                        <!-- {{emprights.branch == '' ? '-': emprights.branch}} -->
                                                        <div class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.branch" class="badge3">{{item.branch}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">City</div>
                                                    <div class="detail-desc">
                                                        <!-- {{emprights.city == '' ? '-': emprights.city}} -->
                                                        <div class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.city" class="badge3">{{item.city}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="detail-title">Grade</div>
                                                    <div class="detail-desc">
                                                        <!-- {{emprights.grade == '' ? '-': emprights.grade}} -->
                                                        <div class="d-flex flex-wrap mt-8 gap-8">
                                                            <span  *ngFor="let item of emprights.grade" class="badge3">{{item.grade}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
    </div>
</div>
  <div *ngIf="deleteToggle" class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Data Access?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Data Access')}}

          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteToggle = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase" (click)="deleteContent(dataAccessResId)">Delete</button>
        </div>
      </div>
    </div>
  </div>
