<app-edit-loader *ngIf="loader"></app-edit-loader>
<div class="row row-16" *ngIf="!loader && permissions">
    <div class="col-12">
        <div class="d-flex">
            <h5 class="mb-0">Documents</h5><span class=" ms-8"><i
                    class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                    ngbPopover="Employee's documents are uploaded on this page"
                    popoverClass="popover-default popover-info" container="body" triggers="hover"></i></span>

        </div>
    </div>
    <div *ngIf="(emptyDoc?.length==0 && permittedDoc?.length==0) || (from == 'myteam' &&  permittedDoc?.length == 0);" class="row " style="min-height: calc(100vh - 9rem);">
        <app-nodata-view class="vstack" [noDataImg]="'bg9.png'" [noDataText]="'Document master is not set up.'"
          [noDataPara]="'Currently, document master is not available. Get started with the document upload once the document master is configured.'"
          [hasPermission]="false">
        </app-nodata-view>
    </div>

    <!-- Edit Doc -->
    <ng-container *ngIf="from != 'myteam' || (getFieldPermission('document_type') || getFieldPermission('document_file'))">
    <div class="col-12" *ngFor="let doc of permittedDoc let i = index">
        <div class="card details-card shadow-0 p-24 ">
            <div class="row row-24 ">
                <div *ngIf="getFieldPermission('document_type')" class="col-12 d-flex" (click)="currentId(doc.id,doc.doc_request_detail?.doc_status)"><span
                        class="title">{{doc.document_type.name}}</span>
                    <label *ngIf="((doc.document_type.edit || loginAs=='HR') && permissions?.e)" for="empDoc"
                        class="link-primary d-flex fs-14 align-items-center ms-auto"><i
                            class="bi bi-file-earmark-arrow-up me-1"></i> Update </label>
                </div>
                <label for="empDoc" style="display: none;" *ngIf="!is_employeeRequest?.is_document_requested">
                    <app-file-upload [crop]="false" [round]="false" [id]="'empDoc'" [FileType]="'.png,.pdf,.jpeg,.jpg'"
                        [width]="504" (dataURL)="getURL($event)" (loader)="getloader($event)"
                        (fileformat)="getfileformat($event)" (validsize)="getvalidsize($event)"></app-file-upload>
                </label>

                <ng-container *ngIf="getFieldPermission('document_file')">
                <div class="col-lg-6 is-invalid" *ngIf="doc?.document_file!=null">
                    <div class="card card-c2  bg-secondary">
                        <div class="card-body pb-16 px-24 pt-24">
                            <div class="d-flex align-items-center mb-12 gap-16">
                                <!-- <img src="\assets\images\fileIcon.png" class=" img-fluid" style="width: 1.75rem;"> -->
                                <i class="bi bi-file-earmark-text-fill  text-accent2 lh-1 fs-32"></i>
                                <div>
                                    <h6 class="mb-1">{{this.appservice.getDocName(doc?.document_file)}}</h6>
                                    <div class="fs-10 fw-500 text-uppercase text-light-500"
                                        *ngIf="doc.doc_request_detail!=null">Old document</div>
                                </div>
                                <div class="ms-auto mb-auto">
                                    <i class="bi bi-check-circle fs-20 text-success"></i>
                                </div>
                            </div>
                            <div class="d-flex gap-24 fs-12 fw-500" *ngIf="permissions?.v">
                                <div class="link-primary"
                                    (click)="this.appservice.downloadFile(doc?.document_file,this.appservice.getDocName(doc?.document_file))">
                                    <i class="bi bi-download me-1"></i>Download
                                </div>
                                <div class="link-primary"
                                    (click)="viewDocument(doc?.document_file,this.appservice.getDocName(doc?.document_file))">
                                    <i class="bi bi-eye me-1"></i>View
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card-body px-24 py-12 bg-darkgrey border-0">
                            <div class="d-flex fs-12">
                                <div class="text-light-500 me-8">Modified by</div>
                                <div class="fw-500 ms-auto">{{doc.modified_by.employee.first_name.concat(" ").concat(doc.modified_by.employee.middle_name!=null?doc.modified_by.employee.middle_name:" ").concat(" ").concat(doc.modified_by.employee.last_name)}}</div>
                            </div>

                        </div> -->

                        <!-- NEW START -->
                        <div
                            class="d-flex  align-items-center justify-content-between card-body px-24 py-12 bg-darkgrey border-0 pointer">
                            <div class="text-muted fs-12 me-8 text-nowrap">Modified by</div>
                            <div class="d-flex align-items-center overlap-avatar overlap-right">
                                <ng-container *ngIf="doc.modified_by != null && doc.modified_by != '' && doc.modified_by?.employee !=null&& doc.modified_by?.employee !=''">

                                    <img class="avatar-sm sq-32 avatar-circle"
                                        *ngIf="doc.modified_by.employee?.profile_image !=  '' && doc.modified_by?.employee?.profile_image !=  null"
                                        src="{{doc?.modified_by?.employee?.profile_image}}"
                                        [ngbPopover]="popContentSingle" popoverClass="user-list single-user"
                                        triggers="hover" container="body">

                                    <div class="avatar-sm sq-32 avatar-circle bg{{i%5}}"
                                        *ngIf="(doc.modified_by?.employee?.profile_image ==  ''|| doc.modified_by?.employee?.profile_image ==  null)"
                                        [ngbPopover]="popContentSingle" popoverClass="user-list single-user"
                                        triggers="hover" container="body">
                                        <span>{{this.appservice.getFirstChar(doc.modified_by?.employee?.first_name?.concat("
                                            ").concat(doc.modified_by?.employee?.last_name),2)}}</span></div>
                                    <ng-template #popContentSingle>
                                        <div class="user-details">
                                            <span class="user-label">{{doc.modified_by?.employee?.first_name.concat("
                                                ").concat(doc.modified_by?.employee?.middle_name!=null?doc.modified_by?.employee?.middle_name:"
                                                ").concat(" ").concat(doc.modified_by?.employee?.last_name)}}</span>
                                            <span class="user-code">EMP CODE:
                                                {{doc.modified_by?.employee?.employee_code}} </span>
                                        </div>
                                    </ng-template>

                                </ng-container>

                            </div>
                        </div>
                        <!-- NEW END -->
                    </div>

                </div>
</ng-container>
                <span class="invalid-feedback" *ngIf="!Savecontent[doc.id].valid">{{errormsg}}</span>


                <!-- Save confirmation -->
                <div class="col-lg-6" *ngIf="Savecontent[doc.id]?.loader || Savecontent[doc.id]?.url!='' ">
                    <div class="card card-c2   bg-secondary">
                        <div class="p-24 bg-secondary radius-8 d-flex justify-content-start">
                            <!-- <img src="\assets\images\fileIcon.png" class=" img-fluid" style="width: 4rem;"> -->
                            <i class="bi bi-file-earmark-text-fill  text-accent2 lh-1 fs-44 me-24"></i>

                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                                <h6 class="mb-1">{{this.appservice.getDocName(Savecontent[doc.id]?.url)}}</h6>
                                <div class="link-primary" *ngIf="Savecontent[doc.id]?.url!='' && permissions?.v"
                                    (click)="this.appservice.downloadFile(Savecontent[doc.id]?.url,this.appservice.getDocName(Savecontent[doc.id]?.url))">
                                    <i class="bi bi-download me-8"></i>Download</div>
                                <div class="link-primary" *ngIf="Savecontent[doc.id]?.url!='' && permissions?.v"
                                    (click)="viewDocument(Savecontent[doc.id]?.url,this.appservice.getDocName(Savecontent[doc.id]?.url))"><i
                                        class="bi bi-eye me-8"></i>View </div>
                                <div class="flex-center text-center" *ngIf="Savecontent[doc.id].loader">
                                    <div class="fs-12 text-muted">Loading </div>



                                </div>
                            </div>
                            <button class="btn-reset text-light-600 ms-auto d-flex " (click)="closeDoc(doc.id)"><i
                                    class="icon-close-lg fs-16"></i></button>

                        </div>

                    </div>
                    <!-- <div class="btn btn-outline-primary btn-sm w-50 mt-24" *ngIf="Savecontent[doc.id]?.url!='' " (click)="saveDoc()">SAVE</div> -->


                </div>

                <!-- end -->

                <div class="col-lg-6 " *ngIf="(doc.doc_request_detail | json) != '{}'">
                    <div class="card card-c2  bg-secondary">
                        <div class="card-body pb-16 px-24 pt-24">
                            <div class="d-flex align-items-start mb-8 gap-16">
                                <img src="\assets\images\pdf.png" class=" img-fluid" style="width: 1.75rem;">
                                <div>
                                    <h6 class="mb-1">{{this.appservice.getDocName(doc.doc_request_detail?.new_file)}}
                                    </h6>
                                    <div class="fs-10 fw-500 text-uppercase text-light-500">New document</div>
                                </div>
                                <div class="ms-auto ">
                                    <i
                                        class="bi bi-exclamation-circle-fill fs-20 {{doc.doc_request_detail?.doc_status=='Pending'?'text-warning':'text-danger'}}"></i>

                                </div>
                            </div>
                            <div class="d-flex gap-24 fs-12 fw-500">
                                <div class="link-primary"
                                    (click)="this.appservice.downloadFile(doc.doc_request_detail?.new_file,this.appservice.getDocName(doc.doc_request_detail?.new_file))">
                                    <i class="bi bi-download me-1"></i>Download
                                </div>
                                <div class="link-primary"
                                    (click)="viewDocument(doc.doc_request_detail?.new_file,this.appservice.getDocName(doc.doc_request_detail?.new_file))">
                                    <i class="bi bi-eye me-1"></i>View
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card-body px-24 py-12 bg-darkgrey border-0" *ngIf="doc.doc_request_detail.approver.length > 0">
                           <div class="d-flex fs-12">
                              <div class="text-light-500 me-8">{{doc.doc_request_detail.doc_status=='Pending'?'Pending with':'Rejected by'}}</div>
                              <div class="fw-500 ms-auto">{{doc.doc_request_detail.approver}}</div>
                           </div>

                       </div> -->

                        <!-- NEW START-->
                        <div class="d-flex  align-items-center justify-content-between card-body px-24 py-12 bg-darkgrey border-0 pointer"
                            *ngIf="doc.doc_request_detail?.approver?.length > 0">
                            <div class="text-muted fs-12 me-8 text-nowrap">{{ doc.doc_request_detail?.doc_status ==
                                'Accepted' ? "Accepted by" : doc.doc_request_detail?.doc_status == 'Rejected'?
                                "Rejected by" : doc.doc_request_detail?.doc_status == 'Pending'? 'Pending with' : ""}}
                            </div>
                            <div class="d-flex align-items-center overlap-avatar overlap-right">
                                <ng-container *ngFor="let aacpt of doc.doc_request_detail?.approver,let i = index">

                                    <img class="avatar-sm sq-32 avatar-circle"
                                        *ngIf="i < 3 && aacpt.profile_image !=  '' && aacpt.profile_image !=  null"
                                        src="{{aacpt.profile_image}}" [ngbPopover]="popContentSingle"
                                        popoverClass="user-list single-user" triggers="hover" container="body">

                                    <div class="avatar-sm sq-32 avatar-circle bg{{i%5}}"
                                        *ngIf="i < 3 && (aacpt.profile_image ==  ''|| aacpt.profile_image ==  null)"
                                        [ngbPopover]="popContentSingle" popoverClass="user-list single-user"
                                        triggers="hover" container="body">
                                        <span>{{this.appservice.getFirstChar(aacpt.name,2)}}</span></div>
                                    <ng-template #popContentSingle>
                                        <div class="user-details">
                                            <span class="user-label">{{aacpt?.name}}</span>
                                            <span class="user-code">EMP CODE: {{aacpt?.employee_code}} </span>
                                        </div>
                                    </ng-template>
                                    <div *ngIf="i==3" class="avatar-sm sq-32 avatar-circle"
                                        [ngbPopover]="popContentMultilpe" popoverClass="user-list multi-user"
                                        triggers="click" container="body">
                                        <span>+{{this.appservice.subEmp(doc.doc_request_detail?.approver?.length)}}</span>
                                    </div>

                                </ng-container>
                                <ng-template #popContentMultilpe>
                                    <ul>
                                        <ng-container *ngFor="let re of doc.doc_request_detail?.approver,let i = index">
                                            <ng-container *ngIf="i >= 3">
                                                <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                                                    <img class="user-avatar" src="{{re.profile_image}}">
                                                    <div class="user-details">
                                                        <span class="user-label">{{re.name}}</span>
                                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                                    </div>
                                                </li>
                                                <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                                                    <span
                                                        class="user-avatar bg{{i%5}}">{{this.appservice.getFirstChar(re.name,1)}}</span>
                                                    <div class="user-details">
                                                        <span class="user-label">{{re.name}}</span>
                                                        <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </ng-template>
                            </div>
                        </div>
                        <!-- NEW END -->
                        <ng-container *ngIf="doc.doc_request_detail?.approver?.length==0">
                            <div class="bg-darkgrey card-body d-flex flex-center py-12 text-center border-0">
                                <div class="fs-12 text-muted">Loading </div>
                                <div class="ms-4 dot-pulse"></div>

                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </ng-container>

    <!-- Add Doc -->
    <ng-container *ngIf="this.from !='myteam'">
    <div class="col-12" *ngFor="let doc of emptyDoc let i = index">
        <div class="card details-card shadow-0 p-24 ">
            <div class="row row-24 ">
                <div  class="col-12 d-flex">
                    <span class="title">{{doc.document_type.name}}</span>

                </div>
                <ng-container *ngIf="getFieldPermission('document_file')">
                <div class="col-lg-6 " (click)="addEditPermission && currentId(doc.id,doc.doc_request_detail?.doc_status)"
                    *ngIf="!Savecontent[doc.id].pending && permissions?.a">
                    <label for="new_empDoc" class="upload-border p-16 text-center w-100">
                        <div class="fs-14 text-light-500"><span class="text-accent2">Upload document</span></div>
                        <div class="fs-12 text-light-400">Supports Jpeg, Jpg, Png and Pdf</div>

                    </label>
                </div>
                <label *ngIf="!is_employeeRequest?.is_document_requested" class="is-invalid" for="new_empDoc"
                    style="display: none;">
                    <app-file-upload [crop]="false" [round]="false" [id]="'new_empDoc'"
                        [FileType]="'.png,.pdf,.jpeg,.jpg'" [width]="504" (dataURL)="getURL($event)"
                        (loader)="getloader($event)" (fileformat)="getfileformat($event)"
                        (validsize)="getvalidsize($event)"></app-file-upload>
                </label>
                <span class="invalid-feedback" *ngIf="!Savecontent[doc.id].valid">{{errormsg}}</span>
              </ng-container>

                <!-- test -->

                <div class="col-lg-6" *ngIf="Savecontent[doc.id].loader || Savecontent[doc.id]?.url!='' ">
                    <div class="card card-c2  bg-secondary">
                        <div class="p-24 bg-secondary radius-8 d-flex justify-content-start">
                            <!-- <img src="\assets\images\fileIcon.png" class="me-24 img-fluid" style="width: 4rem;"> -->
                            <i class="bi bi-file-earmark-text-fill  text-accent2 lh-1 fs-44 me-24"></i>

                            <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                                <h6 class="mb-1">{{this.appservice.getDocName(Savecontent[doc.id]?.url)}}</h6>

                                <div class="link-primary" *ngIf="Savecontent[doc.id]?.url!='' && permissions?.v "
                                    (click)="this.appservice.downloadFile(Savecontent[doc.id]?.url,this.appservice.getDocName(Savecontent[doc.id]?.url))">
                                    <i class="bi bi-download me-8"></i>Download</div>
                                <div class="link-primary" *ngIf="Savecontent[doc.id]?.url!='' && permissions?.v "
                                    (click)="viewDocument(Savecontent[doc.id]?.url,this.appservice.getDocName(Savecontent[doc.id]?.url))"><i
                                        class="bi bi-eye me-8"></i>View </div>
                                <div class="  flex-center text-center" *ngIf="Savecontent[doc.id].loader">
                                    <div class="fs-12 text-muted">Loading </div>
                                    <div class="ms-4 dot-pulse"></div>

                                </div>
                            </div>
                            <button class="btn-reset text-light-600 ms-auto d-flex " (click)="closeDoc(doc.id)"><i
                                    class="icon-close-lg fs-16"></i></button>

                        </div>

                    </div>
                    <!-- <div class="btn btn-outline-primary btn-sm w-50 mt-24"  *ngIf="Savecontent[doc.id]?.url!='' " (click)="saveDoc()">SAVE 1</div> -->


                </div>

                <!-- end -->
            </div>
        </div>
    </div>
    <div class="col-12 position-sticky bottom-0" *ngIf="(emptyDoc?.length!=0 || permittedDoc?.length!=0);">
        <div class="card card-c2 flex-row px-24 py-16">
            <button class="btn btn-primary btn-sm ms-auto px-4" [disabled]="validCount()==0"
                (click)="saveDoc()">SAVE</button>
        </div>

    </div>
    </ng-container>
</div>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
    <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
        [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>

<!-- InfoPopup start -->
<ng-container *ngIf="showInfo == true">
    <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
<!-- InfoPopup end -->
