<div class="container-fluid p-24">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Leave Policy</h3>
      <p class="text-helper mb-md-0">Create flexible leave policies and map them to the appropriate leave types.
      </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='leavePlaceholder'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchleavepolicy($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>
        <button routerLink="/leave-rule/leave-policy-setup/new" class="btn flex-shrink-0 btn-primary btn-icon btn-add"
          *ngIf="permissions?.a"><i class="icon-plus"></i>Add LEAVE POLICY</button>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
  <div *ngIf="nodata && !loader;else all_data" class="row " style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg39.png'" [noDataText]="'Set up leave policies for each leave type'"
      [noDataPara]="'It seems no leave policies are defined for the leave types. Click \'Add Leave Policy\' to start defining them.'"
      [hasPermission]="false">
    </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16 " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="  col-md-3 col-sm-6  " *ngFor="let data of leavePolicyData">
          <div class="card card-hover card-custom1 ">
            <div class="card-body  p-0 d-flex align-items-start">
              <div class="d-flex  view-section" (click)="viewSingledata(data.id)">
                <div class="logo-img {{data.color_code}}">{{this.appService.getFirstChar(data.name,2)}}</div>

                <div class="overflow-hidden">
                  <h6 class="mb-0 fw-500 text-trim tips">{{data.name}}</h6>
                  <p class="mb-0  tips-helper">{{data.name}}</p>
                  <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="data.is_active">ACTIVE</span>
                  <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="data.is_active==false">DELETED</span>
                </div>

              </div>
              <div ngbDropdown class="dropdown-section"
                *ngIf="data.is_active==true && (permissions?.e || permissions?.d)">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical fs-16"></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                  <button *ngIf="permissions?.e" ngbDropdownItem (click)="editLeavepolicy(data.id)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button *ngIf="permissions?.d" class="text-danger" ngbDropdownItem
                    (click)="deleteAlert = true;deleteId=data.id">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body p-16" (click)="viewSingledata(data.id)">
              <div class="d-flex w-100">
                <div class="text-light-400 fs-10 text-uppercase me-8">leave cycle</div>
                <div class=" fs-12 ms-auto">{{data.leave_cycle==false ?'Calendar':'Financial'}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
      <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
  </div> -->
  <app-infinity-loader *ngIf="infinityloader"></app-infinity-loader>


</div>



<!-- LEAVE POLICY VIEW COMPONENT START -->
<app-view-leave-policy [from]="from" [leavePolicy]="viewDetail2" [viewRightsArray]="viewRightsArray"
  [viewListData]="viewListData" [viewApplicable]="viewApplicable" [editPermission]="permissions?.e"
  (editLeavepolicy)="editLeavepolicy($event)" (viewClose)="leavePolicyViewClose($event)"
  [viewloader]="viewloader" [leaveMapping]="leaveMapping"></app-view-leave-policy>
<!-- LEAVE POLICY VIEW COMPONENT END -->

<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="icon-trash text-danger"></i>
        <h4 class="modal-title">Delete Leave Policy?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Leave Policy')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase fw-500"
          (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase fw-500" (click)="deleteLeavePolicy(deleteId)"
          [disabled]="deleteClicked">Delete</button>
      </div>
    </div>
  </div>
</div>
