<div class="container-fluid p-32 form-container">
  <div class="row row-24 ">
    <div class="col-12 d-flex flex-wrap align-items-start">
      <div>
        <div class="mb-0 fw-500">Override Pay Component</div>
        <div class=" fs-12 fw-500 text-light-400">{{month}} {{year}} </div>
      </div>
      <div class="d-flex justify-content-end align-items-center ms-auto flex-wrap gap-16">
        <div style="width:15rem;">
          <div class="form-icon2">
            <i class="icon-search icon-left fs-14"></i>
            <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                      <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"  autocomplete="off"/>
          </div>
        </div>
        <ng-select style="width:15rem;"  [clearable]  ="false" class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('Pay group')}}" [items]="paygroupDD" bindLabel="name" [(ngModel)]="selectedPG" (change)="getDataList()"></ng-select>
        <button class="btn-square btn btn-secondary"  *ngIf="status=='Completed'" (click)="filter  = true">
          <i class="icon-filter-left fs-14"></i>
        </button>
        <!-- <button *ngIf="status=='Pending' && permissions?.a " class="btn flex-shrink-0 btn-outline-primary radius-2 fs-10 fw-700 px-4 ">IMPORT</button> -->
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
            [checked]='status=="Pending"' (click)='status="Pending";changeStatus()'>
          <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio1">PENDING</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
            [checked]='status=="Completed"' (click)='status="Completed";changeStatus()'>
          <label class="btn btn-outline-accent2 text-uppercase fs-10 px-4 fw-700" for="btnradio2">COMPLETED</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div *ngIf="selectedPG?.length <= 0" class="row " style="height: calc(100vh - 15rem);">
        <app-nodata-view class="vstack" [noDataImg]="'bg33.png'" [noDataText]="'Oops, no results found!'"
          [noDataPara]="'It seems no results match your search criteria. Please adjust or apply different filters.'"
          [hasPermission]="false">
        </app-nodata-view>
      </div>
      <div *ngIf="selectedPG?.paycomponent_list?.length > 0" role="alert" class="alert alert-info mb-10 show">
        <p class="mb-0 ">Only fixed component type can be edited here, for editing any variable compoents please go to variable payment step.
        </p>
      </div>
      <div *ngIf="selectedPG?.paycomponent_list?.length > 0" class="card card-c2 p-0">

        <app-table-loader *ngIf="loader"></app-table-loader>
        <div  *ngIf="!loader" class="table-responsive radius-4">
          <table class="table td-12 th-12 sticky-header td-white-space td-fs-14
                tr-fs-14 table-striped table-sm form-table">
            <thead>
              <tr>
                <th scope="col" class="form-cell">
                  <input class="form-check-input m-0" type="checkbox" value=""   (click)="checkAll($event)" [checked]="checkAllSelected()">
              </th>
                <th class="fw-600" scope="col">EMP CODE</th>
                <th class="fw-600" scope="col">EMPLOYEE NAME</th>
                <th class="fw-600" scope="col">GROSS PAY</th>
                <ng-container *ngFor="let pc of selectedPG?.paycomponent_list">
                  <th  class="fw-600" scope="col">Regular {{pc?.name}}</th>
                  <th  class="fw-600" scope="col">Override {{pc?.name}}</th>
                </ng-container>
                <th class="fw-600" scope="col">COMMENT</th>
                <th class="fw-600" scope="col"  *ngIf="status == 'Pending'"></th>
                <th class="fw-600" scope="col"  *ngIf="status == 'Completed'">Salary Status</th>
              </tr>
            </thead>
            <tbody class="cell-16 height-full">
              <ng-container *ngIf="data.length==0 || (data|filter:searchString)?.length <= 0">
                <tr>
                  <td  [attr.colspan]="colspan()">

                    <div class="card card-c2 flex-center p-16 bg-dark-500">
                      <span class="fw-500 fs-14">No data found</span>
                    </div>
                  </td>
                </tr>
            </ng-container>
              <tr *ngFor=" let item of $any(data|filter:searchString| slice: (page-1) * pageSize : page * pageSize),let ind = index">
                <td class="form-cell">
                        <input class="form-check-input m-0" type="checkbox"  [(ngModel)]="item.checked">
                </td>

                <td>
                  <!-- <div class="{{item?.error?'cell-error is-invalid':''}}">{{item?.employee_code}}</div>
                  <div class="invalid-feedback"  *ngIf="item?.error">{{item?.error_description}}</div> -->
                  <div class="d-flex align-items-center gap-8 {{ item?.error? 'cell-error':''}}">
                    <p class="fs-14 mb-0 text-trim w-15ch">{{item?.employee_code}}</p>
                    <app-table-input-popover [errorCheck]="item?.error" [errorData]="item?.error_description "></app-table-input-popover>
                  </div>
              </td>
              <ng-container *ngIf="status == 'Pending'">
                <td class="form-cell">
                  <ng-select appendTo="body" placeholder="{{this.messageService.selectplaceholddisplay('Employee')}}" [(ngModel)]="item.employee_id" [clearable]  ="false" (change)="setEmpCode($event,data.indexOf(item))">
                        <ng-container *ngFor="let emp of employeeList;let i=index">
                            <ng-container *ngIf="!selctedData.includes(emp.id) || item.employee_id==emp.id ">
                            <ng-option  [value]="emp.id" >
                                <span class="ng-value-label">
                                <div class="d-inline-flex align-items-center">
                                    <div *ngIf="emp.profile_image ==  '' || emp.profile_image ==  null" class="avatar-sm sq-24 rounded-circle {{emp?.color_code}} me-1" ><span>{{this.appService.getFirstChar(emp.fullname,2)}}</span></div>
                                    <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="emp.profile_image !=  '' && emp.profile_image !=  null" [src]="emp.profile_image" >
                                        {{emp.first_name+" "+(emp.middle_name!=null?emp.middle_name+" ":" ")+emp.last_name +" ("+emp.employee_code+")"}}
                                    </div>
                                </span>
                                </ng-option>
                            </ng-container>
                        </ng-container>
                  </ng-select>
                </td>
                <td>{{item?.gross| currency: item?.currency}}</td>
                <ng-container *ngFor="let pc of selectedPG?.paycomponent_list">
                  <td *ngIf="item?.component_list">{{item?.component_list[pc.id]?.original_amount| currency: item?.currency}}</td>
                  <td *ngIf="!item?.component_list"></td>
                  <td  class="form-cell">
                    <!-- <input *ngIf="item?.component_list"  placeholder="{{this.messageService.placeholderdisp(pc.name)}}" type="text" class="form-control {{(item.inputerror?.component_list && item.inputerror?.component_list[pc.id]?.is_valid==false)?'is-invalid':''}}"  [value]="item?.component_list[pc.id]?.amount"  (keyup)="validate(data.indexOf(item),pc.id,$event)"  (focusout)="focusOutfield(data.indexOf(item),pc.id,$event)">
                      <div *ngIf="(item.inputerror?.component_list && item.inputerror?.component_list[pc.id]?.is_valid==false)" class="invalid-feedback">
                          <div>{{item.inputerror?.component_list[pc.id]?.error}}</div>
                      </div> -->
                      <div class="form-icon {{(item.inputerror?.component_list && item.inputerror?.component_list[pc.id]?.is_valid==false)?'icon-right':''}}">
                    <input *ngIf="item?.component_list" placeholder="{{this.messageService.placeholderdisp(pc.name)}}" type="text"
                      class="form-control {{(item.inputerror?.component_list && item.inputerror?.component_list[pc.id]?.is_valid==false)?'is-invalid':''}}"
                      [value]="item?.component_list[pc.id]?.amount" (keyup)="validate(data.indexOf(item),pc.id,$event)"
                      (focusout)="focusOutfield(data.indexOf(item),pc.id,$event)">
                        <app-table-input-popover [errorCheck]="(item.inputerror?.component_list && item.inputerror?.component_list[pc.id]?.is_valid==false)" [errorData]="item.inputerror?.component_list[pc.id]?.error"></app-table-input-popover>
                      </div>
                  </td>
                </ng-container>

                <td>
                  <!-- <input placeholder="{{this.messageService.placeholderdisp('Comment')}}"  type="text" class="form-control {{(item.inputerror?.comment?.is_valid==false)?'is-invalid':''}}" [(ngModel)]="item.comment"  (keyup)="validateComment(data.indexOf(item))">
                  <div *ngIf="(item.inputerror?.comment?.is_valid==false)" class="invalid-feedback">
                      <div>{{item.inputerror?.comment?.error}}</div>
                  </div> -->
                  <div class="form-icon {{(item.inputerror?.comment?.is_valid==false)?'icon-right':''}}">
                  <input placeholder="{{this.messageService.placeholderdisp('Comment')}}" type="text"
                    class="form-control {{(item.inputerror?.comment?.is_valid==false)?'is-invalid':''}}" [(ngModel)]="item.comment"
                    (keyup)="validateComment(data.indexOf(item))">
                    <app-table-input-popover [errorCheck]="(item.inputerror?.comment?.is_valid==false)" [errorData]="item.inputerror?.comment?.error"></app-table-input-popover>
                  </div>
                </td>
                <td> <i class="icon-trash" (click)="deleteaRow(data.indexOf(item))"></i> </td>
              </ng-container> <ng-container *ngIf="status == 'Completed'">
                  <td class="text-trim w-15ch" title="{{item.employee_name}}">{{item?.employee_name}}</td>
                  <td>{{item?.gross| currency: item?.currency}}</td>
                  <ng-container *ngFor="let pc of selectedPG?.paycomponent_list">
                    <td>{{item?.component_list[pc.id]?.original_amount| currency: item?.currency}}</td>
                    <td class="form-cell" >{{item?.component_list[pc.id]?.amount| currency: item?.currency}}</td>
                  </ng-container>
                  <td class="text-trim w-15ch" title="{{item.comment}}">{{item?.comment}}</td>
                  <td>
                    <span *ngIf="item.salary_status=='Publish'"  class="badge  badge-success py-1">Published</span>
                    <span *ngIf="item.salary_status=='Processed'"  class="badge badge-warning py-1">Processed</span>
                    <span *ngIf="item.salary_status=='Hold'"  class="badge badge-primary py-1">Held</span>
                    <span *ngIf="item.salary_status=='Freeze'"  class="badge badge-holiday py-1">Freezed</span>
                    <span *ngIf="item.salary_status=='Unprocessed'"  class="badge badge-danger py-1">Unprocessed</span>
                </td>
              </ng-container>

              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="selectedPG?.paycomponent_list?.length > 0 && status == 'Pending'">
      <button class="btn btn-outline-primary radius-2" (click)="addEmployee()">Add Employee</button>
    </div>
    <div class="col-12" *ngIf=" this.appService.filteredcount(data,searchString)>pageSize">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="this.appService.filteredcount(data,searchString)" [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
    </div>
  </div>

</div>
<footer class="submit-footer   gap-16">
  <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="bulkDelete()"  *ngIf="(countSelected()>=1 && status=='Pending')">Delete</button>
  <p class="fs-14 ms-auto d-flex gap-8 mb-0">
    <span class="">{{countSelected()}} of {{data.length}} selected </span>
    <span *ngIf="data.length != countSelected()" class="link-primary fw-500"  (click)="selectAll();">Select all {{data?.length}} employees</span>
    <span *ngIf="data.length == countSelected()" class="link-primary fw-500"  (click)="unselectAll();">Unselect all {{data?.length}} employees</span>
</p>
  <button *ngIf="status == 'Pending'" class="btn btn-primary btn-sm text-uppercase" [disabled]=" countSelectedWithoutError()==0 "  (click)="confirmPop()">SAVE</button>
  <button *ngIf="status == 'Completed'" class="btn btn-primary btn-sm text-uppercase" [disabled]=" countSelected()==0"   (click)="showinfo();">Remove</button>
</footer>


<div class="modal  modal-alert {{confirmMsg == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header align-items-start gap-16">
              <h4 class="modal-title">{{countSelected()}} Employees selected</h4>
              <button type="button" class="btn-close ms-auto my-0" (click)="confirmMsg = false"></button>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{confirmMsgText}}</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="confirmMsg = false;">Cancel</button>
              <button *ngIf="status == 'Completed'" type="button" class="btn btn-primary btn-sm text-uppercase" (click)="moveToPending();confirmMsg = false;">Remove</button>
              <button *ngIf="status == 'Pending'" type="button" class="btn btn-primary btn-sm text-uppercase" (click)="uploadSaveData();confirmMsg = false;">SAVE</button>
          </div>
      </div>
  </div>
</div>

<app-info-popup *ngIf="alertToggle == true" (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="'The selected employee(s) payroll has already been Freezed/Held/Published. Kindly undo the respective action to map/unmap overrided Paycomponent data'" ></app-info-popup>

<app-upload-progress-bar  *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted" [isProgressStart]="isProgressStart" [heading]="'Uploading'" [body]="body" ></app-upload-progress-bar>

<app-error-download [fileName]="'PCError'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg" [failedData]="failedData" [modalHeading]="'Paycomponent Data'" (closePanel)="downloadMsg =false" [successMsg]="successMsg" [errorMsg]="errorMsg">  </app-error-download>

<app-salary-dashboard-filter *ngIf="filter || filterOpened" [filter]="filter" [company]="company" (applymethod)="submitForm($event)" (resetmethod)="resetFilter()" (closemethod)="closeFilter()"></app-salary-dashboard-filter>
