<div class="container-fluid p-24" *ngIf="permissions">
    <div class="row mb-16">
        <div class="col-md-4 col-lg-6">
            <h3>Location Group</h3>
            <p class="text-helper mb-md-0">Create unique location groups, mapping the states and cities.</p>
        </div>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end  gap-16">
                <div class="form-icon " style="width:22.6875rem;">
                    <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='locPlaceholder' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchgroup($event)" [isLoading]="isLoading"></app-searchbar>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                        <i class="icon-filter-left fs-13rem"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-end" >
                      <button ngbDropdownItem [class]="{'active':statusBtn=='All' }" (click)="statusBtn='All';statusfilter('')">All</button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }" (click)="statusBtn='Active';statusfilter(true)">Active </button>
                      <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }" (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
                    </div>
                </div>
                <button  class="btn flex-shrink-0 btn-primary btn-icon btn-add" routerLink="/location-group/location-group-setup" *ngIf="permissions?.a"><i
                    class="icon-plus"></i>ADD Location Group</button>
            </div>
        </div>
    </div>
    <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg17.png'" [noDataText]="'Set up location groups'"
        [noDataPara]="'It seems that no location groups have been set up yet. Click \'Add Location Group\' to organize and manage company locations.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>
    <div class="row row-16" *ngIf="loader">
     <app-common-loader  class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
    <ng-template #all_data>
      <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
        <ng-container *ngIf="loader==false">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let loc_data of locationgroupData;let i=index">
                <div class="card card-custom1 card-hover">
                    <div class="card-body p-0 d-flex align-items-start">
                        <div class="d-flex view-section" (click)="viewdata(loc_data.id)">
                            <div class="logo-img {{loc_data.color_code}}">{{this.appService.getFirstChar(loc_data.name,2)}} </div>
                            <div class="overflow-hidden">
                                <h6 class="mb-1 tips">{{loc_data.name}}</h6>
                                <span class="tips-helper">{{loc_data.name}}</span>
                                <div class="fs-10 text-uppercase fw-500  text-success " *ngIf="loc_data.is_active">Active
                                </div>
                                <div class="fs-10 text-uppercase fw-500  text-danger " *ngIf="loc_data.is_active==false">Deleted
                                </div>
                            </div>
                        </div>
                        <div ngbDropdown class="d-inline-block dropdown-section"  *ngIf="loc_data.is_active && permissions">
                            <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                                <i class="icon-three-dots-vertical "></i>
                            </button>
                            <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                                <button ngbDropdownItem (click)="editlocationgroup(loc_data.id)" *ngIf="permissions?.e">
                                    <i class="icon-pencil-square me-1"></i> Edit
                                </button>
                                <button class="text-danger" ngbDropdownItem (click)="deleteId=loc_data.id;deleteAlert=true;" *ngIf="permissions?.d">
                                    <i class="icon-trash  me-1"></i> Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="infinityloader">
            <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"  *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
        </ng-container>
      </div>
    </ng-template>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
    [class.side-pane-active]='viewDetail === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Details</h5>
            <a class="toggle-panel " (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <div class="container-fluid p-32">
            <app-view-loader *ngIf="viewLoader"></app-view-loader>
            <ng-container *ngIf="!viewLoader" >
                <div class="d-flex mb-16">
                    <p class="text-uppercase fw-500 mb-0 text-trim me-16">{{viewdatas.name}}</p>
                    <a class="link-primary fs-14 ms-auto text-nowrap" (click)="editlocationgroup(viewdatas.id)" *ngIf="permissions?.e && viewdatas.is_active"><i
                            class="icon-pencil-square me-1"></i> Edit</a>
                </div>
                <div class="row row-16 mb-24">
                    <div class="col-12">
                        <div class="card details-card shadow-0 px-24 pb-24 pt-16">
                            <div class="row row-16 ">
                                <div class="col-12 ">
                                    <p class="title mb-8">LOCATION GROUP </p>
                                </div>
                                <div class="col-6">
                                    <div class="detail-title">Location Group Name</div>
                                    <div class="detail-desc">{{viewdatas.name}} </div>
                                </div>
                                <div class="col-6">
                                    <div class="detail-title">Status</div>
                                    <div class="detail-desc">
                                      <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewdatas.is_active">ACTIVE</span>
                                      <span class="fs-12 fw-500 text-danger text-uppercase" *ngIf="viewdatas.is_active==false">DELETED</span>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="detail-title">States</div>
                                    <div class="detail-desc">
                                        <div class="d-flex gap-8 flex-wrap mt-8 ">
                                            <ng-container *ngFor="let states of viewdatas.states;let i=index">
                                                <span class="badge3 py-2">{{states.name}}</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="detail-title">Cities</div>
                                    <div class="detail-desc">
                                        <div class="d-flex gap-8 flex-wrap mt-8 ">
                                            <ng-container *ngFor="let cities of viewdatas.cities;let i=index">
                                                <span class="badge3 py-2">{{cities.name}}</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- Delete  component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Location Group?</h4>

        </div>
        <div class="modal-body">
          <p class="mb-0">{{this.messageService.Deletemsgdisplay('Location Group')}}

          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="deleteAlert = false">Cancel</button>
          <button type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deletegroup(deleteId)">Delete</button>
        </div>
      </div>
    </div>
  </div>
