<!-- <ng-container *ngIf="!loader"> -->
  <ng-container *ngIf="isEmployee; else notautherized">
    <nav class="nav nav-custom sticky">
      <a class="nav-link" [ngClass]="{ active: activeToggle == '' }" (click)="selectItemList('')">
        All
      </a>
      <a class="nav-link" [ngClass]="{ active: activeToggle == 'Pending' }" (click)="selectItemList('Pending')">
        Pending
      </a>
      <a class="nav-link" [ngClass]="{ active: activeToggle == 'Accepted' }" (click)="selectItemList('Accepted')">
        Accepted
      </a>
      <a class="nav-link" [ngClass]="{ active: activeToggle == 'Rejected' }" (click)="selectItemList('Rejected')">
        Rejected
      </a>
      <a class="nav-link" [ngClass]="{ active: activeToggle == 'Cancelled' }" (click)="selectItemList('Cancelled')">
        Cancelled
      </a>
    </nav>
    <div class="container-fluid p-24">
      <div class="row mb-24">
        <div class="col-md-4">
          <h3>Your Requests</h3>
          <p class="text-helper mb-md-0">
            Create and track the status of your workflow requests.
          </p>
        </div>
        <div class="col-md-8">
          <div class="d-flex justify-content-end gap-16">
            <div class="" style="width: 22.6875rem">
              <div class="icon-left">
                <app-searchbar [searchlist]="searchlistdata" [searchplaceholder]="requestsearch" [isShowList]="false"
                  (searchEmitter)="searchresults($event)"
                  (keywordsearchEmitter)="searchrequest($event)"></app-searchbar>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block">
              <button class="btn-square btn btn-secondary" placement="bottom-left" id="dropdownBasic1"
                (click)="filterpanel = true">
                <i class="icon-filter-left fs-13"></i>
              </button>
            </div>
            <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="newRequestSidePanelFunction()">
              <i class="icon-plus"></i>New request
            </button>
          </div>
        </div>
      </div>
      <!--No data found-->
      <div class="row" style="height: calc(100vh - 14rem);" *ngIf="nodata && !tabLoader; else all_data">
        <app-nodata-view *ngIf="activeToggle == ''" class="vstack" [noDataImg]="'bg56.png'"
          [noDataText]="'Let\'s begin! raise new requests and keep track of them effortlessly.'"
          [noDataPara]="'Get started by raising a request. Click \'New Request\' to begin the process and manage all your requests seamlessly as they are published.'"
          [hasPermission]="false">
        </app-nodata-view>
        <app-nodata-view *ngIf="activeToggle == 'Pending'" class="vstack" [noDataImg]="'bg56.png'" [noDataText]="'Spotless! no pending requests'"
          [noDataPara]="'Enjoy a moment of peace. There are no pending request in your list'" [hasPermission]="false">
        </app-nodata-view>
        <app-nodata-view *ngIf="activeToggle == 'Accepted'" class="vstack" [noDataImg]="'bg56.png'" [noDataText]="'Stay tuned for updates!'"
          [noDataPara]="'Once requests are accepted by the approvers, they will appear here.'" [hasPermission]="false">
        </app-nodata-view>
        <app-nodata-view *ngIf="activeToggle == 'Rejected'" class="vstack" [noDataImg]="'bg56.png'" [noDataText]="'It seems there are no rejected requests yet!'"
          [noDataPara]="'Rejected requests will appear here once they are processed. Check back later for updates.'"
          [hasPermission]="false">
        </app-nodata-view>
        <app-nodata-view *ngIf="activeToggle == 'Cancelled'" class="vstack" [noDataImg]="'bg56.png'" [noDataText]="'Everything’s in order!'"
          [noDataPara]="'There are no cancelled requests at the moment. Stay tuned for any updates if requests are withdrawn.'"
          [hasPermission]="false">
        </app-nodata-view>
      </div>
      <!--End-->

      <!-- Loader updations -->
      <!-- <app-loader *ngIf="loader"></app-loader> -->
      <div class="row row-16" *ngIf="tabLoader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card11'"
          *ngFor="let dummy of ' '.repeat(10).split(''); let x = index"></app-common-loader>
      </div>

      <!-- Landing Div -->
      <ng-template #all_data>
        <div class="" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
          (scrolled)="onScrollDown()">
          <div class="row row-16">
            <ng-container *ngIf="tabLoader == false">
              <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let req of requests; let i = index">
                <div
                  class="card card-c2 card-hover px-16 h-100 {{(req.status == 'Invalid' || req?.status == 'Cancelled') ? 'card-disable' : '' }}"  (click)="viewRequest(req)">
                  <div class="card-body pb-8 px-0">
                    <div class="vstack gap-2 text-trim position-relative">
                      <p class="mb-0 fs-14 fw-500 lh-base text-trim pe-22" title="{{ req.request_type_name }}">{{
                        req.request_type_name }}</p>
                      <p class="mb-0 fs-10 fw-500 lh-base text-trim text-light-500 hstack gap-4">
                        <span>Requested on: </span>
                        <span>{{this.appservice.dateFormatDisplay(req.requested_on)}}</span>
                      </p>

                      <i class="icon-message-system text-warning fs-20 ms-8 position-absolute end-0" *ngIf="(
                                                                        req?.request_type_name == 'Overtime' ||
                                                                        req?.request_type_name == 'Compensatory Off' ||
                                                                        req?.request_type_name == 'Check-in Request') &&
                                                                        req?.is_system_generated"></i>
                      <span *ngIf="req.declaration_status != null"
                        [ngClass]="{ 'badge-success1': req.declaration_status === 'active'}"
                        class="badge  py-1 text-uppercase fw-600 fs-9 position-absolute end-0 bottom-0">
                        {{ req.declaration_status === "active" ? "Active" : "Inactive" }}
                      </span>
                    </div>
                  </div>
                  <div class="card-body px-0 pt-8  vstack gap-8 fs-12">
                    <!-- ACTIVITY Section -->
                    <ng-container *ngIf="req.status == 'Invalid' || req.status == 'Cancelled';  else active1">
                      <div class="hstack justify-content-between ">
                          <span *ngIf="req.status == 'Invalid'" class="text-light-500 ">Activities</span>
                          <span *ngIf="req.status == 'Cancelled'" class="text-light-500 ">Activities ({{ req.current_level }}/{{req.maxLevel}}) </span>
                        <ng-container
                          *ngIf=" req.status == 'Invalid' && (req?.request_type_name == 'Proposed Investment Declaration' || req?.request_type_name == 'Confirmed Investment Declaration' || req?.request_type_name == 'Reimbursement' || req?.request_type_name == 'FBP Declaration') ">-
                        </ng-container>
                        <ng-container *ngIf="req.status == 'Invalid' && (req?.request_type_name == 'Leave' || req?.request_type_name == 'Attendance Regularization' || req?.request_type_name == 'On Duty' || req?.request_type_name == 'Work From Home' ||req?.request_type_name == 'Overtime' || req?.request_type_name == 'Compensatory Off' || req?.request_type_name == 'Holiday' || req?.request_type_name == 'Asset')">
                          <span class="link-primary1" (click)="requestViewStatus(req.id, req.approval_required,$event)">View status</span>
                        </ng-container>
                        <ng-container *ngIf="req.status == 'Cancelled'">
                          <span class="link-primary1 " (click)="requestViewStatus(req.id, req.approval_required,$event)">View status</span>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-template #active1>
                      <ng-container *ngIf="req.approval_required">
                        <div class="hstack justify-content-between ">
                          <span class="text-light-500 ">Activities ({{ req.current_level }}/{{req.maxLevel}})</span>
                          <span class="link-primary1" (click)="requestViewStatus(req.id, req.approval_required,$event)">View status</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!req.approval_required">
                        <div class="hstack justify-content-between ">
                          <span class="text-light-500 ">Activities </span>
                          <span class="link-primary1" (click)="requestViewStatus(req.id, req.approval_required,$event)">View status</span>
                        </div>
                      </ng-container>
                    </ng-template>
                    <!-- ACTIVITY Section -->
                    <!-- Accepted Section -->
                    <ng-container *ngIf="req.status == 'Invalid' || req.status == 'Cancelled';  else active2">
                      <div class="hstack justify-content-between" style="height:1.5rem;">
                        <span class="text-light-500 text-nowrap">Pending with</span>
                        <span>-</span>
                      </div>
                    </ng-container>
                    <ng-template #active2>
                      <ng-container *ngIf="req.approval_required">
                        <ng-container *ngIf="req?.accepted_by?.length == 0">
                          <div class="d-flex align-items-center justify-content-between overflow-hidden my-auto" style="height:1.5rem;">
                            <div class="fs-12 text-muted">Loading</div>
                            <div class="dot-pulse me-16"></div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="req?.accepted_by?.length > 0">
                          <div class="hstack justify-content-between">
                            <span class="text-light-500 text-nowrap">
                              {{
                              req.status == "Accepted"? "Accepted by":
                              req.status == "Rejected"? "Rejected by":
                              req.status == "Terminated"? "Terminated by":
                              req.status == "Confirmed"? "Confirmed by":
                              req.status == "Extended Probation"? "Probation Extended by":
                              req.status == "Pending"? "Pending with": ""}}
                            </span>
                            <div class="avatar-overlap avatar-overlap-right " style="--size:1.5rem; --count :4;--gap:1rem;">
                              <ng-container *ngFor="let aacpt of req.accepted_by;let i = index">
                                <img class="avatar avatar-border" [ngbPopover]="popContentSingle" triggers="hover" *ngIf="i < 3 && aacpt.profile_image != '' && aacpt.profile_image != null"
                                  src="{{aacpt.profile_image}}" container="body" popoverClass="user-list single-user">
                                <div *ngIf="i < 3 && (aacpt.profile_image == '' || aacpt.profile_image  == null)"
                                  class="avatar avatar-border {{aacpt.color_code}}" [ngbPopover]="popContentSingle" triggers="hover"
                                  container="body" popoverClass="user-list single-user">
                                  <span>{{this.appservice.getFirstChar(aacpt.first_name +"" +aacpt.last_name,2)}}</span>
                                </div>
                                <ng-template #popContentSingle>
                                  <div class="user-details">
                                    <span class="user-label">{{aacpt?.name}}</span>
                                    <span class="user-code">EMP CODE: {{ aacpt?.employee_code }}
                                    </span>
                                  </div>
                                </ng-template>
                                <div class="avatar fs-12" *ngIf="i==3" [ngbPopover]="popContentMultilpe"
                                  popoverClass="user-list multi-user" triggers="click" container="body" (click)="onPopoverClick($event)">
                                  +{{this.appservice.subEmp(req.accepted_by?.length)}}</div>
                              </ng-container>

                              <ng-template #popContentMultilpe>
                                <ul>
                                  <ng-container *ngFor="let re of req.accepted_by;let i = index">
                                    <ng-container *ngIf="i >= 3">
                                      <li *ngIf=" re.profile_image != '' && re.profile_image != null ">
                                        <img class="user-avatar" src="{{ re.profile_image }}" />
                                        <div class="user-details">
                                          <span class="user-label">{{re.name}}</span>
                                          <span class="user-code">EMP CODE :{{ re.employee_code }}</span>
                                        </div>
                                      </li>
                                      <li *ngIf=" re.profile_image == '' || re.profile_image == null ">
                                        <span class="user-avatar {{ re.color_code }}">{{this.appservice.getFirstChar(re.first_name
                                          +"" +re.last_name,2)}}</span>
                                        <div class="user-details">
                                          <span class="user-label">{{re.name}}</span>
                                          <span class="user-code">EMP CODE :{{ re.employee_code }}</span>
                                        </div>
                                      </li>
                                    </ng-container>
                                  </ng-container>
                                </ul>
                              </ng-template>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!req.approval_required">
                        <div class="hstack justify-content-between ">
                          <span class="text-light-500 text-nowrap">Accepted By</span>
                          <span>Self</span>
                        </div>
                      </ng-container>
                    </ng-template>
                    <ng-container>
                      <div class="hstack justify-content-between ">
                        <span class="text-light-500 text-nowrap">Status</span>
                        <span class="text-uppercase fs-10 fw-500 badge py-1"
                          [ngClass]="{
                          'badge-success':req.status === 'Accepted',
                          'badge-warning': req.status === 'Pending',
                          'badge-danger':(req.status === 'Rejected'|| req.status === 'Invalid'),
                          '': req.status === 'Cancelled'}">{{ req.status }}</span>
                      </div>
                    </ng-container>
                    <!-- Accepted Section -->
                    <!-- Date Section -->
                    <div class="hstack justify-content-between">
                      <span class="text-light-500 ">Current status date</span>
                      <span>{{this.appservice.dateFormatDisplay(req.modified_at)}}</span>
                    </div>
                    <!-- Date Section -->
                  </div>
                </div>
              </div>
              <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let req of requests; let i = index">
                <div class="card card-c2 h-100 px-16 py-0 card-hover card-ribbon {{
                    req.status == 'Invalid'
                      ? 'card-disable'
                      : req?.status == 'Cancelled'
                      ? 'card-disable'
                      : ''
                  }}">
                  <span *ngIf="req.status == 'Invalid'" class="ribbon ribbon-right ribbon-danger ribbon-sm"
                    style="top: 1rem">Invalid</span>
                  <span *ngIf="req.status == 'Cancelled'" class="ribbon ribbon-right ribbon-secondary ribbon-sm"
                    style="top: 1rem">Cancelled</span>
                  <div class="card-body h-100 pt-16 pb-8 px-0" (click)="
                      viewRequest(
                        req.request_object,
                        req.request_type_name,
                        req.id,
                        req.declaration_status,
                        req.status
                      )
                    ">
                    <div class="d-flex justify-content-between align-items-center" style="min-height: 1.25rem">
                      <div class="fs-10 fw-500">
                        <span class="text-light-400 text-uppercase">Requested on : </span>{{
                          this.appservice.dateFormatDisplay(req.requested_on)
                        }}
                      </div>
                      <div class="fs-14 px-2 fw-500 badge3" *ngIf="
                          req.status != 'Invalid' && req.status != 'Cancelled'
                        " [ngClass]="{
                          'badge-warning': req.status === 'Pending',
                          'badge-success': (req.status === 'Accepted' || req.status === 'Confirmed'),
                          'badge-danger' : (req.status === 'Rejected' || req.status === 'Terminated'),
                          'badge-primary': req.status === 'Extended Probation'
                        }">
                        {{ req.status }}
                      </div>
                    </div>
                    <span class="fw-500 text-trim-2 w-75 {{
                        req.status == 'Invalid'
                          ? 'text-light-400'
                          : 'text-light-600'
                      }} ">{{ req.request_type_name }}
                      <i class="icon-message-system text-warning fs-20 ms-8" *ngIf="
                      (req?.request_type_name == 'Overtime' ||
                        req?.request_type_name == 'Compensatory Off' ||
                        req?.request_type_name == 'Check-in Request') &&
                      req?.is_system_generated
                    "></i>

                      <span *ngIf="req.declaration_status != null"
                        class="badge fs-9 text-uppercase py-1 position-relative" style="top: 0.25rem" [ngClass]="{
                          'badge-success1': req.declaration_status === 'active'
                        }">{{
                          req.declaration_status === "active"
                            ? "Active"
                            : "Inactive"
                        }}</span></span>
                  </div>
                  <ng-container *ngIf="
                      req.status == 'Invalid' || req.status == 'Cancelled';
                      else active
                    ">
                    <div class="card-body d-flex flex-column gap-8 py-16 px-0">
                      <div class="d-flex align-items-center justify-content-between overflow-hidden">
                        <div class="text-muted fs-12 me-8 text-nowrap" *ngIf="req.status == 'Invalid'">
                          Activities
                        </div>
                        <div class="text-muted fs-12 me-8 text-nowrap" *ngIf="req.status == 'Cancelled'">
                          Activities ({{ req.current_level }}/{{
                            req.maxLevel
                          }})
                        </div>
                        <div class="d-flex align-items-center overflow-hidden">
                          <div class="fs-12 fw-500" *ngIf="
                              req.status == 'Invalid' &&
                              (req?.request_type_name ==
                                'Proposed Investment Declaration' ||
                                req?.request_type_name ==
                                  'Confirmed Investment Declaration' ||
                                req?.request_type_name == 'Reimbursement' ||
                                req?.request_type_name == 'FBP Declaration')
                            ">
                            -
                          </div>

                          <div class="fs-12 fw-500 link-primary1" *ngIf="
                              req.status == 'Invalid' &&
                              (req?.request_type_name == 'Leave' ||
                                req?.request_type_name ==
                                  'Attendance Regularization' ||
                                req?.request_type_name == 'On Duty' ||
                                req?.request_type_name == 'Work From Home' ||
                                req?.request_type_name == 'Overtime' ||
                                req?.request_type_name == 'Compensatory Off' ||
                                req?.request_type_name == 'Holiday')
                            " (click)="
                              requestViewStatus(req.id, req.approval_required)
                            ">
                            View status
                          </div>

                          <div class="fs-12 fw-500 link-primary1" *ngIf="req.status == 'Cancelled'" (click)="
                              requestViewStatus(req.id, req.approval_required)
                            ">
                            View status
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between" style="min-height: 1.75rem">
                        <div class="text-muted fs-12 me-8 text-nowrap">
                          Pending with
                        </div>
                        <div class="d-flex align-items-center overlap-right text-muted fs-12 fw-500 text-nowrap">
                          -
                        </div>
                      </div>
                      <div (click)="
                          viewRequest(
                            req.request_object,
                            req.request_type_name,
                            req.id,
                            req.declaration_status,
                            req.status
                          )
                        " class="d-flex align-items-center justify-content-between overflow-hidden">
                        <div class="text-muted fs-12 me-8 text-nowrap">
                          Current status date
                        </div>
                        <div class="d-flex align-items-center overflow-hidden">
                          <div class="fs-12 fw-500 text-trim">
                            {{
                              this.appservice.dateFormatDisplay(req.modified_at)
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #active>
                    <ng-container *ngIf="req.approval_required">

                      <div class="card-body d-flex flex-column gap-8 py-16 px-0">
                        <div class="d-flex align-items-center justify-content-between overflow-hidden">
                          <div class="text-muted fs-12 me-8 text-nowrap">
                            Activities ({{ req.current_level }}/{{
                              req.maxLevel
                            }})
                          </div>
                          <div class="d-flex align-items-center overflow-hidden">
                            <div class="fs-12 fw-500 link-primary1" (click)="
                                requestViewStatus(req.id, req.approval_required)
                              ">
                              View status
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="req?.accepted_by?.length == 0">
                          <div class="d-flex align-items-center justify-content-between overflow-hidden">
                            <div class="fs-12 text-muted">Loading</div>
                            <div class="dot-pulse me-16"></div>
                          </div>
                        </ng-container>
                        <div *ngIf="req?.accepted_by?.length > 0"
                          class="d-flex align-items-center justify-content-between">
                          <div class="text-muted fs-12 me-8 text-nowrap">
                            {{
                              req.status == "Accepted"
                                ? "Accepted by"
                                : req.status == "Rejected"
                                ? "Rejected by"
                                : req.status == "Terminated"
                                ? "Terminated by"
                                : req.status == "Confirmed"
                                ? "Confirmed by"
                                : req.status == "Extended Probation"
                                ? "Probation Extended by"
                                : req.status == "Pending"
                                ? "Pending with"
                                : ""
                            }}
                          </div>
                          <div class="d-flex align-items-center overlap-avatar overlap-right">
                            <ng-container *ngFor="
                                let aacpt of req.accepted_by;
                                let i = index
                              ">
                              <img class="avatar-sm sq-32 avatar-circle" *ngIf="
                                  i < 3 &&
                                  aacpt.profile_image != '' &&
                                  aacpt.profile_image != null
                                " src="{{ aacpt.profile_image }}" [ngbPopover]="popContentSingle"
                                popoverClass="user-list single-user" triggers="hover" container="body" />
                              <div class="avatar-sm sq-32 avatar-circle {{
                                  aacpt.color_code
                                }}" *ngIf="
                                  i < 3 &&
                                  (aacpt.profile_image == '' ||
                                    aacpt.profile_image == null)
                                " [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                                container="body">
                                <span>{{
                                  this.appservice.getFirstChar(
                                    aacpt.first_name +
                                      "
                                  " +
                                      aacpt.last_name,
                                    2
                                  )
                                }}</span>
                              </div>
                              <ng-template #popContentSingle>
                                <div class="user-details">
                                  <span class="user-label">{{
                                    aacpt?.name
                                  }}</span>
                                  <span class="user-code">EMP CODE: {{ aacpt?.employee_code }}
                                  </span>
                                </div>
                              </ng-template>
                              <div *ngIf="i == 3" class="avatar-sm sq-32 avatar-circle"
                                [ngbPopover]="popContentMultilpe" popoverClass="user-list multi-user" triggers="click"
                                container="body">
                                <span>+{{
                                    this.appservice.subEmp(
                                      req.accepted_by.length
                                    )
                                  }}</span>
                              </div>
                            </ng-container>
                            <ng-template #popContentMultilpe>
                              <ul>
                                <ng-container *ngFor="
                                    let re of req.accepted_by;
                                    let i = index
                                  ">
                                  <ng-container *ngIf="i >= 3">
                                    <li *ngIf="
                                        re.profile_image != '' &&
                                        re.profile_image != null
                                      ">
                                      <img class="user-avatar" src="{{ re.profile_image }}" />
                                      <div class="user-details">
                                        <span class="user-label">{{
                                          re.name
                                        }}</span>
                                        <span class="user-code">EMP CODE :
                                          {{ re.employee_code }}</span>
                                      </div>
                                    </li>
                                    <li *ngIf="
                                        re.profile_image == '' ||
                                        re.profile_image == null
                                      ">
                                      <span class="user-avatar {{ re.color_code }}">{{
                                          this.appservice.getFirstChar(
                                            re.first_name +
                                              "
                                        " +
                                              re.last_name,
                                            2
                                          )
                                        }}</span>
                                      <div class="user-details">
                                        <span class="user-label">{{
                                          re.name
                                        }}</span>
                                        <span class="user-code">EMP CODE :
                                          {{ re.employee_code }}</span>
                                      </div>
                                    </li>
                                  </ng-container>
                                </ng-container>
                              </ul>
                            </ng-template>
                          </div>
                        </div>
                        <div (click)="
                            viewRequest(
                              req.request_object,
                              req.request_type_name,
                              req.id,
                              req.declaration_status,
                              req.status
                            )
                          " class="d-flex align-items-center justify-content-between overflow-hidden">
                          <div class="text-muted fs-12 me-8 text-nowrap">
                            Current status date
                          </div>
                          <div class="d-flex align-items-center overflow-hidden">
                            <div class="fs-12 fw-500 text-trim">
                              {{
                                this.appservice.dateFormatDisplay(
                                  req.modified_at
                                )
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!req.approval_required">
                      <div class="card-body d-flex flex-column gap-8 py-16 px-0">
                        <div class="d-flex align-items-center justify-content-between overflow-hidden">
                          <div class="text-muted fs-12 me-8 text-nowrap">
                            Activities
                          </div>
                          <div class="d-flex align-items-center overflow-hidden">
                            <div class="fs-12 fw-500 link-primary1" (click)="
                                requestViewStatus(req.id, req.approval_required)
                              ">
                              View status
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="text-muted fs-12 me-8 text-nowrap">
                            Accepted by
                          </div>
                          <div class="d-flex align-items-center overlap-right text-muted fs-12 fw-500 me-8 text-nowrap">
                            Self
                          </div>
                        </div>
                        <div (click)="
                            viewRequest(
                              req.request_object,
                              req.request_type_name,
                              req.id,
                              req.declaration_status,
                              req.status
                            )
                          " class="d-flex align-items-center justify-content-between overflow-hidden">
                          <div class="text-muted fs-12 me-8 text-nowrap">
                            Current status date
                          </div>
                          <div class="d-flex align-items-center overflow-hidden">
                            <div class="fs-12 fw-500 text-trim">
                              {{
                                this.appservice.dateFormatDisplay(
                                  req.modified_at
                                )
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
              </div> -->
            </ng-container>

            <!-- Loader updations -->
            <ng-container *ngIf="infinityloader">
              <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card11'"
                *ngFor="let dummy of ' '.repeat(10).split(''); let x = index"></app-common-loader>
            </ng-container>
          </div>
        </div>
      </ng-template>

      <!-- End -->
    </div>
  </ng-container>
  <!--No data found-->
  <ng-template #notautherized>
    <div class="row pt-5 flex-center text-center">
      <div class="col-12">
        <img class="img-fluid mx-auto" src="/assets/images/bg/No Access.png" style="width: 438px" />
        <p class="text-uppercase text-light-400 fw-700 fs-14">
          YOU ARE NOT AUTHORIZED
        </p>
      </div>
    </div>
  </ng-template>
  <!--End-->
<!-- </ng-container> -->

<!-- New request side panel -->
<div class="side-panel" style="--sidepanel-width: 45rem" [class.side-pane-active]="showSidePanel === true">
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Raise a request</h5>
      <a class="toggle-panel" (click)="newRequestPanelClose()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto">
      <div class="container-fluid p-32">
        <app-c1-loader *ngIf="loader3"></app-c1-loader>
        <ng-container *ngIf="!loader3">
          <div class="d-flex gap-16">
            <div class="icon-left flex-grow-1 search-dropdowns">
              <app-searchbar [searchlist]="dropdownData" [searchplaceholder]="requestTypeSearch"
                (searchEmitter)="searchtyperesults($event)"
                (keywordsearchEmitter)="searchrequestType($event)"></app-searchbar>
            </div>
          </div>
          <!-- RECENTLY USED SECTION START -->
          <ng-container *ngIf="
              recentUsedReq != '' &&
              searchKeywordRequestType == '' &&
              requestSearchData == ''
            ">
            <p class="text-uppercase fw-500 pt-24">
              Recently used
            </p>
            <div class="row row-12">
              <div class="col-12 col-sm-4 col-md-3" *ngFor="let recentreq of recentUsedReq; let i = index">
                <div class="card card-c2 p-16 h-100 card-hover" (click)="getRequest(recentreq.name)">
                  <div class="card-body flex-center text-center pt-0 px-0 pb-8">
                    <div class="flex-center d-flex sq-36">
                      <i *ngIf="recentreq.icon" class="{{ recentreq.icon }}  fs-26"></i>
                    </div>
                    <span *ngIf="recentreq.image == null">
                      <div *ngIf="recentreq.icon == null" class="logo-img bg{{ i % 5 }} ">
                        {{ this.appservice.getFirstChar(recentreq.name, 2) }}
                      </div>
                    </span>
                  </div>
                  <div class="card-body overflow-hidden flex-center text-center px-0 pb-0 pt-8 h-100"
                    style="min-height: 2.3125rem">
                    <div class="fs-12 fw-500 text-trim-3">
                      {{ recentreq.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- RECENTLY USED SECTION END -->

          <!-- ALL REQUEST SECTION START -->

          <p class="text-uppercase fw-500 pt-24">All</p>
          <app-c1-loader *ngIf="loader2"></app-c1-loader>

          <div class="row pt-5 flex-center text-center" *ngIf="reqTypeSearchlistdata?.length == 0 && !loader2">
            <div class="col-12">
              <img class="img-fluid mx-auto" src="/assets/images/bg/15 Startup launch.png" style="width: 438px" />
              <p class="text-uppercase text-light-400 fw-700 fs-14">NO DATA</p>
            </div>
          </div>
          <div *ngIf="reqTypeSearchlistdata?.length > 0 && !loader2" class="row row-12">
            <div class="col-12 col-sm-4 col-md-3" *ngFor="let reqtype of reqTypeSearchlistdata; let i = index">
              <div class="card card-c2 p-16 h-100 card-hover" (click)="getRequest(reqtype.name)">
                <div class="card-body flex-center text-center pt-0 px-0 pb-8">
                  <div class="flex-center d-flex sq-36">
                    <i *ngIf="reqtype.icon" class="{{ reqtype.icon }} fs-26"></i>
                  </div>
                  <span *ngIf="reqtype.icon == null">
                    <div class="logo-img bg{{ i % 5 }} ">
                      {{ this.appservice.getFirstChar(reqtype.name, 2) }}
                    </div>
                  </span>
                </div>
                <div class="card-body overflow-hidden flex-center text-center px-0 pb-0 pt-8 h-100"
                  style="min-height: 2.3125rem">
                  <div class="fs-12 fw-500 text-trim-3">{{ reqtype.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- ALL REQUEST SECTION END -->
        </ng-container>
      </div>
    </div>
  </form>
</div>

<!-- Info popup start -->
<ng-container *ngIf="alertToggle == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="alertToggle" [infoMsg]="investalertMsg"></app-info-popup>
</ng-container>

<!-- Alert for investment declaration start -->
<!-- <div class="modal  modal-alert {{alertToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-info-circle text-accent2"></i>
        <h4 class="modal-title">Info</h4>
      </div>
      <div class="modal-body py-0">
        <p class="mb-0">{{investalertMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary text-uppercase btn-sm" (click)="alertToggle = false">Ok</button>
      </div>
    </div>
  </div>
</div> -->
<!-- Doc Preview Start -->
<!-- <div class="modal  modal-pdfviewer modal-custom {{modalToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <h4 class="modal-title">{{currentDocName}}</h4>
        <i (click)="modalToggle = false" class="ms-auto bi  bi-x-lg text-light-500"></i>
      </div>
      <div class="modal-body px-0">
        <div class="pdf-container" *ngIf="currentExtension=='pdf' ">
          <pdf-viewer [src]="fileName" [original-size]="false"
            style="height: calc(100% - 1.375rem)!important;"></pdf-viewer>
        </div>
        <img
          *ngIf="currentExtension!='pdf' && currentExtension!='zip' && currentExtension!='docx' && currentExtension!='xlsx'"
          [src]="fileName" style="padding: 1.3125rem;width: 100%;">
      </div>
    </div>
  </div>
</div> -->

<!-- Alert for invalid request start -->
<div class="modal  modal-alert  {{ alertToggle1 == true ? 'show' : '' }}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle fs-24 text-danger"></i>
        <h4 class="modal-title">Invalid request</h4>
      </div>
      <div class="modal-body py-0">
        <p class="mb-0">{{ invalidReqAlertMsg }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary text-uppercase btn-sm px-5" style="width: 15rem"
          (click)="alertToggle1 = false">
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<!-- IT declaration request view -->
<ng-container *ngIf="investmentDeclerationView">
  <div class="side-panel" style="--sidepanel-width: 45rem"
    [class.side-pane-active]="investmentDeclerationView === true">
    <div class="side-panel-container">
      <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
          <h5>{{ investmentSingleData.employee }} - {{ viewHeader }}</h5>
          <a class="toggle-panel" (click)="investmentDeclerationView = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto">
          <div class="container-fluid p-32">
            <app-view-loader *ngIf="loader1"></app-view-loader>
            <ng-container *ngIf="!loader1">
              <ng-container *ngIf="from == 'request'">
                <div class="mb-12 d-flex align-items-center">
                  <span class="fs-12 ms-auto text-light-400 me-8">Status :</span>
                  <span class="fw-500 badge fs-9 text-uppercase py-1"
                    [ngClass]="{ 'badge-success1': declareStatus === 'active' }">{{
                      declareStatus == "active" ? "ACTIVE" : "INACTIVE"
                    }}</span>
                </div>
              </ng-container>
              <app-view-investment-declaration [from]="from" [investmentSingleData]="investmentSingleData"
                [viewHeader]="viewHeader" [hra80ggSingleData]="hra80ggSingleData"
                [cahpt80CSingleData]="cahpt80CSingleData" [otherSourceSingleData]="otherSourceSingleData"
                [deductionSingleData]="diductionSingleData" [incomeLossSingleData]="incomeLossSingleData"
                [hra80ggFlag]="hra80ggFlag" [exeption_50]="exeption_50" [cityName]="cityName" [requestitem]=""
                [investmentcard1]="investmentDeclerationView" [declareStatus]="declareStatus" [taxData]="taxData"
                [hra80gg]="hra80gg" [loader1]="loader1" [currency]="currency"
                (investmentcardClose)="closeSidePanel($event)"></app-view-investment-declaration>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Holiday request component -->
<app-holiday-setup *ngIf="holidayRequestFlag" [holidayRequestFlag]="holidayRequestFlag"
  [holidayFloatData]="holidayFloatData" [holidatSearchData]="holidatSearchData" [loader]="holiday_loader"
  [nodata]="holiday_nodata" [saveClicked]="saveClicked" (holidayReqClose)="holidayReqCloseFunction($event)"
  (holidaySearch)="holidaySearchFunction($event)" (holidayReqSave)="holidayReqSaveFunction($event)"></app-holiday-setup>

<!-- Holiday request view-->
<app-holiday-request-view [holidayRequestView]="holidayRequestView" [loader]="loader1"
  [holidayRequestSingleData]="holidayRequestSingleData" [from]="'request'" [requestName]="requestName"
  [requestId]="requestId" (holidayViewClose)="holidayViewClose($event)"
  (closeViewPage)="closeViewPage($event)"></app-holiday-request-view>

<!-- Leave request view -->
<ng-container *ngIf="leaveRequestView">
  <app-view-leave-request *ngIf="!loader1" [leaveRequestView]="leaveRequestView" [from]="from"
    [leaveRequestData]="leaveRequestData" [loader1]="loader1" [requestName]="requestName" [requestId]="requestId"
    [compOffData]="compOffData" (closeViewPage)="closeViewPage($event)"
    (leaveViewClose)="leaveViewClose($event)"></app-view-leave-request>
</ng-container>

<!-- Short Leave View -->
<ng-container *ngIf="shortLeaveView">
  <app-view-short-leave *ngIf="!loader1" [loader]="loader1" [shortLeaveData]="shortLeaveData"
   [shortLeaveView]="shortLeaveView" [requestName]="requestName" [requestId]="requestId" (closeViewPage)="closeViewPage($event)"
   (shortLeaveClose)="shortLeaveClose($event)"></app-view-short-leave>
</ng-container>

<!-- Loan Request View -->
<ng-container *ngIf="loanView">
  <app-view-loan-request *ngIf="!loader1" [loader]="loader1" [loanData]="loanData"
   [loanView]="loanView" [requestName]="requestName" [requestId]="requestId" (closeViewPage)="closeViewPage($event)"
   (closeLoanPanel)="closeLoanPanel($event)"></app-view-loan-request>
</ng-container>

<!-- Employee profile request view -->
<ng-container *ngIf="empRequestView == true">
  <div class="side-panel" style="--sidepanel-width: 45rem" [class.side-pane-active]="empRequestView === true">
    <div class="side-panel-container">
      <header class="side-panel-head">
        <h5>View</h5>
        <a class="toggle-panel" (click)="employeeProfileViewClose()"><i class="icon-close-lg"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto">
        <div class="container-fluid px-32 py-24">
          <app-view-employee-profile [requestitem]="empRequestData" [reqId]="reqIds" [loader]="loader1" [from]="from"
            [currentStaus]="currentStaus"></app-view-employee-profile>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- FBP declaration request component -->
<ng-container *ngIf="fbdeclarationFlag">
  <app-fbp-declaration-setup [fbdeclarationFlag]="fbdeclarationFlag" (fbpReqSave)="fbpReqSaveFunction($event)"
    (fbpReqClose)="fbpReqClose($event)"></app-fbp-declaration-setup>
</ng-container>

<!-- FBP declaration request view -->
<ng-container *ngIf="fbpRequestView == true">
  <div class="side-panel" style="--sidepanel-width: 45rem" [class.side-pane-active]="fbpRequestView === true">
    <div class="side-panel-container">
      <header class="side-panel-head">
        <h5>View</h5>
        <a class="toggle-panel" (click)="fbbDeclarationViewClose()"><i class="icon-close-lg"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto">
        <div class="container-fluid px-32 py-24">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">FBP Declaration Request</p>
          </div>
          <app-c1-loader *ngIf="loader1"></app-c1-loader>
          <ng-container *ngIf="!loader1">
            <app-view-fbp-declaration [fbpReqViewData]="fbpReqViewData" [currency]="currency" [from]="from"
              [fbpStatus]="fbpStatus"></app-view-fbp-declaration>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- View status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width: 32.1875rem" [class.side-pane-active]="viewstatusPanel === true">
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <app-view-status [loader]="viewStatusLoader" [approvalRequired]="approvalRequired" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"
        [from]="fromViewStatus"></app-view-status>
    </form>
  </div>
</ng-container>

<!-- Reimbesement request view  -->
<div class="side-panel" style="--sidepanel-width: 45rem" [class.side-pane-active]="empReimburseView === true">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>View</h5>
      <a class="toggle-panel" (click)="empReimburseView = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <app-view-reimbursement *ngIf="empReimburseView" [reimbursementData]="reimbursementData" [loader1]="loader1" [requestId]="requestId"
      [from]="'request'" [reimbursementType]="reimbursementType" (closeViewPage)="closeViewPage($event)">
    </app-view-reimbursement>
  </div>
</div>

<!-- Request landing page filter -->
<div class="side-panel" style="--sidepanel-width: 27rem" [class.side-pane-active]="filterpanel === true"
  [formGroup]="filterForm">
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Filters</h5>
      <a class="toggle-panel" (click)="filterpanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto">
      <div class="container-fluid p-32">
        <div class="row row-16">
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Category</p>
          </div>
          <div class="col-12 form-row">
            <ng-select placeholder="{{
                this.messageService.selectplaceholddisplay('category')
              }}" formControlName="category_list" [items]="category_list" [multiple]="true" groupBy="selectedAllGroup"
              [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
              bindValue="id" class="multiselect">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected"
                  [ngModelOptions]="{ standalone: true }" />
                Select All
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-inline-flex align-items-center overflow-hidden w-100">
                  <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected"
                    [ngModelOptions]="{ standalone: true }" class="me-8" />
                  <!-- {{
                    item.name?.length > 25
                      ? (item.name | slice : 0 : 25) + ".."
                      : item.name
                  }} -->
                  <span class="text-trim">{{
                    item.name
                  }}</span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="filterForm.value.category_list?.length">
                  <span class="ng-value-label">{{ filterForm.value.category_list.length }} Category
                    Selected.</span>
                </div>
              </ng-template>
            </ng-select>
            <ul class="selected-values mt-16" *ngIf="filterForm.value.category_list?.length != 0">
              <li class="ng-value" *ngFor="
                  let item of filterForm.value.category_list;
                  let ki = index
                ">
                <ng-container *ngFor="let insideitem of category_list; let k = index">
                  <span class="ng-value-label" *ngIf="insideitem['id'] == item">
                    {{
                      insideitem["name"].length > 15
                        ? (insideitem["name"] | slice : 0 : 15) + ".."
                        : insideitem["name"]
                    }}</span>
                </ng-container>
                <span *ngIf="disabled == false" class="ng-value-icon right"
                  (click)="clearcommonForm('category_list', ki)" aria-hidden="true">×</span>
              </li>
            </ul>
          </div>
          <div class="col-12 form-row">
            <p class="text-uppercase fw-500 mb-0">Requested ON</p>
          </div>
          <div class="form-icon icon-right vertical-datepicker">
            <input class="form-control datepicker-vertical" type="text" onkeydown="return false" opens="right"
              ngxDaterangepickerMd [closeOnAutoApply]="true" [showCustomRangeLabel]="true" [alwaysShowCalendars]="false"
              [ranges]="ranges" [linkedCalendars]="true" [locale]="{ applyLabel: 'ok' }" [isInvalidDate]="isInvalidDate"
              [showClearButton]="true" placeholder="Select period" formControlName="req_date" />
            <i class="icon-calendar pointer-event"></i>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetfilter()">
        Reset
      </button>
      <button type="submit" (click)="applyfilter()" class="ms-auto btn btn-primary text-uppercase btn-sm">
        Apply
      </button>
    </footer>
  </div>
</div>

<!-- <div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='workFromHome === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="workFromHome = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">

        <div class="d-flex mb-16">
          <p class="text-uppercase fw-500 mb-0">Add weekly off</p>

        </div>
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24">
                <div class="col-12 d-flex">
                  <span class="title">WORK FROM HOME REQUEST</span>
                  <span class=" fs-14 ms-auto px-2 fw-500 badge-success  badge3">
                    Accepted
                  </span>
                </div>
                <div class="col-12">
                  <div class="card card-c2 p-16">
                    <div class="row row-24">
                      <div class="col-md-4">
                        <div class="detail-title">From Date</div>
                        <div class="detail-desc">21/03/2021, Friday</div>

                      </div>
                      <div class="col-md-4">
                        <div class="detail-title">To date</div>
                        <div class="detail-desc">29/03/2021, Monday</div>

                      </div>
                      <div class="col-md-4">
                        <div class="detail-title">No. of Days</div>
                        <div class="detail-desc">8.5 Days</div>

                      </div>

                      <div class="col-12">
                        <div class="detail-title">Full day</div>
                        <div class="detail-desc">21 March 2021, 22 March 2021, 23 March 2021, 24 March 2021, 25 March
                          2021, 26 March 2021, 27 March 2021, 28 March 2021</div>
                      </div>

                      <div class="col-12">
                        <div class="detail-title">Half day</div>
                        <div class="detail-desc">29 March 2021</div>
                      </div>
                      <div class="col-12">
                        <div class="detail-title">Reason</div>
                        <div class="detail-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt.</div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">

      <button type="submit" class="ms-auto btn btn-outline-danger text-uppercase btn-sm">
        CANCEL REQUEST
      </button>
    </footer>
  </div>
</div> -->

<!-- ************************************* TIME & ATTENDANCE COPONENT AND VIEW SECTIONS START ******************* -->

<!-- Compensatory off request component -->
<app-compensatory-off-setup *ngIf="compOffRequestFlag" [reqFlag]="compOffRequestFlag" [listData]="compOffListData"
  [loader]="holiday_loader" [nodata]="holiday_nodata" (successEmitter)="compOffSuccess($event)"
  (compOffClose)="compOffReqCloseFunction()"></app-compensatory-off-setup>

<!-- View page sections -->
<ng-container *ngIf="viewPanel == true">
  <div class="side-panel" style="--sidepanel-width: 45rem" [class.side-pane-active]="viewPanel === true">
    <div class="side-panel-container">
      <header class="side-panel-head">
        <h5>View</h5>
        <a class="toggle-panel" (click)="viewPanel = false"><i class="icon-close-lg"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto" *ngIf="reqName != 'Work From Home'">
        <div class="container-fluid px-32 py-24">
          <app-view-loader *ngIf="loader1"></app-view-loader>

          <app-view-regularization-request *ngIf="reqName == 'Attendance Regularization' && !loader1"
            [regularizationCancel]="regularizeCancel" [data]="regularizationData" [id]="regualarizationID"
            [loader]="loader_regularization" (closePanel)="closePanel($event)"></app-view-regularization-request>

          <app-view-on-duty-request *ngIf="reqName == 'On Duty' && !loader1" [onDutyData]="onDutyData"
            [onDutyCancel]="onDutyCancel" [onDutyID]="onDutyID" [loader]="loader_OnDuty"
            (closePanel)="closePanel($event)"></app-view-on-duty-request>

          <app-view-overtime-request *ngIf="!loader1 && reqName == 'Overtime'"
            [viewData]="viewData"></app-view-overtime-request>

          <app-view-comp-off-request *ngIf="!loader1 && reqName == 'Compensatory Off'"
            [viewData]="viewData"></app-view-comp-off-request>

          <app-view-checkin-request *ngIf="!loader1 && reqName == 'Check-in Request'" [viewData]="viewData"
            [checkInId]="checkInId"></app-view-checkin-request>

          <!-- Asset -->
          <app-view-asset-request *ngIf="!loader1 && reqName == 'Asset'" [viewData]="viewData" [requestId]="reqIds"></app-view-asset-request>


          <!-- Expense Module -->
          <app-view-expense-report-request
          *ngIf="!loader1 && reqName == 'Expense Report'"
          [viewData]="viewData" [loader]="loader1" [currency]="currency"
        ></app-view-expense-report-request>
          <!-- End -->
        </div>
      </div>
      <app-view-wfh-request *ngIf="reqName == 'Work From Home'" [loader1]="loader1"
        [workFromHomedata]="workFromHomedata" [workFromHomecancel]="workFromHomecancel"
        (closePanel)="closePanel($event)"></app-view-wfh-request>
    </div>
  </div>
</ng-container>

<!-- ************************************* TIME & ATTENDANCE VIEW SECTIONS END ******************* -->

<!-- test start -->
<app-asset-request *ngIf="(requestName == 'Asset') && requestFlag == true" [assetRequest]="requestFlag" (requestSave)="requestSave($event)" (requestClose)="requestFlag = false"></app-asset-request>
<!-- test end -->


<!-- test start -->
<!-- EXPENSE MODULE SECTION -->
<!-- <ng-container *ngIf="viewPanel == true">
  <div
    class="side-panel"
    style="--sidepanel-width: 45rem"
    [class.side-pane-active]="viewPanel === true"
  >
    <div class="side-panel-container">
      <header class="side-panel-head">
        <h5>View</h5>
        <a class="toggle-panel" (click)="viewPanel = false"
          ><i class="icon-close-lg"></i
        ></a>
      </header>
      <div
        class="side-panel-body"
        style="overflow-y: auto"
      >
        <div class="container-fluid px-32 py-24">

          <app-view-loader *ngIf="loader1"></app-view-loader>


          <app-view-expense-report-request
            *ngIf="!loader1 && reqName == 'Expense Report'"
            [viewData]="viewData" [loader]="loader1" [currency]="currency"
          ></app-view-expense-report-request>


        </div>
      </div>

    </div>
  </div>
</ng-container> -->

<app-add-expense-advance *ngIf="addadvance" [addadvance]="addadvance" [from]="'expenseAdvanceRequest'" [amountlimitdata]="validationdata" (panelclose)="closefn($event)" (detailout)="detailout($event)" [outstandingamount]="advancedatalist[1]?.outstanding_amount">
</app-add-expense-advance>

<app-emp-advance-details-view *ngIf="viewAdvance" [viewAdvance]="viewAdvance" [from]="'expenseAdvanceRequestView'" [viewdata]="requestViewData.expense_advance" [viewLoader]="loader1" [currency]="requestViewData.currency" (panelclose)="closeViewPanel($event)" > </app-emp-advance-details-view>
<!-- END -->
<!-- test end -->
