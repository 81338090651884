<div class="d-flex flex-column" *ngIf="resetPermission">
  <div class="container-fluid p-24" style="min-height:calc(100vh - (3rem + 2.5rem + 4.5rem))">
    <div class="row row-16 h-100">
      <div class="col-12 ">
        <h3>Search existing employees</h3>
        <p class="text-helper mb-md-0">Reset passwords for existing employees and search for multiple employees at once
          by adding a comma between the employee codes.</p>
      </div>
      <div class="col-12">
        <div class="card card-c2 p-24 gap-16">
          <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]="searchkey" [defaultFilterFunction]="defaultFilterFunction"
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchKeyFn($event)" (enterClicked)="enterClicked()"></app-searchbar>
          <ul class="selected-values gap-8" *ngIf="inviteData.length!=0">
            <li class="ng-value m-0 " *ngFor="let i of inviteData; let ind = index">
              <span class="d-flex align-items-center p-0 ">{{i?.employee_name}} : {{i?.employee_code}}</span>
              <span class="ng-value-icon right m-0 " (click)="applyTagClose(ind)">x</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12" *ngIf="inviteData.length<=0">
        <div class="row" style="height: calc(100vh - 25rem);">
          <app-nodata-view class="vstack" [noDataImg]="'bg55.png'" [noDataText]="'Manage Password Resets'"
            [noDataPara]="'Find and select employees to send new passwords or regenerate existing ones as needed.'"
            [hasPermission]="false">
          </app-nodata-view>
        </div>
      </div>
      <ng-container *ngIf="inviteData.length>0">
        <div class="col-12">
          <div class="card card-c2 p-0">
            <div class="table-responsive  scrollbar-10 radius-4">
              <table class="table sticky-header table-striped table-sm td-white-space td-width-15ch ">
                <thead>
                  <tr>
                    <th class="form-cell td-checkbox-16" *ngIf="resetPermission?.e">
                      <input class="form-check-input checkbox-16 m-0" type="checkbox" value=""
                        (click)="checkAll($event)" [checked]="pageChange()">
                    </th>
                    <th class="fw-600" scope="col">Employee CODE</th>
                    <th class="fw-600" scope="col">Employee name</th>
                    <th class="fw-600" scope="col">BUSINESS UNIT</th>
                    <th class="fw-600" scope="col">DEPARTMENT</th>
                    <th class="fw-600" scope="col">DESIGNATION</th>
                    <th class="fw-600" scope="col">WORK EMAIL</th>
                    <th class="fw-600" scope="col">MObile number</th>
                    <th class="fw-600" scope="col">Date of Joining</th>
                    <th class="fw-600" scope="col">Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let i of $any(inviteData| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)">
                    <th class="form-cell td-checkbox-16" *ngIf="resetPermission?.e">
                      <input class="form-check-input checkbox-16 m-0" type="checkbox" [(ngModel)]="i.checked">
                    </th>
                    <td data-toggle="tooltip" title="{{i.employee_code}}">{{i.employee_code}}</td>
                    <td data-toggle="tooltip" title="{{i.employee_name}}">{{i.employee_name}}</td>
                    <td data-toggle="tooltip" title="{{i.businessunit_name}}">{{i.businessunit_name}}</td>
                    <td data-toggle="tooltip" title="{{i.department_name}}">{{i.department_name}}</td>
                    <td data-toggle="tooltip" title="{{i.designation_name}}">{{i.designation_name}}</td>
                    <td data-toggle="tooltip" title="{{i.work_email}}">{{i.work_email}}</td>
                    <td data-toggle="tooltip" title="{{i.mobile_number}}">{{i.mobile_number}}</td>
                    <td data-toggle="tooltip" title="{{this.appService.dateFormatDisplay(i.date_of_joined)}}">{{this.appService.dateFormatDisplay(i.date_of_joined)}}</td>
                    <td *ngIf="i.employee_login_data != null" data-toggle="tooltip" title="{{this.appService.datetimeconvert(i.employee_login_data)}}">{{this.appService.datetimeconvert(i.employee_login_data)}}</td>
                    <td *ngIf="i.employee_login_data == null" data-toggle="tooltip" >-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 mb-2" *ngIf="inviteData.length>pageSize">
          <ngb-pagination class="d-flex justify-content-end" [(page)]="page"
            [collectionSize]="inviteData.length" [maxSize]="3" [rotate]="true"
            [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
        </div>
      </ng-container>
    </div>
  </div>
  <footer class="side-panel-footer radius-0 mt-auto gap-24 justify-content-end position-sticky bottom-0"
    *ngIf="resetPermission?.e">
    <p class="text-light-400 fs-14 text-center mb-0" *ngIf="getWholeData().length !== 0 ">{{getWholeData().length}} Employees
      selected</p>
    <button type="button" class="btn text-uppercase btn-sm btn btn-outline-danger"
      *ngIf="getWholeData().length !== 0 " (click)="appService.unselectAll(inviteData)" [disabled]="getWholeData().length ==0">Cancel
      Selection</button>
      <button type="button" class="btn text-uppercase btn-sm btn btn-outline-primary" (click)="confirmPwddPop= true "
      [disabled]="getWholeData().length ==0 || !canGenerate">ReGenerate & View</button>
    <button type="button" class="btn text-uppercase btn-sm btn btn-primary" (click)="sendPassWord()"
      [disabled]="getWholeData().length ==0 || !canSave">Send password</button>
  </footer>
</div>

<app-error-download [fileName]="'Invite Employees Error'" [successDataCount]="successDataCount"
  [downloadMsg]="errorLog" [failedData]="exportData" [modalHeading]="'Reset password for existing user'"
  (closePanel)="errorLog =false" [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>

  <div class="modal  modal-alert {{gnrtdPwddPop == true ? 'show': ''}}" >
    <div class="modal-dialog modal-dialog-centered " style="--bs-modal-width:45.4375rem">
      <div class="modal-content pb-30">
        <div class="modal-header gap-16">
          <h4 class="modal-title">Regenerate Password</h4>
          <button type="button" class="btn-close ms-auto m-0"  (click)="closePwdList();"></button>

        </div>
        <div class="modal-body overflow-auto" style="max-height: 30rem;">
          <div class="row row-16">
            <div class="col-12" *ngFor="let i of gnrtdPwdList">
              <div class="card card-c2 flex-row py-16">
                <div class="  d-flex align-items-center px-16 gap-16 flex-shrink-0" style="width:16rem">
                  <div class="avatar-circle sq-48 fw-500 {{i.employee_data.color_code}}"  *ngIf="i.employee_data.profile_image ==  '' || i.employee_data.profile_image ==  null">{{this.appService.getFirstChar(i.employee_data.first_name+"
                    "+i.employee_data.last_name,2)}}</div>
                     <img class="avatar-sm sq-48 img-fluid rounded-circle me-1" *ngIf="i.employee_data.profile_image !=  '' && i.employee_data.profile_image !=  null" [src]="i.employee_data.profile_image">
                  <div class="overflow-hidden  d-flex flex-column">
                    <span class="fs-14  pb-1 text-trim">{{i.employee_data.first_name+" "+(i.employee_data.middle_name!=null?i.employee_data.middle_name+" ":" " )+i.employee_data.last_name }}</span>
                    <span class="fs-10 fw-500 text-light-400 text-trim text-uppercase ">{{i.employee_data.employee_code}}</span>
                  </div>
                </div>
                <div class="vr flex-shrink-0"></div>
                <div class="px-16 w-100">
                  <label class="form-label">Password</label>
                  <div class="d-flex gap-16">
                    <input type="text" class="form-control" disabled  value="{{i.new_password}}" #sharableLink>
                    <button ngbPopover="Copied"  popoverClass="popover-input popover-info" placement="top" triggers="click" type="button" class="btn btn-primary fs-20 btn-square "  (click)="clipboard.copy(i.new_password)"><i class="icon-copy" ></i></button>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="modal  modal-alert {{confirmPwddPop == true ? 'show': ''}}"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="icon-shield-lock fs-30 text-success"></i>
          <h4 class="modal-title">Regenerate & View</h4>
        </div>

        <div class="modal-body">
          <p class="mb-0">If you regenerate the password, employee cannot login using the existing password. The newly
            generated password should be used to reset their password.
            Are you sure you want to continue?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary text-uppercase btn-sm" (click)="confirmPwddPop=false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase btn-sm" (click)="generatePassWord();">Yes</button>
        </div>
      </div>
    </div>
  </div>
