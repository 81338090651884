<nav class="nav nav-custom sticky">
  <a class="nav-link" [ngClass]="{ active: activeToggle == '' }"
    (click)="selectItemList('')">
    All
  </a>
  <a
    class="nav-link" [ngClass]="{ active: activeToggle == 'Pending' }"
    (click)="selectItemList('Pending')">
    Pending
  </a>
  <a
    class="nav-link" [ngClass]="{ active: activeToggle == 'Rejected' }"
    (click)="selectItemList('Rejected')">
    Rejected
  </a>

</nav>
<!-- *ngIf="permissions" -->
<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Advances</h3>
      <p class="text-helper mb-md-0">Expense advance requests can be raised and tracked in this page.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon " style="width:22.6875rem;">
          <div class="form-icon " style="width:22.6875rem;">
            <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='advancePlaceholder' [isLoading]="sLoading" (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchadvance($event)"></app-searchbar>
        </div>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle (click)="filter=true;">
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addNewdata()"><i
            class="icon-plus"></i>ADD new
          ADVANCE</button>
      </div>
    </div>
  </div>

  <div class="row row-16">
    <div class="col-12"  *ngIf="Isalert && (advancedatalist[0]?.dispense_advance || advancedatalist.length > 1)">
      <div role="alert" class="alert alert-warning py-12 mb-0 show">
        <p class="mb-0 fs-14 hstack ">Total Outstanding Advance Amount : {{advancedatalist[0]?.outstanding_amount?(advancedatalist[0]?.outstanding_amount | currency : currency):'0'}}
        </p>
        <span class="ms-auto link-primary1 fw-500" (click)="viewdetailsout=true;" >View details</span>
        <!-- <div class="fs-9 pointer"></div> -->
        <div class="btn-close pointer" (click)="Isalert=false;"></div>
      </div>
    </div>
    <app-table-loader *ngIf="loader"></app-table-loader>
      <div class="col-12" *ngIf="nodata && !loader">
        <div class="row" style="height: calc(100vh - 13rem);">
          <app-nodata-view class="vstack" [noDataImg]="'bg46.png'" [noDataText]="'Advance manager!'"
            [noDataPara]="'Ready to add new advances and keep the table updated.'" [hasPermission]="false">
          </app-nodata-view>
        </div>
      </div>
    <div class="col-12"  *ngIf="!loader && !nodata ">
      <div class="card card-c2">
        <div class="table-responsive radius-4">
          <table class="table td-12 th-12 td-fs-14 tr-fs-14 table-striped table-sm form-table">
            <thead>
              <tr>
                <th class="fw-600 text-uppercase" scope="col">Advance number</th>
                <th class="fw-600 text-uppercase" scope="col">DATE</th>
                <th class="fw-600 text-uppercase" scope="col">EXPENSE ADVANCE AMOUNT</th>
                <th class="fw-600 text-uppercase" scope="col">PURPOSE</th>
                <!-- <th class="fw-600 text-uppercase" scope="col">TRIP</th> -->
                <th class="fw-600 text-uppercase" scope="col">REPORT NAME</th>
                <th class="fw-600 text-uppercase" scope="col">DISPENSED AMOUNT</th>
                <th class="fw-600 text-uppercase" scope="col">Status</th>
              </tr>
            </thead>
            <!-- <tbody *ngIf="nodata ">
              <tr>
                  <td colspan="9">
                      <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                      </div>
                  </td>
              </tr>
            </tbody> -->
            <tbody class="cell-16 "  >
              <ng-container *ngFor='let advancedata of $any(advancedatalist| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize),let i = index'>
                <tr>
                  <td><a (click)="viewAdvanceDetails(advancedata?.id);" class="link-primary1">{{advancedata?.number}}</a></td>
                  <td><span class="text-nowrap">{{appService.dateFormatDisplay(advancedata?.expense_date)}}</span></td>
                  <td>{{advancedata?.amount | currency : currency}}</td>
                  <td>{{advancedata?.purpose
                  }}</td>
                  <!-- <td>{{advancedata?.trip?advancedata?.trip:'---'}}</td> -->
                  <td>{{advancedata?.report_name?advancedata?.report_name:'---'}}</td>
                  <td>{{advancedata?.dispense_advance?(advancedata?.dispansed_amount | currency : currency):'---'}}</td>
                  <td *ngIf="advancedata?.status == 'Pending'"><span class="badge badge-warning py-1 px-3">Pending</span></td>
                  <td *ngIf="advancedata?.status == 'Carry forward'"><span class="badge badge-primary py-1 px-3">Carry forwarded</span></td>
                  <td *ngIf="advancedata?.status == 'Rejected'"><span class="badge badge-danger py-1 px-3">Rejected</span></td>
                  <td *ngIf="advancedata?.status == 'Accepted' && advancedata?.dispense_advance==false"><span class="badge badge-success py-1 px-3">Accepted</span></td>
                  <td *ngIf="advancedata?.status == 'Accepted' && advancedata?.dispense_advance"><span class="badge badge-primary py-1 px-3">Dispensed</span></td>
                  <td *ngIf="advancedata?.status == 'Cancelled'"><span class="badge badge py-1 px-3">Cancelled</span></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="this.appService.filteredcount(advancedatalist,searchKeyword)>pageSize">
      <ngb-pagination class="d-flex justify-content-end"
          [collectionSize]="this.appService.filteredcount(advancedatalist,searchKeyword)" [(page)]="page"
          [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
          [pageSize]="pageSize">
      </ngb-pagination>
    </div>
  </div>
</div>


<app-add-expense-advance *ngIf="addadvance" [addadvance]="addadvance" [from]="'profileAdvance'" [amountlimitdata]="validationdata" (panelclose)="closefn($event)" (detailout)="detailout($event)" [outstandingamount]="advancedatalist[0]?.outstanding_amount" [currency]="currency">
</app-add-expense-advance>


<div class="side-panel" style="--sidepanel-width:27.375rem;" [class.side-pane-active]='filter === true'>
  <form class="side-panel-container" [formGroup]="filterform" autocomplete="off">
      <header class="side-panel-head">
          <h5>Filters</h5>
          <a class="toggle-panel" (click)="filter=false;"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
              <div class="row row-16 mb-16 ">
                  <div class="col-12 form-row">
                      <label class="form-label ">Select date range</label>
                  </div>
                  <div class="form-icon icon-right vertical-datepicker">
                          <input class="form-control datepicker-vertical" type="text"
                              [locale]="{applyLabel: 'ok', format: dateFormat}" onkeydown="return false" opens="right"
                              ngxDaterangepickerMd [closeOnAutoApply]="true" [showCustomRangeLabel]="true"
                              [alwaysShowCalendars]="false" [linkedCalendars]="true" [showClearButton]="true"
                              placeholder="{{this.messageService.selectplaceholddisplay('Date')}}"
                              formControlName="expense_date__range">
                          <i class="icon-calendar pointer-event"></i>
                  </div>
              </div>
              <div class="col-12 form-row">
                <label class="form-label ">Select status</label>
            </div>
             <ng-select class="form-ngselect" formControlName="status" id="status" placeholder="Select filter">
                <ng-option *ngFor="let statuslist of statuslist" [value]="statuslist">
                  {{statuslist}}
                </ng-option>
              </ng-select>

          </div>

      </div>
      <footer class="side-panel-footer">
          <button type="submit" class="btn btn-outline-primary text-uppercase btn-sm" (click)="resetfn()">
              reset
          </button>
          <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" (click)="applyfilter()">
              Apply
          </button>
      </footer>
  </form>
</div>

<app-advance-outstanding-detail-view *ngIf="viewdetailsout" [viewdetailsout]="viewdetailsout" [currency]="currency" (viewpanelclose)="closeoutstandview($event)"> </app-advance-outstanding-detail-view>

<app-emp-advance-details-view *ngIf="viewAdvance" [viewAdvance]="viewAdvance" [viewdata]="viewdata" [advanceid]="advanceid" [viewLoader]="viewLoader" [currency]="currency" [from]="'expenseAdvanceRequestProfile'"  (panelclose)="closefnview($event)"> </app-emp-advance-details-view>

<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
    [alertMsg]="alertMsg">
</app-alert-toggle>
