<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Leave Type</h3>
      <p class="text-helper mb-md-0">Create multiple leave types as per your organizational needs.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='leavesearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchleavetype($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':status_filter=='' }" (click)="listFilter()">All</button>
            <button ngbDropdownItem [class]="{'active':status_filter=='true' }" (click)="listFilter('true')">Active
            </button>
            <button ngbDropdownItem [class]="{'active':status_filter=='false' }"
              (click)="listFilter('false')">Deleted</button>
          </div>
        </div>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addleaveForm()"
          *ngIf="permissions?.a"><i class="icon-plus"></i>Add Leave Type</button>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
  <div *ngIf="!leave_list_flag && !loader;else all_data " class="row " style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg54.png'" [noDataText]="'Start defining leave types'"
      [noDataPara]="'It seems leave types are not added yet. Click \'Add Leave Type\' to get started.'"
      [hasPermission]="false"></app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <ng-container *ngFor="let leavetype of LeaveTypeList, let i = index">
          <div class="  col-md-3 col-sm-6  ">
            <div class="card card-hover card-custom1 ">
              <div class="card-body p-0 d-flex align-items-start">
                <div class="d-flex view-section" (click)="viewleavetyp(leavetype.id)">
                  <div class="logo-img {{leavetype.color_code}}">
                    {{this.appService.getFirstChar(leavetype.leave_name,2)}}</div>

                  <div class="overflow-hidden ">
                    <h6 class="mb-0 fw-500 text-trim tips">{{leavetype.leave_name}}</h6>
                    <p class="mb-0  tips-helper">{{leavetype.leave_name}}</p>
                    <span
                      class="fs-10 text-uppercase fw-500 {{(leavetype.is_active == true) ? 'text-success' : 'text-danger'}}">{{(leavetype.is_active
                      == true) ? 'Active' : 'Deleted'}}</span>
                  </div>
                </div>
                <div ngbDropdown class="dropdown-section"
                  *ngIf="leavetype.is_active && (permissions?.e || permissions?.d)">
                  <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                    <i class="icon-three-dots-vertical fs-16"></i>
                  </button>
                  <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                    <button *ngIf="permissions?.e" ngbDropdownItem (click)="editleaveForm(leavetype.id)">
                      <i class="icon-pencil-square me-1"></i> Edit
                    </button>
                    <button *ngIf="permissions?.d && !leavetype.default" class="text-danger" ngbDropdownItem
                      (click)="deleteleavetyp(leavetype.id)">
                      <i class="icon-trash  me-1"></i> Delete
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-16" (click)="viewDetail = true">
                <div class="d-flex w-100 ">
                  <div class="text-light-400 fs-10 text-uppercase me-8">leave code</div>
                  <div class=" fs-12 ms-auto">{{leavetype.leave_code}} </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
          <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
      </div> -->
  <app-infinity-loader *ngIf="infinityloader"></app-infinity-loader>


</div>

<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- View loader start -->
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <!-- View loader end -->
        <div class="d-flex mb-16" *ngIf="!loader1">
          <p class="text-uppercase fw-500 mb-0">LEAVE TYPE</p>
          <a class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="editleaveForm(viewleavetypedata.id)"
            *ngIf="viewleavetypedata.is_active==true && (permissions?.e)">
            <i class="icon-pencil-square me-1"></i> Edit
          </a>
        </div>
        <div class="row row-24 mb-24" *ngIf="!loader1">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">LEAVE TYPE DETAILS</span></div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Leave Type Name</div>
                  <div class="detail-desc">{{viewleavetypedata.leave_name}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc">
                    <span class="fs-12 fw-500 text-success text-uppercase"
                      *ngIf="viewleavetypedata.is_active">ACTIVE</span>
                    <span class="fs-12 fw-500 text-danger text-uppercase"
                      *ngIf="viewleavetypedata.is_active==false">DELETED</span>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">Leave Type Code</div>
                  <div class="detail-desc">{{viewleavetypedata.leave_code}}</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">This leave is</div>
                  <div class="detail-desc" *ngIf="!viewleavetypedata.leave_unpaid_paid">Unpaid Leave</div>
                  <div class="detail-desc" *ngIf="viewleavetypedata.leave_unpaid_paid">Paid Leave</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='editDetail === true'>
  <form [formGroup]="leavetypeForm" (ngSubmit)="leavetypFormSubmit()" class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5><span [innerHTML]="header"></span> Leave Type</h5>
      <a class="toggle-panel" (click)="editDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>

    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- Edit loader start -->
        <app-edit-loader *ngIf="loader1"></app-edit-loader>
        <!-- Edit loader end -->
        <ng-container *ngIf="!loader1">
          <p class="text-uppercase fw-500"><span></span> Leave Type DETAILS</p>
          <div class="row row-24 ">
            <div class="col-12 col-md-6 form-row ">
              <label for="name" class="form-label required">Leave Type Code </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter a unique code or short abbreviation for each leave type.
                " popoverClass="popover-default popover-info" placement="bottom" container="body"
                  triggers="hover"></i></span>
              <input type="text" class="form-control" formControlName="leave_code" id="name"
                placeholder="{{this.messageService.placeholderdisp('leave code')}}"
                [ngClass]="{ 'is-invalid': (submitted || f.leave_code.dirty || f.leave_code.touched) && f.leave_code.errors || f.leave_code.errors?.exist}"
                (keyup)="keyUp('leave_code',f.leave_code.value)">
              <div
                *ngIf="(submitted || f.leave_code.dirty || f.leave_code.touched) && f.leave_code.errors || f.leave_code.errors?.exist"
                class="invalid-feedback">
                <div *ngIf="f.leave_code.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.leave_code.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.leave_code.errors?.pattern) && f.leave_code.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','5')}}</div>
                <div *ngIf="!(f.leave_code.errors?.pattern) && !f.leave_code.errors?.required && f.leave_code.hasError('exist')">
                  {{this.messageService.validationDisplay(f.leave_code.value)}}</div>
              </div>
            </div>
            <div class="col-12 col-md-6 form-row ">
              <label for="name" class="form-label required">Leave Type Name </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Enter a name for the leave type.
                " popoverClass="popover-default popover-info" placement="bottom" container="body"
                  triggers="hover"></i></span>
              <input type="text" class="form-control" formControlName="leave_name" id="name"
                placeholder="{{this.messageService.placeholderdisp('Name')}}"
                [ngClass]="{ 'is-invalid': (submitted || f.leave_name.dirty || f.leave_name.touched) && f.leave_name.errors || f.leave_name.errors?.exist}"
                (keyup)="keyUp('leave_name',f.leave_name.value)">
              <div
                *ngIf="(submitted || f.leave_name.dirty || f.leave_name.touched) && f.leave_name.errors || f.leave_name.errors?.exist"
                class="invalid-feedback">
                <div *ngIf="f.leave_name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.leave_name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.leave_name.errors?.pattern) && f.leave_name.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
                <div *ngIf="f.leave_name.hasError('exist')">
                  {{this.messageService.validationDisplay(f.leave_name.value)}}</div>
              </div>
            </div>
            <div class="col-lg-12 form-row ">
              <label for="" class="form-label required">This Leave is </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="Specify if the leave is paid leave or unpaid leave
                " popoverClass="popover-default popover-info" placement="bottom" container="body"
                  triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mt-0">
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input formControlName="leave_unpaid_paid" class="form-check-input" type="radio" [value]=true
                    id="leave_paid">
                  Paid Leave
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input formControlName="leave_unpaid_paid" class="form-check-input" type="radio" [value]=false
                    id="leave_unpaid">
                  Unpaid Leave
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer gap-32" *ngIf="!loader1">
      <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"
        *ngIf="deleteClass==true && permissions?.d" (click)="deleteToggle = true" [disabled]='disabled'>Delete</button>
      <button class="ms-auto btn btn-primary text-uppercase btn-sm" *ngIf="permissions?.a ||  permissions?.e"
        [disabled]='disabled || !leavetypeForm.valid'><span [innerHTML]="'Save'"></span></button>
    </footer>
  </form>
</div>
<!-- delete model  -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Leave Type?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Leave Type')}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase fw-500"
          (click)="deleteToggle = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase fw-500" [disabled]="deleteClicked"
          (click)="confirmDelete()">Delete</button>
      </div>
    </div>
  </div>
</div>
