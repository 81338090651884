<div class="container-fluid p-24" *ngIf="permissions?.v">
  <div class="row row-16">
    <div class="col-12 d-flex  align-items-start">
      <div class="vstack">
        <h3>Leave Balance</h3>
        <p class="text-helper mb-0 ">
          Check your leave balances across all leave types, and also access your leave transactions.
        </p>
      </div>
      <ng-select class="form-ngselect ms-auto" style="width:9.5rem"
        placeholder="{{this.messageService.selectplaceholddisplay('year')}}" [(ngModel)]="year"
        (change)="addEvent($event)">
        <ng-option *ngFor="let yData of yearRange" [value]="yData">{{yData}}</ng-option>
      </ng-select>
    </div>
    <div class="col-12">
      <div class="card card-c2 pt-16 pb-8 px-24">
        <div class="row ">
          <div class="col-md-4 col-lg-6">
            <p class="mb-0 fw-500">Your leave balance</p>
          </div>
          <div class="col-md-8 col-lg-6 ">
            <div class="d-flex justify-content-end  gap-16">
              <div class="form-icon" style="width:22.6875rem;">
                <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='searchplaceholder' [isLoading]="sLoading"
                  (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchshift($event)"></app-searchbar>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-16">
          <ng-container *ngIf="loader">
            <app-common-loader class="col-md-4 col-lg-4 col-sm-6" [cardType]="'card14'"
              *ngFor="let dummy of ' '.repeat(3).split(''), let x = index"></app-common-loader>
          </ng-container>
          <ng-container *ngIf="!loader">
            <!-- No data start -->
            <div class="col-12 " *ngIf="leaveData?.length == 0">
              <div class="row">
                <app-nodata-view [imgClass]="'img-sm'"></app-nodata-view>
              </div>
            </div>
            <!-- No data end -->
            <div class="col-12">
              <ng-container *ngIf="leaveData?.length > 0 ">
                <ngu-carousel class="custom-carousel" #myCarousel [inputs]="leaveBalanceTiles" [dataSource]="leaveData">
                  <ngu-tile *nguCarouselDef="let item; let i = index">
                    <div class="card card-c2 ">
                      <div class="card-body indicater {{item?.color_code}} p-16 d-flex align-items-center">
                        <div class="fw-500 text-trim pe-16">{{item?.leave_type}}</div>
                        <div class="ms-auto fs-12 fw-500 text-nowrap "> Available :
                          {{item?.available_leave}}/{{item?.total}}</div>
                      </div>
                      <div class="card-body bg-dark-400 py-12 px-16 overflow-hidden">
                        <div class="row  row-16 ">
                          <div class="col-6  ">
                            <div class="text-light-400 fs-12 text-trim ">Accrued this year</div>
                            <div class="fw-500 fs-14">{{item?.accrual}}</div>
                          </div>
                          <div class="col-6 ">
                            <div class="text-light-400 fs-12 text-trim ">Credited from last year</div>
                            <div class="fw-500 fs-14">{{item?.carry_forward}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ngu-tile>
                  <!-- <ng-container *ngIf="leaveData?.length > 1 "> -->
                  <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel?.isFirst ? 0:1">
                    <i class="icon-chevron-left-lg"></i>
                  </button>
                  <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel?.isLast ? 0:1">
                    <i class="icon-chevron-right-lg"></i>
                  </button>
                  <!-- </ng-container> -->
                </ngu-carousel>
              </ng-container>
              <!-- <ng-container *ngIf="leaveData.length > 0 ">
                  <ng-container *ngFor="let item of leaveData; let i = index">
                    <div class="col-md-4 col-lg-4 col-sm-6 ">
                      <div class="card card-c2 ">
                        <div class="card-body indicater {{item?.color_code}} p-16 d-flex align-items-center">
                          <div class="fw-500 text-trim pe-16">{{item?.leave_type}}</div>
                          <div class="ms-auto fs-12 fw-500 text-nowrap "> Available :
                            {{item?.available_leave}}/{{item?.total}}</div>
                        </div>
                        <div class="card-body bg-dark-400 py-12 px-16 overflow-hidden">
                          <div class="row  row-16 ">
                            <div class="col-6  ">
                              <div class="text-light-400 fs-12 text-trim text-uppercase">Accrued this year</div>
                              <div class="fw-500 fs-14">{{item?.accrual}}</div>
                            </div>
                            <div class="col-6 ">
                              <div class="text-light-400 fs-12 text-trim text-uppercase">Credited from last year</div>
                              <div class="fw-500 fs-14">{{item?.carry_forward}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container> -->
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="col-md-6">
        <div class="card card-c2 p-24 gap-16 h-100" style="max-height: 17.8rem;">
          <div class="d-flex">
            <p class="mb-0 fw-500">Time off trend - {{year}}</p>
            <div class="form-row ms-auto" style="width:168px">
              <ng-select class="form-ngselect"
                placeholder="{{this.messageService.selectplaceholddisplay('leave type')}}"
                [(ngModel)]="selectedLeaveTypes" (change)="leaveTypeChanged()">
                <ng-option *ngFor="let data of leaveTypes" [value]="data?.id">{{data?.leave_name}}</ng-option>
              </ng-select>
            </div>
          </div>
          <ng-container *ngIf="!trendLoader && trend?.total != 0">
            <!-- <ngx-charts-advanced-pie-chart [scheme]="colorScheme" [results]="single" [gradient]="gradient" (select)="onSelect($event)" [animations]="false">
              </ngx-charts-advanced-pie-chart> -->
            <app-doughnut-chart [totalCenter]="false" [labelFontSize]="'1rem'" [height]="'11.25rem'" [radius]="60"
              [color]="graph_colorScheme" [data]="graphdata" [labels]="graphlabels" [id]="0" [showRupee]="false"
              [total]="'Total'">
            </app-doughnut-chart>
          </ng-container>
          <app-home-loaders *ngIf="trendLoader" [loader]="'loader_trends'"></app-home-loaders>
          <!-- No data start -->
          <ng-container *ngIf="!trendLoader && (trend?.total == 0 || trend?.length == 0)">
            <div class="col-12 ">
              <div class="row">
                <app-nodata-view [imgClass]="'img-sm'"></app-nodata-view>
              </div>
            </div>
          </ng-container>
          <!-- No data end -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-c2 p-24 h-100" style="overflow-y: auto;max-height: 17.8rem;">
          <div class="row row-16 {{(upcoming.length == 0)?'h-100':''}}">
            <div class="col-12">
              <p class="mb-0 fw-500">Upcoming leave</p>
            </div>
            <div class="row row-16" *ngIf="upcomingLoader">
              <div class="col-12 mt-25">
                <ng-container *ngFor="let dummy of ' '.repeat(1).split(''), let x = index">
                  <div class="mt-15">
                    <ngx-skeleton-loader appearance="line"
                      [theme]="{'background-color': '#EAEDEF','height': '74px','width': '100%' }"></ngx-skeleton-loader>
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="!upcomingLoader">
              <!-- No data start -->
              <div class="col-12 " *ngIf="upcoming.length == 0">
                  <div class="row">
                    <app-nodata-view [imgClass]="'img-sm'"></app-nodata-view>
                  </div>
              </div>
              <!-- No data end -->
              <ng-container *ngIf="upcoming.length > 0">
                <ng-container *ngFor="let upcomingleave of upcoming">
                  <div class="col-12">
                    <div class="card card-c2 flex-row p-16 bg-dark-500">
                      <div class="avatar-circle sq-40 bg8 text-accent2 me-16"><i class="icon-handwave fs-20"></i></div>
                      <p class="fs-14" *ngIf="datecheck(upcomingleave?.from_date,upcomingleave?.to_date)!='same' && upcomingleave?.first_half_day_list.length != 0"><span class="fw-500">{{upcomingleave?.leave_type}}</span> for
                        {{upcomingleave?.first_half_day_list[0]?(createarrayDisplay(upcomingleave?.first_half_day_list)+' (half-day, first half)'):''}}{{upcomingleave?.full_day_list[0]?(' , '+createarrayDisplay(upcomingleave?.full_day_list)+' (full day)'):''}}{{upcomingleave?.second_half_day_list[0]?(' , '+createarrayDisplay(upcomingleave?.second_half_day_list)+' (half-day, second half)'):''}} {{upcomingleave?.status |
                        titlecase
                        }}
                        by
                        <span class="fw-500">{{upcomingleave?.approved_by}}</span>
                      </p>
                      <p class="fs-14" *ngIf="datecheck(upcomingleave?.from_date,upcomingleave?.to_date)!='same' && upcomingleave?.first_half_day_list.length == 0"><span class="fw-500">{{upcomingleave?.leave_type}}</span> for {{upcomingleave?.full_day_list[0]?(createarrayDisplay(upcomingleave?.full_day_list)+' (full day)'):''}}{{upcomingleave?.second_half_day_list[0]?(' , '+createarrayDisplay(upcomingleave?.second_half_day_list)+' (half-day, second half)'):''}} {{upcomingleave?.status |
                        titlecase
                        }}
                        by
                        <span class="fw-500">{{upcomingleave?.approved_by}}</span>
                      </p>

                      <p class="fs-14" *ngIf="datecheck(upcomingleave?.from_date,upcomingleave?.to_date)=='same' && upcomingleave?.first_half_day_list.length==0 && upcomingleave?.second_half_day_list.length==0"><span class="fw-500" >{{upcomingleave?.leave_type}}</span> from
                        {{this.appService.dateFormatDisplay(upcomingleave?.from_date)}} -
                        {{this.appService.dateFormatDisplay(upcomingleave?.to_date)}} (full day) {{upcomingleave?.status |
                        titlecase
                        }}
                        by
                        <span class="fw-500">{{upcomingleave?.approved_by}}</span>
                      </p>
                      <p class="fs-14" *ngIf="datecheck(upcomingleave?.from_date,upcomingleave?.to_date)=='same' && upcomingleave?.first_half_day_list.length!=0 "><span class="fw-500">{{upcomingleave?.leave_type}}</span> from
                        {{this.appService.dateFormatDisplay(upcomingleave?.from_date)}} -
                        {{this.appService.dateFormatDisplay(upcomingleave?.to_date)}} (half-day, first half) {{upcomingleave?.status |
                        titlecase
                        }}
                        by
                        <span class="fw-500">{{upcomingleave?.approved_by}}</span>
                      </p>
                      <p class="fs-14" *ngIf="datecheck(upcomingleave?.from_date,upcomingleave?.to_date)=='same' && upcomingleave?.second_half_day_list.length!=0"><span class="fw-500">{{upcomingleave?.leave_type}}</span> from
                        {{this.appService.dateFormatDisplay(upcomingleave?.from_date)}} -
                        {{this.appService.dateFormatDisplay(upcomingleave?.to_date)}} (half-day, second half) {{upcomingleave?.status |
                        titlecase
                        }}
                        by
                        <span class="fw-500">{{upcomingleave?.approved_by}}</span>
                      </p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-12">
      <div class="card card-c2 p-24">
        <div class="row mb-16">
          <div class="col-md-4 col-lg-6">
            <p class="mb-0 fw-500">Leave data</p>
          </div>
          <div class="col-md-8 col-lg-6">
            <div class="d-flex justify-content-end gap-16">
              <div class="form-icon2" style="width:22.6875rem;">
                <i class="icon-search icon-left fs-14"></i>
                <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
                  (click)="searchString=''"></div>
                <input type="text" class="form-control fs-14" name=""
                  placeholder="{{this.messageService.searchdisplay('')}}" id="" [(ngModel)]="searchString"
                  (keyup)="page=1;">
              </div>
              <div ngbDropdown class="d-inline-block">
                <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                  <i class="icon-filter-left fs-13"></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-end dropdown-scroll">
                  <button ngbDropdownItem *ngFor="let filter of filterData" (click)="filterFn(filter)"
                    [class]="{'active':leaveStatus == filter }">{{filter}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-24 ">
          <app-table-loader *ngIf="loadertbl"></app-table-loader>
          <ng-container *ngIf="loadertbl==false">
            <app-view-leave-balance [leaveBalanceTable]="leaveBalanceTable" [searchString]="searchString"
              [isLeave]="true" (view)="viewComp($event)"></app-view-leave-balance>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="viewpanel == true">
  <!-- <div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='viewpanel === true'>
    <div class="side-panel-container">
      <header class="side-panel-head">
        <h5>View</h5>
        <a class="toggle-panel" (click)="viewpanel = false"><i class="icon-close-lg"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <app-c1-loader *ngIf="loader1"></app-c1-loader>
        <div class="container-fluid p-32" *ngIf="!loader1"> -->
  <app-view-leave-request [leaveRequestData]="leaveview" [from]="'myleave'" [requestId]="requestId"
    [leaveRequestView]="viewpanel" (leaveViewClose)="leaveViewClose($event)" [loader1]="loader1"
    (closeViewPage)="closeViewPage($event)"></app-view-leave-request>
  <!-- </div>
      </div>
    </div>
  </div> -->
</ng-container>
