<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row row-16 mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Attendance Dashboard</h3>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">

        <div class="ms-auto" style="width:18.75rem;">
          <ng-select #ngSelecter class="form-ngselect fs-14" placeholder="Select Company"
              (change)="companySelected()" [(ngModel)]="company" [items]="companyList"
              bindLabel="company_name" bindValue="id" [ngClass]="{ 'is-invalid':(company==null || company=='') && !loader }" [clearOnBackspace]="false">
              <ng-template ng-label-tmp let-item="item" let-index="index">
                  <div class="d-inline-flex  align-items-center">
                      <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                          *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                      <span
                          *ngIf="(item.logo ==  ''|| item.logo == null) && item.company_name != '' && item.company_name != null"
                          class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                          <span>{{this.appService.getFirstChar(item.company_name,1)}}</span>
                      </span>
                      {{item.company_name}}
                  </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="d-inline-flex  align-items-center">
                      <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                          *ngIf="item.logo !=  '' && item.logo != null" src="{{item.logo}}">
                      <span *ngIf="item.logo ==  ''|| item.logo == null"
                          class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}">
                          <span>
                              {{this.appService.getFirstChar(item.company_name,1)}}</span>
                      </span>
                      {{item.company_name}}
                  </div>
              </ng-template>
          </ng-select>
          <div *ngIf="(company==null || company=='') && !loader" class="invalid-feedback">
            <div *ngIf="(company==null || company=='') && !loader">{{this.messageService.validationDisplay('required')}}</div>
          </div>
        </div>

        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add"  routerLink="/run-payroll/attendance-&-lop/{{selectedyear}}/{{selectedmonth}}/{{company}}" *ngIf="!nodataFound && !loader">Process Attendance</button>
      </div>
    </div>

    <div *ngIf=" nodataFound " class="row" style="height: calc(100vh - 9rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg25.png'" [noDataText]="'Start building your ideal attendance policy!'"
        [noDataPara]="'The attendance settings are waiting to be configured! Check back here after completing the relevant configurations.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>

    <div class="col-12" *ngIf="!nodataFound">
      <div class="card  px-24 card-c2"  *ngIf="!loader">
        <div class="card-body p-0"
       >
        <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselTileItems"
            (carouselLoad)="myCarousel?.moveTo(movetoPoint,false);">
            <ngu-tile *nguCarouselDef="let item; let i = index" (click)="clickCarousel(item)">
                <div
                    class="card card-c3 {{ item.status }} {{(item.state == 'completed') ? 'success' : ''}}">
                    <div class="card-body py-8 date">
                        <div class="fs-14 fw-500">{{ item.month }}</div>
                    </div>
                    <div class="card-body status current py-0">
                        <div class="fs-10 fw-500 text-uppercase ">
                            {{ item.state }}
                        </div>
                    </div>
                </div>
            </ngu-tile>
              <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0.5 : 1">
                  <i class="icon-chevron-left-lg fs-11"></i>
              </button>
              <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0.5 : 1">
                  <i class="icon-chevron-right-lg fs-11"></i>
              </button>
        </ngu-carousel>
        </div>
        <ng-container *ngIf="!loadersub">
        <div class="card-body px-0 py-16">
          <div class="row row-16">
            <div class="col-lg-8">
              <div class="card-c2 card p-16">
                <div class="row row-16">
                  <div class="col-12">
                    <div class="fw-500">Attendance Summary</div>
                  </div>
                  <div class="col-12">
                    <div class="card card-c2 card-highlight highlight-primary">
                      <div class="card-body px-24 py-12 ">
                        <div class="d-flex gap-12 justify-content-between">
                        <ng-container *ngFor="let item of summerydata?.attendance_summary_data; let i = index">
                          <div class="">
                            <div class="fs-24 mb-6 lh-1">{{item?.sub_header_value}} {{(item?.sub_header=='AVG WORK HOUR')?'Hours':'%'}}</div>
                            <div class="fs-10 fw-500 text-uppercase text-light-400  ">{{item?.sub_header}}</div>
                            <div class="fs-10 fw-500 text-uppercase ">
                              <span class="text-light-400 ">from {{getPreMonth()}}</span>
                              <span class="ms-8 {{(item?.last_month_percentage_status == 'positive' ) ? ' graph-increase text-success' :' graph-decrease text-danger' }}">{{item?.last_month_percentage_change}} %</span>
                            </div>
                          </div>
                          <div class="vr" *ngIf="i<3"></div>
                        </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-c2 p-16">
                <div class="row row-16">
                  <div class="col-12">
                    <div class="fw-500">Alerts & Reminders</div>
                  </div>
                  <div class="col-6">
                    <div class="card-c2 card bg5 px-16 py-8 pointer" routerLink="/attendance-alert/alerts/{{selectedyear}}/{{selectedmonth}}/{{company}}" >
                      <div class="d-flex align-items-center">
                        <i class="bi bi-exclamation-triangle-fill me-8  fs-20 text-danger"></i>
                        <div class="fs-12 fw-500 text-uppercase  ">ALERTS</div>
                      </div>
                      <div class="lh-sm fs-32 fw-500 " [countUp]="summerydata?.alert_reminders?.alert_count"> 0</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card-c2 card bg7 px-16 py-8 pointer" routerLink="/attendance-reminder/reminders/{{selectedyear}}/{{selectedmonth}}/{{company}}">
                      <div class="d-flex align-items-center">
                        <i class="bi bi-bell-fill me-8  fs-20 text-warning"></i>
                        <div class="fs-12  fw-500 text-uppercase  ">REMINDERS</div>
                      </div>
                      <div [countUp]="summerydata?.alert_reminders?.reminders_count" class="lh-sm fs-32 fw-500 ">0 </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="card card-c2 p-24">
                <div class="fw-500 mb-24 lh-1">{{graphselect}}</div>
                <div class="row row-16  ">
                  <div class="col-12">
                    <div class="card-c2 card bg14 px-16 py-12 h-100">
                      <div class="d-flex align-items-center">
                        <i class="icon-calendar-fill me-8  fs-16 text-accent1"></i>
                        <div class="fs-12 fw-500 text-uppercase  ">calendar
                          days
                        </div>
                      </div>
                      <div class="mt-8 lh-sm fs-32 fw-500 " [countUp]="summerydata?.monthly_data?.calendar_days">0</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card-c2 card bg8 px-16 py-12 h-100">
                      <div class="d-flex align-items-center">
                        <i class="icon-cash-stack-fill me-8  fs-16 text-primary"></i>
                        <div class="fs-12 fw-500 text-uppercase  ">Attendance processed
                        </div>
                      </div>
                      <div class="lh-sm mt-8 fs-32 fw-500 "> <span [countUp]="summerydata?.monthly_data?.current_month_processed_employee_count">0</span>/{{summerydata?.monthly_data?.employee_count}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card-c2 card bg7 px-16 py-12 h-100">
                      <div class="d-flex align-items-center">
                        <i class="icon-people-fill me-8  fs-16 text-warning"></i>
                        <div class="fs-12  fw-500 text-uppercase  ">
                          Employees</div>
                      </div>
                      <div [countUp]="summerydata?.monthly_data?.employee_count" class="lh-sm mt-8 fs-32 fw-500 ">0</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="card card-c2 p-24 h-100">
                <div class="row row-16">
                  <div class="col-12 d-flex">
                    <div class="fw-500">Work Hours Summary</div>
                      <ng-select class="form-ngselect ms-auto h-36 fs-14"
                      placeholder="{{this.messageService.selectplaceholddisplay('month')}}"
                      [(ngModel)]="graphselect" (change)="getgraphdata('graph')">
                         <ng-option *ngFor="let data of graphyear" [value]="data">{{data}}</ng-option>
                     </ng-select>
                  </div>
                    <div class="col-12" *ngIf="!loader  && !nodataFound && graphloader">
                      <app-graph-loader></app-graph-loader>
                    </div>
                    <div class="col-12" *ngIf="!nographdata && !graphloader">
                      <app-line-chart style="max-height: 19rem;" [pin]="true" [labels]="graphLabels" [data]="graphvalues" [fill]="false" [type]="'AVG Work Hours is'" [bgColor]="'#ffffff'" [xtitle]="'AVG. Work hours'" [ytitle]="'Days in '+ graphselect"></app-line-chart>
                    </div>
                    <div class="col-12 " *ngIf="nographdata && !graphloader">
                        <div class="flex-center h-100 d-flex flex-column gap-20">
                            <img src="assets\images\bg\line-art1.svg" class="img-fluid">
                            <p class="fs-14 fw-500">No Attendance summary found</p>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 p-24 h-100">
                <div class="row row-16">
                  <div class="col-12 d-flex">
                    <div class="fw-500">Attendance Details For {{this.appService.dateFormatDisplay(selecteddate)}}</div>
                    <div class="input-group form-icon icon-right ms-auto"  style="width:10.25rem;">
                      <input (click)="datePicker1.open()" placeholder="{{this.messageService.selectplaceholddisplay('date')}}" class="form-control "
                      matInput [matDatepickerFilter]="dateFilter" [(ngModel)]="selecteddate"
                      (dateInput)="dateEvent($event)"
                      [matDatepicker]="datePicker1" (keydown)="disableDate()"><i
                      class="icon-calendar pointer h-36 fs-14" (click)="datePicker1.open()"></i>
                        <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
                    </div>
                  </div>
                  <ng-container *ngIf="!attendanceloader">
                  <div class="col-lg-3 col-md-4">
                    <div class="card card-c2 h-100 overflow-auto" style="max-height:28.5rem">
                      <div class="p-16 fw-500 position-sticky top-0">On Time ({{attendancedata?.on_time_employee_data?.length}})</div>
                      <div class="card card-c2 px-16 border-0  " *ngIf="attendancedata?.on_time_employee_data?.length!=0">
                        <ng-container *ngFor="let item of attendancedata?.on_time_employee_data; let i = index">
                        <div class="card-body py-8 px-0 d-flex gap-12 align-items-center">
                          <img alt="" class="img-fluid  avatar-circle sq-32"
                            src="{{item?.profile_image}}" *ngIf="item?.profile_image!='' && item?.profile_image!=null">
                            <span class="avatar-circle fs-14 fw-500 rounded-circle sq-32 {{item?.color_code}}" *ngIf="item?.profile_image=='' || item?.profile_image==null">{{this.appService.getFirstChar(item?.user__first_name+' '+item?.user__last_name,2)}}</span>
                          <div class="d-flex flex-column gap-1 overflow-hidden">
                            <div class="fw-500 fs-12 text-trim">{{item?.user__first_name}} {{(item?.middle_name)?item?.middle_name:''}} {{item?.user__last_name}} </div>
                            <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              {{item?.designation__name}} | {{item?.employee_code}}</div>
                            <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              Login : {{(this.appService.timeFormatDisplay(item?.first_punch_in))?(this.appService.timeFormatDisplay(item?.first_punch_in)):'--'}}</div>
                          </div>
                        </div>
                       </ng-container>
                      </div>
                      <div class="card card-c2 px-16 border-0 " *ngIf="attendancedata?.on_time_employee_data?.length==0">
                        <div class="row row-16 h-100">
                          <div class="col-12 text-center">
                            <div class="flex-center flex-column h-100">
                              <img class="img-fluid mx-auto " src="/assets/images/helper/helper3.png">
                              <p class="text-light-400 fs-14 mt-3">No data to show</p>
                            </div>
                          </div>
                        </div>
                       </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4">
                    <div class="card card-c2 h-100 overflow-auto" style="max-height:28.5rem">
                      <div class="p-16 fw-500 position-sticky top-0">Late Arrival ({{attendancedata?.late_arrival_employee_data?.length}})</div>
                      <div class="card card-c2 px-16 border-0  " *ngIf="attendancedata?.late_arrival_employee_data?.length!=0">
                        <ng-container *ngFor="let item of attendancedata?.late_arrival_employee_data; let i = index">
                        <div class="card-body py-8 px-0 d-flex gap-12 align-items-center">
                          <img alt="" class="img-fluid  avatar-circle sq-32"
                          src="{{item?.profile_image}}" *ngIf="item?.profile_image!='' && item?.profile_image!=null">
                          <span class="avatar-circle fs-14 fw-500 rounded-circle sq-32 {{item?.color_code}}" *ngIf="item?.profile_image=='' || item?.profile_image==null">{{this.appService.getFirstChar(item?.user__first_name+' '+item?.user__last_name,2)}}</span>
                          <div class="d-flex flex-column gap-1 overflow-hidden">
                            <div class="fw-500 fs-12 text-trim">{{item?.user__first_name}} {{(item?.middle_name)?item?.middle_name:''}} {{item?.user__last_name}} </div>
                            <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              {{item?.designation__name}} | {{item?.employee_code}}</div>
                            <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              Login : {{this.appService.timeFormatDisplay(item?.first_punch_in)}}</div>
                          </div>
                        </div>
                       </ng-container>
                      </div>
                      <div class="card card-c2 px-16 border-0 " *ngIf="attendancedata?.late_arrival_employee_data?.length==0">
                        <div class="row row-16 h-100">
                          <div class="col-12 text-center">
                            <div class="flex-center flex-column h-100">
                              <img class="img-fluid mx-auto " src="/assets/images/helper/helper3.png">
                              <p class="text-light-400 fs-14 mt-3">No data to show</p>
                            </div>
                          </div>
                        </div>
                       </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4">
                    <div class="card card-c2 h-100 overflow-auto" style="max-height:28.5rem">
                      <div class="p-16 fw-500 position-sticky top-0 z-1 bg-white">WFH ({{attendancedata?.wfh_employee_data?.length}})</div>
                      <div class="card card-c2 px-16 border-0 " *ngIf="attendancedata?.wfh_employee_data?.length!=0">
                        <ng-container *ngFor="let item of attendancedata?.wfh_employee_data; let i = index">
                        <div class="card-body py-8 px-0 d-flex gap-12 align-items-center">
                          <img alt="" class="img-fluid  avatar-circle sq-32"
                          src="{{item?.profile_image}}" *ngIf="item?.profile_image!='' && item?.profile_image!=null">
                          <span class="avatar-circle fs-14 fw-500 rounded-circle sq-32 {{item?.color_code}}" *ngIf="item?.profile_image=='' || item?.profile_image==null">{{this.appService.getFirstChar(item?.user__first_name+' '+item?.user__last_name,2)}}</span>
                          <div class="d-flex flex-column gap-1 overflow-hidden">
                            <div class="fw-500 fs-12 text-trim">{{item?.user__first_name}} {{(item?.middle_name)?item?.middle_name:''}} {{item?.user__last_name}} </div>
                            <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              {{item?.designation__name}} | {{item?.employee_code}}</div>
                            <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              Login : {{this.appService.timeFormatDisplay(item?.first_punch_in)}}</div>
                          </div>
                        </div>
                       </ng-container>
                      </div>
                      <div class="card card-c2 px-16 border-0 " *ngIf="attendancedata?.wfh_employee_data?.length==0">
                        <div class="row row-16 h-100">
                          <div class="col-12 text-center">
                            <div class="flex-center flex-column h-100">
                              <img class="img-fluid mx-auto " src="/assets/images/helper/helper3.png">
                              <p class="text-light-400 fs-14 mt-3">No data to show</p>
                            </div>
                          </div>
                        </div>
                       </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4">
                    <div class="card card-c2 h-100 overflow-auto" style="max-height:28.5rem">
                      <div class="p-16 fw-500 position-sticky top-0">On Leave ({{attendancedata?.leave_employee_data?.length}})</div>
                      <div class="card card-c2 px-16 border-0 " *ngIf="attendancedata?.leave_employee_data?.length!=0">
                        <ng-container *ngFor="let item of attendancedata?.leave_employee_data; let i = index">
                        <div class="card-body py-8 px-0 d-flex gap-12 align-items-center">
                          <img alt="" class="img-fluid  avatar-circle sq-32"
                            src="{{item?.profile_image}}" *ngIf="item?.profile_image!='' && item?.profile_image!=null">
                            <span class="avatar-circle fs-14 fw-500 rounded-circle sq-32 {{item?.color_code}}" *ngIf="item?.profile_image=='' || item?.profile_image==null">{{this.appService.getFirstChar(item?.user__first_name+' '+item?.user__last_name,2)}}</span>
                          <div class="d-flex flex-column gap-1 overflow-hidden">
                            <div class="fw-500 fs-12 text-trim">{{item?.user__first_name}} {{(item?.middle_name)?item?.middle_name:''}} {{item?.user__last_name}} </div>
                            <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              {{item?.designation__name}} | {{item?.employee_code}}</div>
                            <!-- <div class="fw-500 fs-10 text-uppercase text-light-400 text-trim">
                              Login : {{this.appService.timeFormatDisplay(item?.first_punch_in)}}</div> -->
                          </div>
                        </div>
                       </ng-container>
                      </div>
                      <div class="card card-c2 px-16 border-0 " *ngIf="attendancedata?.leave_employee_data?.length==0">
                        <div class="row row-16 h-100">
                          <div class="col-12 text-center">
                            <div class="flex-center flex-column h-100">
                              <img class="img-fluid mx-auto " src="/assets/images/helper/helper3.png">
                              <p class="text-light-400 fs-14 mt-3">No data to show</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  </ng-container>
                  <div *ngIf="attendanceloader" class="col-12">
                      <ngx-skeleton-loader appearance="circle"
                      [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}">
                      </ngx-skeleton-loader>
                      <ngx-skeleton-loader appearance="circle"
                      [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}">
                      </ngx-skeleton-loader>
                      <ngx-skeleton-loader appearance="circle"
                      [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}">
                      </ngx-skeleton-loader>
                      <ngx-skeleton-loader appearance="circle"
                      [theme]="{ 'border-radius': '0.5rem',height: '7rem',width: '17rem','background-color': '#EAEDEF'}">
                       </ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        </ng-container>
        <app-c1-loader *ngIf="loadersub"></app-c1-loader>
      </div>
      <app-c1-loader *ngIf="loader"></app-c1-loader>
    </div>

  </div>
</div>
