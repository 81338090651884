<nav class="nav nav-custom sticky ">
  <a class="nav-link" [ngClass]="{ 'active': currentStatus}" (click)="salaryFilter(true)"> Assigned </a>
  <a class="nav-link" [ngClass]="{ 'active': !currentStatus}" (click)="salaryFilter(false)">Not assigned </a>

</nav>
<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4">
      <h3>Salary Structure</h3>
    </div>
    <div class="col-md-8">
      <div class="d-flex justify-content-end gap-16">
        <div style="max-width: 22.6875rem;width: 100%;">
          <div class="">
            <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="searchkey"  [isLoading]="sLoading"
              (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchsalary($event)"></app-searchbar>
          </div>
        </div>

        <button class=" btn-square btn btn-secondary" (click)="filterclick()">
          <i class="icon-filter-left fs-13"></i>
        </button>



      </div>
    </div>
  </div>
  <div *ngIf="nodataFound && !loader;else all_data" class="row " style="height: calc(100vh - 12rem);">
    <app-nodata-view *ngIf="currentStatus" class="vstack" [noDataImg]="'bg18.png'"
      [noDataText]="'Let’s see who has an assigned salary structure'"
      [noDataPara]="'It seems no employees have been assigned a salary structure yet. Once you assign salary structures, they will appear in here.'"
      [hasPermission]="false">
    </app-nodata-view>
    <app-nodata-view *ngIf="!currentStatus" class="vstack" [noDataImg]="'bg18.png'"
      [noDataText]="'Oops! no employees awaiting salary assignment'"
      [noDataPara]="'Add new employees to start assigning salaries.'" [hasPermission]="false">
    </app-nodata-view>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <ng-template #all_data>
    <div *ngIf="permissions" class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let salary of salaryData;let ind = index">
          <div class="card card-c2 card-hover px-16" (click)="salaryUpdate(salary.id)">
            <div class="card-body pb-8 px-0">
              <div class="d-flex gap-16">
                <img *ngIf="salary.employee_data.profile_image !=  null && salary.employee_data.profile_image !=  ''" alt=""
                  class="avatar-circle avatar-border sq-45" src="{{salary.employee_data.profile_image}}" />
                <div *ngIf="salary.employee_data.profile_image ==  '' || salary.employee_data.profile_image ==  null"
                  class="avatar-circle avatar-border fs-14 fw-500 {{salary.employee_data.color_code}}  sq-45">
                  {{this.appService.getFirstChar(salary.employee_data.first_name+ " "+salary.employee_data.last_name,2)}}</div>
                <div class="vstack text-trim">
                  <p class="mb-0 fs-12 fw-500 lh-base text-trim">{{salary.employee_data.first_name+"
                    "+(salary.employee_data.middle_name!=null?salary.employee_data.middle_name:" ")+ "
                    "+salary.employee_data.last_name}}</p>
                  <p class="mb-0 fs-10 fw-500 lh-base text-trim text-light-500 hstack gap-4">
                    <span>{{salary.employee_data.employee_code}}</span>|
                    <span>{{salary.employee_data.designation_name}}</span>
                  </p>
                  <span class="text-uppercase fs-10 fw-500
                                              {{(salary.employee_data.employment_status == 'Confirmed') ? 'text-success' :
                                            (salary.employee_data.employment_status == 'Probation') ? 'text-accent1':
                                            (salary.employee_data.employment_status == 'Relieved') ? 'text25':
                                            (salary.employee_data.employment_status == 'Resigned') ? 'text7':
                                            (salary.employee_data.employment_status == 'Settled') ? 'text23':
                                            (salary.employee_data.employment_status == 'Retired') ? 'text24':
                                            (salary.employee_data.employment_status == 'Terminated') ? 'text-danger': ' '}}
                                            ">{{salary.employee_data.employment_status}}</span>
                </div>
              </div>
            </div>
            <div class="card-body py-12 px-0  d-flex fs-12 align-items-center"
              *ngIf="!currentStatus && salary.accepted_by.length>0">
              <div class="text-light-400 me-8 text-muted fs-12 text-nowrap">Pending with</div>

              <div class="d-flex align-items-center overlap-avatar overlap-right">
                <ng-container *ngFor="let aacpt of salary.accepted_by,let i = index">

                  <img class="avatar-sm sq-32 avatar-circle"
                    *ngIf="i < 3 && aacpt.profile_image !=  '' && aacpt.profile_image !=  null" src="{{aacpt.profile_image}}"
                    [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover" container="body">

                  <div class="avatar-sm sq-32 avatar-circle {{aacpt.color_code}}"
                    *ngIf="i < 3 && (aacpt.profile_image ==  ''|| aacpt.profile_image ==  null)" [ngbPopover]="popContentSingle"
                    popoverClass="user-list single-user" triggers="hover" container="body">
                    <span>{{this.appService.getFirstChar(aacpt?.first_name+"
                      "+aacpt?.last_name,2)}}</span>
                  </div>
                  <ng-template #popContentSingle>
                    <div class="user-details">
                      <span class="user-label">{{aacpt?.first_name+'
                        '+(aacpt?.middle_name!=null?aacpt?.middle_name:'')+' '+aacpt?.last_name}}</span>
                      <span class="user-code">EMP CODE: {{aacpt?.employee_code}} </span>
                    </div>
                  </ng-template>
                  <div *ngIf="i==3" class="avatar-sm sq-32 avatar-circle" [ngbPopover]="popContentMultilpe"
                    popoverClass="user-list multi-user" triggers="click" container="body">
                    <span>+{{this.appService.subEmp(salary.accepted_by.length)}}</span>
                  </div>

                </ng-container>
                <ng-template #popContentMultilpe>
                  <ul>
                    <ng-container *ngFor="let re of salary.accepted_by,let i = index">
                      <ng-container *ngIf="i >= 3">
                        <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                          <img class="user-avatar" src="{{re.profile_image}}">
                          <div class="user-details">
                            <span class="user-label">{{re.first_name+' '+(re.middle_name!=null?re.middle_name:'')+'
                              '+re.last_name}}</span>
                            <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                          </div>
                        </li>
                        <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                          <span class="user-avatar {{re.color_code}}">{{this.appService.getFirstChar(re.first_name+"
                            "+re.last_name,2)}}</span>
                          <div class="user-details">
                            <span class="user-label">{{re.first_name+' '+(re.middle_name!=null?re.middle_name:'')+'
                              '+re.last_name}}</span>
                            <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                          </div>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </ng-template>
              </div>
            </div>
            <ng-container *ngIf="salary.accepted_by.length==0 && !currentStatus">
              <div class="card-body d-flex  flex-center text-center py-16">
                <div class="fs-12 text-muted">Loading </div>
                <div class="ms-4 dot-pulse"></div>
              </div>
            </ng-container>
            <div class="card-body px-0 pt-8 pb-12 d-flex fs-12" *ngIf="currentStatus">
              <div class="me-8">
                <div class="text-light-400">CTC</div>
                <div>{{salary.ctc | currency: salary.currency}}</div>
              </div>
              <div class="ms-auto text-end">
                <div class="text-light-400">Gross</div>
                <div>{{salary.gross_amount | currency: salary.currency}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let salary of salaryData;let ind = index">
          <div class="card card-c2 user-card px-24 {{salary.employee_data.color_code}} card-hover h-100"
            (click)="salaryUpdate(salary.id)">
            <div class="card-body py-16 px-0 text-center">

              <img *ngIf="salary.employee_data.profile_image !=  null && salary.employee_data.profile_image !=  ''"
                alt="" class="img-fluid avatar-border avatar-circle sq-57 mt-6 mx-auto"
                src="{{salary.employee_data.profile_image}}" />
              <div *ngIf="salary.employee_data.profile_image ==  '' || salary.employee_data.profile_image ==  null"
                class="avatar-circle sq-57 mt-6 mx-auto fw-500 bg-white avatar-border">
                {{this.appService.getFirstChar(salary.employee_data.first_name+ " "+salary.employee_data.last_name,2)}}
              </div>

              <div class="badge badge-employee mt-2 mb-8
            {{(salary.employee_data.employment_status == 'Confirmed') ? 'badge-success' :
              (salary.employee_data.employment_status == 'Probation') ? 'badge-c1':
              (salary.employee_data.employment_status == 'Relieved') ? 'badge-c2':
              (salary.employee_data.employment_status == 'Resigned') ? 'badge-c3':
              (salary.employee_data.employment_status == 'Settled') ? 'cbg-7':
              (salary.employee_data.employment_status == 'Retired') ? 'cbg-8':
              (salary.employee_data.employment_status == 'Terminated') ? 'badge-danger': 'badge-c4'}}">
                {{salary.employee_data.employment_status}}</div>

              <h6 class="fw-500 tips text-trim  fs-14">{{salary.employee_data.first_name+"
                "+(salary.employee_data.middle_name!=null?salary.employee_data.middle_name:" ")+ "
                "+salary.employee_data.last_name}}</h6>
              <span class="tips-helper ">{{salary.employee_data.first_name+"
                "+(salary.employee_data.middle_name!=null?salary.employee_data.middle_name:" ")+ "
                "+salary.employee_data.last_name }}</span>
              <div class="mb-0 text-trim text-uppercase fw-500 fs-10 text-light-400">
                {{salary.employee_data.designation_name}}</div>
              <div class="d-flex fs-10 text-light-400 mt-1 gap-1 justify-content-center fw-500">
                <span class="text-uppercase">EMP CODE : </span>
                <span> {{salary.employee_data.employee_code}}</span>
              </div>
            </div>
            <div class="card-body py-12 px-0  d-flex fs-12 align-items-center"
              *ngIf="!currentStatus && salary.accepted_by.length>0">
              <div class="text-light-400 me-8 text-muted fs-12 text-nowrap">Pending with</div>

              <div class="d-flex align-items-center overlap-avatar overlap-right">
                <ng-container *ngFor="let aacpt of salary.accepted_by,let i = index">

                  <img class="avatar-sm sq-32 avatar-circle"
                    *ngIf="i < 3 && aacpt.profile_image !=  '' && aacpt.profile_image !=  null"
                    src="{{aacpt.profile_image}}" [ngbPopover]="popContentSingle" popoverClass="user-list single-user"
                    triggers="hover" container="body">

                  <div class="avatar-sm sq-32 avatar-circle {{aacpt.color_code}}"
                    *ngIf="i < 3 && (aacpt.profile_image ==  ''|| aacpt.profile_image ==  null)"
                    [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover"
                    container="body"><span>{{this.appService.getFirstChar(aacpt?.first_name+"
                      "+aacpt?.last_name,2)}}</span></div>
                  <ng-template #popContentSingle>
                    <div class="user-details">
                      <span class="user-label">{{aacpt?.first_name+'
                        '+(aacpt?.middle_name!=null?aacpt?.middle_name:'')+' '+aacpt?.last_name}}</span>
                      <span class="user-code">EMP CODE: {{aacpt?.employee_code}} </span>
                    </div>
                  </ng-template>
                  <div *ngIf="i==3" class="avatar-sm sq-32 avatar-circle" [ngbPopover]="popContentMultilpe"
                    popoverClass="user-list multi-user" triggers="click" container="body">
                    <span>+{{this.appService.subEmp(salary.accepted_by.length)}}</span>
                  </div>

                </ng-container>
                <ng-template #popContentMultilpe>
                  <ul>
                    <ng-container *ngFor="let re of salary.accepted_by,let i = index">
                      <ng-container *ngIf="i >= 3">
                        <li *ngIf="re.profile_image !='' && re.profile_image !=null">
                          <img class="user-avatar" src="{{re.profile_image}}">
                          <div class="user-details">
                            <span class="user-label">{{re.first_name+' '+(re.middle_name!=null?re.middle_name:'')+'
                              '+re.last_name}}</span>
                            <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                          </div>
                        </li>
                        <li *ngIf="re.profile_image =='' || re.profile_image ==null">
                          <span class="user-avatar {{re.color_code}}">{{this.appService.getFirstChar(re.first_name+"
                            "+re.last_name,2)}}</span>
                          <div class="user-details">
                            <span class="user-label">{{re.first_name+' '+(re.middle_name!=null?re.middle_name:'')+'
                              '+re.last_name}}</span>
                            <span class="user-code">EMP CODE : {{re.employee_code}}</span>
                          </div>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </ng-template>
              </div>



            </div>
            <ng-container *ngIf="salary.accepted_by.length==0 && !currentStatus">
              <div class="card-body d-flex  flex-center text-center py-16">
                <div class="fs-12 text-muted">Loading </div>
                <div class="ms-4 dot-pulse"></div>

              </div>
            </ng-container>
            <div class="card-body px-0 pt-8 pb-16 d-flex fs-12" *ngIf="currentStatus">
              <div class="me-8">
                <div class="text-light-400">CTC</div>
                <div>{{salary.ctc | currency: salary.currency}}</div>
              </div>
              <div class="ms-auto text-end">
                <div class="text-light-400">Gross</div>
                <div>{{salary.gross_amount | currency: salary.currency}}</div>
              </div>

            </div>
          </div>
        </div> -->
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-lg-3 col-md-4 col-sm-6" [cardType]="'card4'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>
</div>

<div class="side-panel" style="--sidepanel-width:59.0625rem; " [class.side-pane-active]='showSidePanel === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Salary Structure</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body " style="overflow-y: auto;">
      <div class="container-fluid px-32 py-24 ">
        <app-view-loader *ngIf="viewloader"></app-view-loader>
        <ng-container *ngIf="!viewloader">
          <div class="row ">
            <div class="col-12 mt-16" *ngIf="Isalert && (empSalaryData.message!='' && empSalaryData.message!=null)">
              <div role="alert" class="alert alert-warning mb-0 show mb-12">
                <p class="mb-0 fs-14">{{empSalaryData.message}}</p>
                <div class="btn-close pointer" (click)="Isalert=false;"></div>
              </div>
            </div>

            <div class="col-12 mt-16" *ngIf="employemntAlert && (!empSalaryData.employee_data?.employee_active)">
              <div role="alert" class="alert alert-warning mb-0 show mb-12">
                <p class="mb-0 fs-14">The employment status for the employee is
                  {{empSalaryData.employee_data?.employment_status}}. Salary structure cannot be assigned.</p>
                <div class="btn-close pointer" (click)="employemntAlert=false;"></div>
              </div>
            </div>

            <div *ngIf="revisePermissions?.v && empSalaryData?.allow_revision"
              class="text-end mb-12 link-primary1 fs-12 fw-500" (click)="salaryRevisionHistory = true"> <i
                class="icon-eye me-1"></i> Salary Revision History</div>
            <div class="col-12">
              <div class="card card-c2 details-card">
                <div class="card-body p-16">
                  <div class="row row-16">
                    <div class="col-12">
                      <div class="d-flex align-items-center">

                        <img
                          *ngIf="empSalaryData.employee_data?.profile_image !=  null && empSalaryData.employee_data?.profile_image !=  ''"
                          class="avatar-circle avatar-border sq-42  me-16"
                          src="{{empSalaryData.employee_data.profile_image}}" />
                        <div
                          *ngIf="empSalaryData.employee_data?.profile_image ==  '' || empSalaryData.employee_data?.profile_image ==  null"
                          class="avatar-circle avatar-border sq-42 me-16 {{empSalaryData.employee_data?.color_code}} fs-14">
                          {{this.appService.getFirstChar(empSalaryData.employee_data?.first_name+"
                          "+empSalaryData.employee_data?.last_name,2)}}</div>
                        <div style="width: calc(100% - 230px);">
                          <div class="fs-14 fw-600">{{empSalaryData.employee_data?.first_name+"
                            "+(empSalaryData.employee_data?.middle_name!=null?empSalaryData.employee_data?.middle_name:'')+"
                            "+empSalaryData.employee_data?.last_name +"
                            ("+empSalaryData.employee_data?.employee_code+")"}}</div>
                          <div class="fs-12 fw-500 text-light-400">{{empSalaryData.employee_data?.designation_name}} |
                            {{empSalaryData.employee_data?.company_name}}</div>
                        </div>
                        <a *ngIf="permissions?.e && empSalaryData.employee_data?.employee_active"
                          class="ms-auto link-primary fs-14 d-flex align-items-center"
                          routerLink="/salary-structure/assign-salary-structure/{{empSalaryData.id}}"><i
                            class="icon-pencil-square me-1"></i> Edit</a>
                        <div
                          *ngIf="empSalaryData.employee_data?.employee_active && revisePermissions?.a && empSalaryData?.allow_revision"
                          class="btn ms-12 btn-primary text-uppercase fs-10 fw-700"
                          (click)="reviseSalary(empSalaryData.is_salary_revision_pending,empSalaryData.employee)">REVISE
                          SALARY</div>
                      </div>
                    </div>
                    <div class="col-12">
                      <hr class="hr-1">
                    </div>
                    <div class="col-12">
                      <div class="row row-24">
                        <div class="col-12">
                          <div class="title2">Employee statutory</div>
                        </div>
                        <div class="col-12">
                          <div class="detail-title">Tax regime</div>
                          <div class="detail-desc">{{empSalaryData?.tax_regime==true?'New':'Old'}}</div>
                        </div>
                        <div class="col-md-6" *ngIf="empSalaryData.provident_fund">
                          <div class="card details-card shadow-0 p-16 h-100">
                            <div class="row row-24">
                              <div class="col-12">
                                <span class="title">Provident fund</span>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">PF number</div>
                                <div class="detail-desc">
                                  {{empSalaryData.provident_fund==true && empSalaryData?.pf_number!="" && empSalaryData?.pf_number!=null ?empSalaryData?.pf_number:"-"}}</div>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">UAN number</div>
                                <div class="detail-desc">
                                  {{empSalaryData.provident_fund==true && empSalaryData?.uan_number!=null && empSalaryData?.uan_number!='' ?empSalaryData?.uan_number:"-"}}</div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="empSalaryData.pension">
                          <div class="card details-card shadow-0 p-16 h-100">
                            <div class="row row-24">
                              <div class="col-12">
                                <span class="title">Pension</span>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">Pension number</div>
                                <div class="detail-desc">
                                  {{empSalaryData?.pension==true && empSalaryData?.pension_number!=null && empSalaryData?.pension_number!='' ?empSalaryData?.pension_number:"-"}}</div>
                              </div>


                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="empSalaryData.employee_state_insurance">
                          <div class="card details-card shadow-0 p-16 h-100">
                            <div class="row row-24">
                              <div class="col-12">
                                <span class="title">Employee state insurance</span>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">ESI number</div>
                                <div class="detail-desc">
                                  {{empSalaryData.employee_state_insurance==true && empSalaryData?.esi_number!=null && empSalaryData?.esi_number!=''?empSalaryData?.esi_number:"-"}}</div>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">Dispensary name</div>
                                <div class="detail-desc">
                                  {{empSalaryData.employee_state_insurance==true?empSalaryData?.dispensry_name:"-"}}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="col-12" *ngIf="empSalaryData?.labour_welfare_fund">
                          <div class="d-flex align-items-center gap-8 fs-14">
                            <i class="icon-check-square-fill text-light-500"></i>Labour welfare fund
                          </div>
                        </div>
                        <div class="col-12" *ngIf="empSalaryData?.professional_tax">
                          <div class="d-flex align-items-center gap-8 fs-14">
                            <i class="icon-check-square-fill text-light-500"></i>Professional tax
                          </div>
                        </div>
                        <div class="col-12" *ngIf="empSalaryData?.gratuity">
                          <div class="d-flex align-items-center gap-8 fs-14">
                            <i class="icon-check-square-fill text-light-500"></i>Gratuity
                          </div>
                        </div>

                        <div class="col-md-6" *ngIf="empSalaryData?.amount!=0">
                          <div class="card details-card shadow-0 p-16 h-100">
                            <div class="row row-24">
                              <div class="col-12">
                                <span class="title">Voluntary provident fund</span>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">Amount</div>
                                <div class="detail-desc">{{empSalaryData?.amount!=0?(empSalaryData?.amount | currency :
                                  empSalaryData.currency):"-"}} </div>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">Effective from</div>
                                <div class="detail-desc">{{empSalaryData?.vpf_year!='' && empSalaryData?.vpf_year!=null
                                  ?(empSalaryData?.vpf_month+" "+empSalaryData?.vpf_year ):"-"}} </div>
                              </div>


                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="empSalaryData?.percentage!=0">
                          <div class="card details-card shadow-0 p-16 h-100">
                            <div class="row row-24">
                              <div class="col-12">
                                <span class="title">Employer contribution to NPS</span>
                              </div>
                              <div class="  col-sm-6 col-12">
                                <div class="detail-title">Percentage</div>
                                <div class="detail-desc">
                                  {{empSalaryData?.percentage!=0?empSalaryData?.percentage+"%":"-"}}</div>
                              </div>


                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="title2">Salary Structure</div>
                    </div>
                    <div class="col-12">
                      <div class="card details-card shadow-0 p-16 ">
                        <div class="row row-24">
                          <div class="col-12">
                            <span class="title">Salary</span>
                          </div>

                          <div class="col-lg-3 col-sm-6 col-12">
                            <div class="detail-title">Pay group </div>
                            <div class="detail-desc">{{empSalaryData?.pay_group_name}}</div>
                          </div>
                          <div class="col-lg-3 col-sm-6 col-12">
                            <div class="detail-title">CTC</div>
                            <div class="detail-desc">{{empSalaryData?.ctc | currency: empSalaryData?.currency}}</div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="gross_benefits?.length>0">

                      <div class="card details-card shadow-0 p-16">
                        <div class="row row-24">
                          <div class="col-12">
                            <span class="title">Gross benefits</span>
                          </div>
                          <div class="col-12">
                            <div class="card card-c2 overflow-hidden">
                              <div class="table-responsive">
                                <table class="table table-striped  table-sm ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Component name</th>
                                      <th scope="col">Nature</th>
                                      <th scope="col">Formula</th>
                                      <th scope="col">Monthly remuneration</th>
                                      <th scope="col">Yearly remuneration</th>
                                      <th scope="col">Month</th>


                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let gross of gross_benefits">
                                      <td>{{gross.name}}</td>
                                      <td>{{gross.nature==true?'Fixed':'Variable'}}</td>
                                      <td>{{gross.formula}}</td>
                                      <td>{{gross.monthly_renum | currency: empSalaryData?.currency}}</td>
                                      <td>{{gross.year_renum | currency: empSalaryData?.currency}}</td>
                                      <td>{{monthManipulation(gross.pay_month)}}</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-12" *ngIf="other_benefits?.length>0">

                      <div class="card details-card shadow-0 p-16">
                        <div class="row row-24">
                          <div class="col-12">
                            <span class="title">Other benefits</span>
                          </div>
                          <div class="col-12">
                            <div class="card card-c2 overflow-hidden">
                              <div class="table-responsive">
                                <table class="table table-striped table-sm ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Component name</th>
                                      <th scope="col">Nature</th>
                                      <th scope="col">Formula</th>
                                      <th scope="col">Monthly remuneration</th>
                                      <th scope="col">Yearly remuneration</th>
                                      <th scope="col">Month</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let other of other_benefits">
                                      <td>{{other.name}}</td>
                                      <td>{{other.nature==true?'Fixed':'Variable'}}</td>
                                      <td>{{other.formula}}</td>
                                      <td>{{other.monthly_renum | currency: empSalaryData?.currency}}</td>
                                      <td>{{other.year_renum | currency: empSalaryData?.currency}}</td>
                                      <td>{{monthManipulation(other.pay_month)}}</td>

                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-12" *ngIf="contributions?.length>0">

                      <div class="card details-card shadow-0 p-16">
                        <div class="row row-24">
                          <div class="col-12">
                            <span class="title">Contribution/Retirals</span>
                          </div>
                          <div class="col-12">
                            <div class="card card-c2 overflow-hidden">
                              <div class="table-responsive">
                                <table class="table table-striped  table-sm ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Component name</th>
                                      <th scope="col">Monthly remuneration</th>
                                      <th scope="col">Yearly remuneration</th>
                                      <!-- <th scope="col">Month</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let contributions of contributions">

                                      <td>{{contributions.name}}</td>
                                      <td>{{contributions.monthly_renum | currency: empSalaryData?.currency}}</td>
                                      <td>{{contributions.year_renum | currency: empSalaryData?.currency}}</td>
                                      <!-- <td>{{contributions.pay_month}}</td> -->

                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-12" *ngIf="recurring_deductions?.length>0">

                      <div class="card details-card shadow-0 p-16">
                        <div class="row row-24">
                          <div class="col-12">
                            <span class="title">Recurring deductions</span>
                          </div>
                          <div class="col-12">
                            <div class="card card-c2 overflow-hidden">
                              <div class="table-responsive">
                                <table class="table table-striped  table-sm ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Component name</th>
                                      <th scope="col">Nature</th>
                                      <th scope="col">Formula</th>
                                      <th scope="col">Monthly remuneration</th>
                                      <th scope="col">Yearly remuneration</th>
                                      <th scope="col">Month</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let recurring of recurring_deductions">
                                      <td>{{recurring.name}}</td>
                                      <td>{{recurring.nature==true?'Fixed':recurring.nature==false?'Variable':''}}</td>
                                      <td>{{recurring.formula}}</td>
                                      <td>{{recurring.monthly_renum | currency: empSalaryData?.currency}}</td>
                                      <td>{{recurring.year_renum | currency: empSalaryData?.currency}}</td>
                                      <td>{{monthManipulation(recurring.pay_month)}}</td>

                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-12 d-flex fw-500"> <span class="">Gross pay</span><span
                        class="ms-auto">{{empSalaryData?.gross_amount| currency:empSalaryData?.currency}}</span> </div>
                    <div class="col-12 d-flex fw-500"> <span class="">Net take home (excluding TDS)</span><span
                        class="ms-auto">{{empSalaryData?.net_gross| currency:empSalaryData?.currency}}</span> </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </form>
</div>
<!-- SALARY REVISION HISTORY VIEW START -->
<div class="side-panel" style="--sidepanel-width:59.0625rem;" [class.side-pane-active]='salaryRevisionHistory === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Salary Revision History </h5>
      <a class="toggle-panel" (click)="salaryRevisionHistory = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <app-salary-revision-history-view *ngIf='salaryRevisionHistory === true' [from]="from"
        [employeeId]="empSalaryData?.employee" [buttonActivate]="false"></app-salary-revision-history-view>

    </div>
  </div>
</div>
<app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
(filterClose)="closefn($event)"  (resetCalled)="resetCalled($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
<ng-container *ngIf="showInfo == true">
  <app-info-popup (modal)="closeInfo($event)" [infoToggle]="showInfo" [infoMsg]="infoMsg"></app-info-popup>
</ng-container>
